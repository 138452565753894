import React, { Suspense, useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

// STYLES
import {
  Wrapper,
  Container,
  Text,
  Image,
  BoxContainer,
  WhiteBoxContainer,
  RightWrap,
  Tabs,
  MainSubtitle,
  TextWrap,
  MobileWrapper
} from "./style";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import { convertPhone } from "../../utils/constants";
import {setHeaders} from "../../utils/setHeaders"
import { createBrowserHistory } from "history";

// COMPONENTS
/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const WhiteBox = lazy(() =>
  import("../../components/commons/additionalInfoCard")
); */

import HeroViews from "../../components/commons/heroViews"
import WhiteBox from "../../components/commons/additionalInfoCard"

export default () => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const [data, setData] = useState(null);
  const [VacationPhone, setVacationPhone] = useState("");
  const history = createBrowserHistory();

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/61");
  }, []);

  useEffect(() => {
    if (data && VacationPhone === "") {
      setVacationPhone(convertPhone(data.acf.ownership_phone));
    }
  }, [VacationPhone, data]);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {data ? (
        <Fragment>
          {!isMobile ? (
            <AnimatedWrap>
              <Wrapper>
                <HeroViews
                  bg={data.acf.ownership_hero_image}
                  title={data.title.rendered.replace("&#038;", "&")}
                  customHeight={"500px"}
                  loginButton
                ></HeroViews>
                <Container>
                  <div>
                    <Tabs>
                      <Link to="/ownership-n-benefits">
                        Ownership & Benefits
                      </Link>
                      <Link
                        to={{
                          pathname: process.env.REACT_APP_CUSTOMER_PORTAL
                        }}
                        target="_blank"
                      >
                        Owner Login
                      </Link>
                      <Link to="/vacation-club/news-n-updates">
                        News & Updates
                      </Link>
                    </Tabs>

                    <MainSubtitle
                      mobileLineHeight={"24px"}
                      mobileSize={"16px"}
                      title={data.acf.ownership_headline}
                    ></MainSubtitle>

                    <TextWrap>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: data.acf.ownership_text
                        }}
                      ></Text>
                    </TextWrap>
                  </div>
                  <BoxContainer>
                    <WhiteBoxContainer>
                      <WhiteBox
                        top={"0px"}
                        padding={"50px 30px 50px 50px"}
                        cardData={{
                          title: "",
                          text: data.acf.ownership_box_text,
                          phoneText: "Call",
                          phone: VacationPhone,
                          phoneTextNumber: data.acf.ownership_phone,
                          emailText: "or email",
                          email: data.acf.ownership_mail
                        }}
                        vacationTemplate
                        left={"-6vw"}
                      ></WhiteBox>
                    </WhiteBoxContainer>
                    <RightWrap>
                      <Image image={data.acf.ownership_side_image}></Image>
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: data.acf.ownership_bottom_text
                        }}
                      ></h4>
                    </RightWrap>
                  </BoxContainer>
                </Container>
              </Wrapper>
            </AnimatedWrap>
          ) : (
            <AnimatedWrap>
              <MobileWrapper>
                <HeroViews
                  bg={data.acf.ownership_hero_image}
                  title="Ownership & Benefits"
                  customHeight={"430px"}
                  loginButton
                ></HeroViews>
                <WhiteBoxContainer>
                  <WhiteBox
                    top={"0px"}
                    padding={"50px 60px"}
                    cardData={{
                      title: "For further information",
                      text: data.acf.ownership_box_text,
                      phoneText: "And can be reached by phone at",
                      phone: VacationPhone,
                      phoneTextNumber: data.acf.ownership_phone,
                      emailText: "or email at",
                      email: data.acf.ownership_mail
                    }}
                    vacationTemplate
                    left={"-6vw"}
                  ></WhiteBox>
                </WhiteBoxContainer>

                <TextWrap>
                  <MainSubtitle
                    mobileLineHeight={"24px"}
                    mobileSize={"16px"}
                    title={data.acf.ownership_headline}
                  ></MainSubtitle>
                </TextWrap>

                <TextWrap bg={data.acf.ownership_side_image}>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: data.acf.ownership_text
                    }}
                  ></Text>
                </TextWrap>
              </MobileWrapper>
            </AnimatedWrap>
          )}
        </Fragment>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
