import styled from "styled-components";

import Subtitle from "../../components/commons/subtitle";
import { XL, HIGHER_RESOLUTIONS } from "../../utils/constants";

export const Wrapper = styled.div``;

export const GridMural = styled.div`
  padding: 0 165px 100px;

  @media (max-width: ${XL}) {
    padding: 0 30px 100px;
  }
`;

export const TextContainer = styled.div`
  grid-column: ${props => (props.id === 1 ? 5 + "/" + 9 : 1 + "/" + 6)};
  padding-top: ${props => (props.id === 1 ? "80px" : "20px")};
  max-width: ${props => (props.id === 1 ? "initial" : "44.66666666666667vw")};
  display: ${props =>
    props.show === "the-cavern" || props.show === "pool-bars"
      ? "none !important"
      : "block"};
  margin-top: ${props =>
    props.currentRoute === "the-cavern" ||
    props.currentRoute === "the-cavern/"
      ? "-10px !important"
      : "inherit"};
  > div {
    &:last-child {
      display: flex;
      position: relative;
    }
  }

  margin-top: ${props =>
    props.currentRoute === "the-cavern" ||
    props.currentRoute === "pool-bars"
      ? "-80px"
      : "initial"};

  a {
    color: ${props => props.theme.skyBlue};
    text-decoration: none;
  }

  p {
    padding-bottom: 20px;
  }
`;

export const CardModule = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 30px;
  grid-template-rows: 600px auto;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    grid-template-rows: ${props =>
      props.currentRoute === "david-deep-blue" ||
      props.currentRoute === "georgies-cafe" ||
      props.currentRoute === "mimi-dock"
        ? " 600px 20vw;"
        : "600px auto"};
  }

  &:first-of-type {
    grid-column: 1/5;
  }

  h4 {
    margin-bottom: 20px;
  }
`;

export const Image = styled.div`
  &:first-child {
    grid-column: 1/5;
  }
  top: ${props => (props.toRight ? "3vw" : "unset")};
  height: ${props => (props.toRight ? "27.77777777777778vw" : "unset")};
  grid-column: ${props => (props.id === 1 ? 5 + "/" + 9 : 6 + "/" + 9)};
  border-radius: 20px;
  max-width: ${props => (props.toRight ? "unset" : props.maxWidth)};
  margin-left: ${props => (props.toRight ? "7.5vw" : "")};
  background: url(${props => props.bg}) no-repeat center center / cover;
  display: ${props =>
    props.show === "the-cavern" || props.show === "pool-bars"
      ? "none !important"
      : "block"};
`;

export const SubtitleMain = styled(Subtitle)``;

export const MobileWrap = styled.div` 
  display: grid;


  a {
    color: ${props => props.theme.skyBlue};
    text-decoration: none;
  }

  > div {
    padding: 0 15px;
    p {
      padding: 0 0 20px;
    }
  }
`;

export const MobileImage = styled.div`
  height: 323px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  margin: 0 0 25px;
`;

export const RoundedMobileImage = styled.div`
  height: 323px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  border-radius: 16px;
  width: 92%;
  margin: 0 auto 25px;
`;

export const MobileTitleWrap = styled.div`
  padding: 0 20px 5px !important;
`;
