import React, {
  Suspense,
  lazy,
  useContext,
  Fragment,
  useEffect,
  useState
} from "react";
import {
  Wrapper,
  Title,
  FirstGrid,
  OnSiteSports,
  TimeCard,
  Picture,
  SecondGrid
} from "./style";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { createBrowserHistory } from "history";
import axios from "axios";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import {setHeaders} from "../../../utils/setHeaders"

/* const MainSlider = lazy(() => import("../../../components/commons/mainSlider"));
const Paragraph = lazy(() => import("../../../components/commons/Paragraph")); */

import MainSlider from "../../../components/commons/mainSlider"
import Paragraph from "../../../components/commons/Paragraph"

export default () => {
  const theme = useContext(ThemeContext);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const [apiData, setApiData] = useState(null);
  const [features, setFeatures] = useState([]);
  const history = createBrowserHistory();

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/580");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setApiData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchFeatures(url) {
    try {
      const response = await axios.get(url);
      setFeatures(features => [...features, response.data]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (apiData && features < apiData.sport_activities_items.length) {
      apiData.sport_activities_items.forEach(item => {
        fetchFeatures(
          process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/features/" + item.ID
        );
      });
    }
  }, [apiData, features]);

  return (
    <Suspense fallback={null}>
      {apiData && features ? (
        <Wrapper>
          <Title>{apiData.first_module_title}</Title>
          <FirstGrid>
            {!isMobile && (
              <div>
                <MainSlider
                  images={apiData.first_module_gallery}
                  title={"Land "}
                  color={theme.blackColor()}
                  customMargin={"auto"}
                />
              </div>
            )}

            <OnSiteSports>
              <Paragraph text={apiData.first_module_text}></Paragraph>
              <h5>On-site sports activities:</h5>
              <ul>
                {features.map(singleFeature => (
                  <AnimatedWrap key={singleFeature.id}>
                    <li>
                      <img
                        src={singleFeature.acf.icon_feature}
                        alt={singleFeature.title.rendered.replace(
                          "&#038;",
                          "&"
                        )}
                      />
                      <span>
                        {singleFeature.title.rendered.replace("&#038;", "&")}
                      </span>
                    </li>
                  </AnimatedWrap>
                ))}
              </ul>
              {!isMobile && (
                <TimeCard>
                  <span>{apiData.first_module_box_days}</span>
                  <h6>{apiData.first_module_box_hours}</h6>
                </TimeCard>
              )}
            </OnSiteSports>
            {isMobile && (
              <Fragment>
                <div>
                  <MainSlider
                    images={apiData.first_module_gallery}
                    title={"Land "}
                    color={theme.blackColor()}
                    customMargin={"auto"}
                  />
                </div>
                <TimeCard>
                  <span>{apiData.first_module_box_days}</span>
                  <h6>{apiData.first_module_box_hours}</h6>
                </TimeCard>
              </Fragment>
            )}
          </FirstGrid>
          <SecondGrid>
            <div>
              <Title>{apiData.second_module_title}</Title>
              <Paragraph text={apiData.second_module_text}></Paragraph>
              {!isMobile && (
                <TimeCard>
                  <span>{apiData.second_module_box_days}</span>
                  <h6>{apiData.second_module_box_hours}</h6>
                </TimeCard>
              )}
            </div>
            <div>
              <Picture bg={apiData.second_module_image} />
              {isMobile && (
                <TimeCard>
                  <span>{apiData.second_module_box_days}</span>
                  <h6>{apiData.second_module_box_hours}</h6>
                </TimeCard>
              )}
            </div>
          </SecondGrid>
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
