import styled from "styled-components";
import {Link} from "react-router-dom"

export const main = styled(Link)`
  outline: none;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: white;
  border-radius: 99em;
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  max-width:max-content;
  text-decoration:none;
  &:hover {
    background-color: ${props => props.theme.darkBlue};
    border-color: white;
    color: white !important;
  }
`;
export const secondary = styled.button`
  outline: none;
  background-color: black;
  color: white;
  border: none;
  border-radius: 99em;
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
`;

export const btnwhite = styled.button`
  outline: none;
  background-color: transparent;
  border: none;
  color: ${props => props.colorfont};
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
`;

export const Wrapper = styled(props => props.typebtn)`
  padding: 1.2em 3em;
  margin-top: ${({ margintop }) => margintop || 0};
  width: ${({ width }) => width || "content"};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  transition: ${props => props.theme.defaultTransition()};
  
    /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     margin-top: 0;
     width: auto;
     float: left;
  }
`;
