import React, {
  useContext,
  useState,
  useEffect,
  Fragment
} from "react";

import {
  Wrapper,
  GridMural,
  Image,
  CardModule,
  TextContainer,
  SubtitleMain,
  MobileWrap,
  MobileImage,
  RoundedMobileImage,
  MobileTitleWrap
} from "./style";
import { ThemeContext } from "styled-components";
import { createBrowserHistory } from "history";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { setHeaders } from "../../utils/setHeaders";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import Slider from "../../components/commons/mainSlider/";

/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const Navigation = lazy(() => import("../../components/commons/upperList"));
const HoursCard = lazy(() =>
  import("../../components/commons/additionalInfoCard")
);
const Paragraph = lazy(() => import("../../components/commons/Paragraph")); */
/* const Slider = lazy(() => import("../../components/commons/mainSlider/")); */

import HeroViews from "../../components/commons/heroViews"
import Navigation from "../../components/commons/upperList"
import HoursCard from "../../components/commons/additionalInfoCard"
import Paragraph from "../../components/commons/Paragraph"

export default () => {
  const theme = useContext(ThemeContext);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const [finalRoute, setFinalRoute] = useState("");

  const history = createBrowserHistory();
  const currentRoute = history.location.pathname.split("/");
  const parsedCurrentRoute = currentRoute.slice(currentRoute.length - 2);
  const [data, setData] = useState(null);

  


  useEffect(() => {
    if (
      finalRoute === "david-deep-blue" ||
      finalRoute === "david-deep-blue/" ||
      finalRoute === "/david-deep-blue/"
    ) {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/399");
    }
    if (finalRoute === "mimi-dock" || finalRoute === "mimi-dock/") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/429");
    }
    if (
        finalRoute === "georgies-cafe" ||
        finalRoute === "georgies-cafe/" ||
        finalRoute === "/georgies-cafe/"
    ) {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/2908"); // new: 2914, dev: 2629
    }
    if (
      finalRoute === "the-cavern" ||
      finalRoute === "the-cavern/" ||
      finalRoute === "/the-cavern/"
    ) {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/467");
    }
    if (
      finalRoute === "pool-bars" ||
      finalRoute === "pool-bars/" ||
      finalRoute === "/pool-bars/"
    ) {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/491");
    }
  }, [finalRoute]);

  useEffect(() => {}, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  const restaurants = [
    {
      name: "David's ",
      subtitle: "",
      route: "/dine/david-deep-blue",
      routeName: "david-deep-blue"
    },
    {
      name: "Mimi's Dock Bar ",
      subtitle: "",
      route: "/dine/mimi-dock",
      routeName: "mimi-dock"
    },
    {
      name: "Georgie's Café ",
      subtitle: "",
      route: "/dine/georgies-cafe",
      routeName: "georgies-cafe"
    },
    {
      name: "The Cavern ",
      subtitle: "",
      route: "/dine/the-cavern",
      routeName: "the-cavern"
    },

    {
      name: "Pool Bars ",
      subtitle: "",
      route: "/dine/pool-bars",
      routeName: "pool-bars"
    }
  ];

  const [currentName, setCurrentName] = useState(null);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);

  useEffect(() => {
    if (history.location.pathname.split("/").pop() === "") {
      setFinalRoute(parsedCurrentRoute[0]);
    } else {
      setFinalRoute(history.location.pathname.split("/").pop());
    }

    if (finalRoute !== "") {
      restaurants.forEach((restaurant, x) => {
        if (restaurant.routeName === finalRoute) {
          setCurrentName(restaurant.name);
          setCurrentSubtitle(restaurant.subtitle);
        }
      });
    }
  }, [finalRoute]);

  return (
    <Fragment>
      {data && (
        /*  <AnimatedWrap> */

        <Wrapper>
          <HeroViews
            bg={data.hero_image}
            subtitle={currentSubtitle}
            title={currentName}
            customHeight={"400px"}
          />

          <HoursCard
            lastCard={true}
            dineCard={true}
            cardData={data}
            hoursTemplate
          />

          <Navigation
            color={theme.blackColor()}
            transition={theme.defaultTransition()}
            list={restaurants}
            dineNavigationSize={true}
            finalRoute={finalRoute}
          />

          {!isMobile ? (
            <Fragment>
              <GridMural>
                <CardModule currentRoute={finalRoute}>
                  <Image
                    id={1}
                    maxWidth={"unset"}
                    variableHeight={"41.66666666666667vw"}
                    bg={data.first_module_image}
                  />

                  <TextContainer id={1} currentRoute={finalRoute}>
                    <SubtitleMain
                      color={theme.skyBlue}
                      title={data.first_module_title}
                    ></SubtitleMain>

                    <Paragraph
                      color={theme.blackColor()}
                      text={data.first_module_text}
                      paddingBottom={"20px"}
                    />
                    {data.second_module_image && (
                      <Image
                        toRight
                        id={2}
                        maxWidth={"31.25vw"}
                        variableHeight={"27.77777777777778vw"}
                        bg={data.second_module_image}
                        show={currentRoute}
                      />
                    )}
                  </TextContainer>

                  <TextContainer id={2} show={finalRoute}>
                    {data.second_module_text && (
                      <Paragraph
                        color={theme.blackColor()}
                        text={data.second_module_text}
                        paddingBottom={"20px"}
                      />
                    )}
                  </TextContainer>
                </CardModule>
              </GridMural>
            </Fragment>
          ) : (
            <Fragment>
              <MobileWrap id={finalRoute}>
                <Fragment>
                  <MobileTitleWrap>
                    <SubtitleMain
                      color={theme.skyBlue}
                      title={data.first_module_title}
                    ></SubtitleMain>
                  </MobileTitleWrap>

                  <RoundedMobileImage bg={data.first_module_image} />
                  <Paragraph
                    color={theme.blackColor()}
                    text={data.first_module_text}
                    paddingBottom={"20px"}
                  />
                  {data.second_module_image && (
                    <MobileImage bg={data.second_module_image} />
                  )}

                  {data.second_module_text && (
                    <Paragraph
                      color={theme.blackColor()}
                      text={data.second_module_text}
                      paddingBottom={"20px"}
                    />
                  )}
                </Fragment>
              </MobileWrap>
            </Fragment>
          )}
        </Wrapper>
        /*        </AnimatedWrap> */
      )}

      {data ? (
        <AnimatedWrap>
          <Slider
            images={data.gallery}
            color={theme.blackColor()}
            title={currentName}
            customMargin={"auto"}
          />
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};
