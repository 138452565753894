import React from "react"
import { toggleMobileMenu } from "../../../redux/actions"
import { connect } from "react-redux"
import {Button, StyledSvg, Path} from './style'

const HamburgerComponent = ({menuToggle, menuOpen, showbg}) => (
  <Button onClick={menuToggle}>
      {menuOpen
        ?
        <StyledSvg s viewBox="0 0 10 10">
          <Path showbg={showbg} menuOpen={menuOpen} d="M2,8 L8,2"/>
          <Path showbg={showbg} menuOpen={menuOpen} d="M5,5 L5,5"/>
          <Path showbg={showbg} menuOpen={menuOpen} d="M2,2 L8,8"/>
        </StyledSvg>
        :
        <StyledSvg viewBox="0 0 10 10">
          <Path showbg={showbg} menuOpen={menuOpen} d="M8,3 L2,3"/>
          <Path showbg={showbg} menuOpen={menuOpen} d="M2,5 L8,5"/>
          <Path showbg={showbg} menuOpen={menuOpen} d="M8,7 L2,7"/>
        </StyledSvg>
      }
  </Button>
)

const mapDispatchToProps = dispatch => {
  return {
    menuToggle: () => dispatch(toggleMobileMenu())
  }
}

const mapStateToProps = state => {
  return { 
    menuOpen: state.toggleMobileMenu.menuOpened
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerComponent)