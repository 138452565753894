import React, { Suspense, Fragment, useEffect, useState } from "react";

import {
  Wrapper,
  BottomWrap,
  TextWrap,
  ScrollIcon,
  MaskContainer,
  AnimatedArrow,
  BarAnimated
} from "./style";
import { Title, Cta } from "../../../components";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents/";
import { convertPhone } from "../../../utils/constants";

export default props => {
  const [homeNumber, setHomeNumber] = useState("");

  useEffect(() => {
    setHomeNumber(convertPhone(props.info.main_tel));
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      {props.data ? (
        <Fragment>
          <Wrapper>
            <AnimatedWrap>
              <Title>{props.data.hero_title}</Title>
              <Cta typebtn="main" margintop="40px" to="/staycation">
                GET STARTED
              </Cta>
            </AnimatedWrap>
          </Wrapper>
          <BottomWrap>
            <TextWrap>
              <MaskContainer />
              <div>
                <h3>
                  <a
                    href={`tel:${
                      props.info.main_tel.substring(0, 1) === "+" ? "+" : ""
                    }${homeNumber}`}
                  >
                    {props.info.main_tel}
                  </a>
                </h3>
                <a href={`mailto:${props.info.mail}`}>{props.info.mail}</a>
              </div>
            </TextWrap>
            <ScrollIcon>
              <AnimatedArrow>
                <BarAnimated></BarAnimated>
              </AnimatedArrow>
              Scroll down
            </ScrollIcon>
          </BottomWrap>
        </Fragment>
      ) : <div></div>}
    </Suspense>
  );
};
