import styled from "styled-components";


export const Wrapper = styled.button`
  width: 100%;
  height: 100%;
  height: 50px;
  width: auto;
  padding: 0 40px;
  border-radius: 25px;
  background-color: ${props => props.theme.skyBlue};
  transition:${props => props.theme.defaultTransition()};

  cursor: pointer;
  color: white;
  font-family: roboto;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  transition: ${props => props.transition};
  border: 0;
  outline: none !important;

  &:hover {
    background-color: #2883DA;
    box-shadow: 0 3px 13px 0 rgba(77,114,149,0.35);

  }
`;
