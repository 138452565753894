import styled from "styled-components";

import { HIGHER_RESOLUTIONS, XL, XS } from "../../../utils/constants";
import { RoundedImage } from "../../../components/commons/borderImage/style";

export const Headline = styled.div`
  grid-area: headline;
  margin-right: 30px;

  @media (max-width: ${XS}) {
    margin-right: 0;
  }

  p{
    padding-bottom:20px;
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     width: 50%;
  }
  @media screen and (max-width: 699px) and (-ms-high-contrast: active), screen and (max-width: 699px) and (-ms-high-contrast: none) {  
     width: 100%;
     margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 60px 165px;
  display: grid;
  grid-template-columns: 1fr minmax(auto, 450px);
  grid-template-columns: ${props =>
    props.imageToRight ? "1fr minmax(auto, 450px)" : "minmax(auto, 450px) 1fr"};
  align-items: ${props => (props.itemAlign ? props.itemAlign : "center")};
  grid-gap: ${props => (props.imageToRight ? "" : "50px")};

  grid-template-areas: ${props =>
    props.imageToRight ? "'headline image'" : "'image headline'"};

  ${Headline} {
    justify-self: ${props => (props.imageToRight ? "" : "flex-end")};
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1320px) {
    grid-template-columns: ${props =>
      props.imageToRight ? "2fr 1fr" : "1fr 2fr"};
  }

  @media (max-width: ${XL}) {
    padding: 65px 60px;
    grid-template-columns: 1fr 0.8fr;
  }

  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     display: flex;
  }
  @media screen and (max-width: 699px) and (-ms-high-contrast: active), screen and (max-width: 699px) and (-ms-high-contrast: none) {  
     display: block;
  }

 `

export const MobileWrapper = styled.div`
  padding: 0 20px;
  > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-area: unset;
    margin-top: 40px;

    div:nth-child(3) {
      grid-row: 2/3;
    }
  }
`;

export const Image = styled(RoundedImage)`

  @media (max-width: ${XS}) {
    grid-area: unset;
    height: 323px;
    margin-top: 15px;
    margin-bottom: 22px;
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     width: 50%;
  }
  @media screen and (max-width: 699px) and (-ms-high-contrast: active), screen and (max-width: 699px) and (-ms-high-contrast: none) {  
     width: 100%;
     margin-right: 0;
  }
`;
