import React, { Suspense, useEffect, useState } from "react";
import {
  Wrapper,
  OnSite,
  OnSiteTitle,
  OnSiteText,
  Located,
  ShoppingCenter,
  ShoppingTitle,
  ShoppingSubtitle,
  CardsContainer
} from "./style";
import { createBrowserHistory } from "history";
import axios from "axios";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import {setHeaders} from "../../../utils/setHeaders"

/* const Card = lazy(() => import("./card/"));
const Paragraph = lazy(() => import("../../../components/commons/Paragraph")); */

import Card from "./card"
import Paragraph from "../../../components/commons/Paragraph"

export default () => {
  const [apiData, setApiData] = useState(null);
  const [stores, setStores] = useState();
  const history = createBrowserHistory();

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/625");
    fetchStores(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/stores/");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setApiData(response.data.acf);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchStores(url) {
    try {
      const response = await axios.get(url);
      setStores(response.data.reverse());
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={null}>
      {apiData && stores ? (
        <AnimatedWrap>
          <Wrapper>
            <OnSite>
              <div>
                <OnSiteTitle>{apiData.first_title}</OnSiteTitle>
                <Paragraph text={apiData.first_text} />
              </div>
              <Located>
                <div>
                  <h3>
                    Located in <span>{apiData.located_in}</span>
                  </h3>
                  <h4>
                    {apiData.open_from} <span>{apiData.open_hours}</span>
                  </h4>
                </div>
              </Located>
            </OnSite>
            <ShoppingCenter>
              <ShoppingTitle>{apiData.centre_shopping_title}</ShoppingTitle>
              <ShoppingSubtitle
                mobileLineHeight={"24px"}
                mobileSize={"16px"}
                title={apiData.centre_shopping_subtitle}
              ></ShoppingSubtitle>
            </ShoppingCenter>
            <CardsContainer>
              {stores.map(store => (
                <Card
                  key={store.id}
                  data={{
                    image: store.acf.store_image,
                    imageXs: store.acf.store_image,
                    title: store.acf.store_type,
                    subtitle: store.title.rendered.replace("&#038;", "&"),
                    text: store.acf.store_description,
                    days1: store.acf.store_week_days,
                    hours1: store.acf.store_week_hours,
                    days2: store.acf.store_weekend_days,
                    hours2: store.acf.store_weekend_hours,
                    closed: store.acf.store_closed_day,
                    disclaimer: store.acf.store_disclaimer
                  }}
                />
              ))}
            </CardsContainer>
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
