import React from "react";

import { Wrapper, Subtitle } from "./style";

export default props => {
  return (
    <Wrapper>
      <Subtitle isPost={props.isPost}>{props.subtitle}</Subtitle>
    </Wrapper>
  );
};
