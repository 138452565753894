import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 70px;
`;

export const GridMural = styled.div`
  padding: 0 165px;
`;

export const TextContainer = styled.div`
  grid-column: ${props => (props.id === 1 ? 5 + "/" + 9 : 1 + "/" + 6)};
  padding-top: ${props => (props.id === 1 ? "80px" : "20px")};
  max-width: ${props => (props.id === 1 ? "initial" : "44.66666666666667vw")};

  > div {
    &:last-child {
      display: flex;
      position: relative;
    }
  }
`;

export const CardModule = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 30px;
  grid-template-rows: 60% auto;

  &:first-of-type {
    grid-column: 1/5;
  }

  ${TextContainer} {
  }
`;

export const Image = styled.div`
  &:first-child {
    grid-column: 1/5;
  }
  top: ${props => (props.toRight ? "3vw" : "unset")};
  height: ${props => (props.toRight ? "27.77777777777778vw" : "unset")};
  grid-column: ${props => (props.id === 1 ? 5 + "/" + 9 : 6 + "/" + 9)};
  border-radius: 20px;
  max-width: ${props => (props.toRight ? "unset" : props.maxWidth)};
  margin-left: ${props => (props.toRight ? "7.5vw" : "")};
  background: url(${props => props.bg}) no-repeat center center / cover;
`;

export const ItemContainer = styled.div``;

export const HeadlineContainer = styled.div``;

export const HeadTitle = styled.h3`
  font-family: Poppins;
  color: ${props => props.color};
  font-size: 72px;
  margin-bottom: 15px;
`;

export const HeadSubtitle = styled.h4`
  color: ${props => props.color};

  font-family: Roboto;
  font-size: 40px;
  line-height: 60px;
  font-weight: 300;
  margin-bottom: 50px;

  b {
    font-weight: 600;
  }
`;

export const ItemImage = styled.div`
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: 33vw;
  border-radius: 20px;
`;

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  grid-gap: 30px;
  margin-bottom: 40px;
`;

export const GridColumn = styled.div``;
