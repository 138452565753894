import React, { Suspense, useEffect, useState, Fragment } from "react";
import axios from "axios";

// STYLES
import { Wrapper, WrapperCards, WrapBtn } from "./style";
import { useMediaQuery } from "react-responsive";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import { setHeaders } from "../../utils/setHeaders"

// COMPONENTS
/* const BlogCards = lazy(() => import("../../components/commons/blogCards"));
const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const Button = lazy(() => import("../../components/commons/buttonGeneric")); */

import BlogCards from "../../components/commons/blogCards"
import HeroViews from "../../components/commons/heroViews"
import Button from "../../components/commons/buttonGeneric"

export default () => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  const [itemsToShow, setItemsToShow] = useState(6);
  const [data, setData] = useState(null);
  const [posts, getPosts] = useState(null);

  const handleToShow = () => {
    if (itemsToShow < posts.length) {
      setItemsToShow(itemsToShow + 6);
    }
  };

  useEffect(() => {
    if (isMobile) {
      setItemsToShow(4);
    } else {
      setItemsToShow(6);
    }

    document.body.style.overflowAnchor = "none";
  }, []);

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/23");
    getAllPost(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/blog_posts");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data.acf);
      setHeaders(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllPost(url) {
    // , process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/blog_posts"
    // console.log('views/blog/getAllPost('+url+')');
    try {
      const response = await axios.get(url);
      getPosts(response.data.filter(d => !d.acf.blog_post_draft));
      // console.log('views/blog/getAllPost('+url+'): getPosts > SUCCESS', posts);
    } catch (error) {
      console.log('views/blog/getAllPost('+url+'): getPosts > ERROR', error);
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {data ? (
        <AnimatedWrap>
          <Wrapper>
            <HeroViews
              bg={data.blog_hero_image}
              title={data.blog_title}
              customHeight={"500px"}
              loginButton
              logintop={"220px"}
            />
            <WrapperCards>
              {posts && (
                <Fragment>
                  {posts.slice(0, itemsToShow).map((item, index) => (
                    <BlogCards
                      key={index}
                      post={item}
                      sectionName={"blog"}
                    ></BlogCards>
                  ))}
                </Fragment>
              )}
            </WrapperCards>

            {posts && (
              <Fragment>
                {itemsToShow < posts.length && (
                  <WrapBtn onClick={() => handleToShow()}>
                    <Button
                        title="View More"
                    ></Button>
                  </WrapBtn>
                )}
              </Fragment>
            )}
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
