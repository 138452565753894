import styled from "styled-components";

import { XS } from "../../../utils/constants";
export const Wrapper = styled.div`
  max-height: 100%;

  @media (max-width: ${XS}) {
    margin-left: 25px;
  }

  img {
    width: 160px;
    height: 100%;
    margin-bottom: -60px;
    position: relative;
    bottom: 28px;
    right: 30px;
    // filter: ${props => (props.showbg === true ? "none" : "drop-shadow(0 0 2px #0000004d)")};
  }
`;

export const LogoBgWhite = styled.img`
  width: 120px !important;
  height: 100%;
  margin-bottom: -60px;
  position: relative;
  bottom: 28px;
  right: 10px !important;

  transition:${props => props.theme.defaultTransition()};
`;
