import { useEffect, useState } from 'react'
import {debounce} from 'lodash'

export default ({ref, debounceTime = 10, }) => {
    const [scrollYPos, setScrollYPos] = useState(0)
	const handleScroll = () => {
        if(!!ref && !!ref.current) {
            setScrollYPos(Math.abs(ref.current.getBoundingClientRect().top))
        }
    }
	useEffect(() => {
        window.addEventListener('scroll', debounce(handleScroll, debounceTime), true);
	  return () => {
        window.removeEventListener('scroll', () => debounce(handleScroll, debounceTime), true);
	  };
    }, [ref, debounceTime, handleScroll]);
    return scrollYPos
}