//react helmet

import React, { useContext, Suspense } from "react";

import { useMediaQuery } from "react-responsive";
import { ThemeContext } from "styled-components";
import { Wrapper, Headline, MobileWrapper, Image } from "./style";
import { StyledP } from "../../../components/commons/Paragraph/style";
import LoaderSpin from "../../../components/LoaderSpin/LoaderSpin"

/* const SecondTitle = lazy(() =>
  import("../../../components/commons/secondTitle")
); */

import SecondTitle from "../../../components/commons/secondTitle"

export default props => {
  const theme = useContext(ThemeContext);
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  return (
    <Suspense fallback={<LoaderSpin>welcome</LoaderSpin>}>
      {!isMobile ? (
        <Wrapper imageToRight={props.imageToRight} itemAlign={props.itemAlign}>
          <Headline>

            <SecondTitle
              title={props.title}
              maxWidth={"635px"}
              textAlign={"left"}
              color={theme.blackColor()}
            />

            <StyledP
              color={theme.blackColor()}
              textAlign={"left"}
              maxWidth={"595px"}
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          </Headline>
          <Image bg={props.bg} customHeight={props.customHeight} />
        </Wrapper>
      ) : (
        <MobileWrapper>
          <Headline>
            <SecondTitle
              title={props.title}
              maxWidth={"635px"}
              textAlign={"left"}
              color={theme.blackColor()}
            />

            <Image bg={props.bg} customHeight={props.customHeight} />

            <StyledP
              color={theme.blackColor()}
              textAlign={"left"}
              maxWidth={"595px"}
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          </Headline>
        </MobileWrapper>
      )}
    </Suspense>
  );
};
