import React, { useRef, useEffect } from 'react'
import { Wrapper } from './style'
import { useScrollListener } from '../../utils'

export default ({children, setScrollYPos}) => {
	const wrapRef = useRef(null)
	let scrollYPos = useScrollListener({ref: wrapRef})
	useEffect(() => {
		setScrollYPos(scrollYPos)
	}, [scrollYPos, setScrollYPos])
    return (
        <Wrapper ref={wrapRef}>
            {children}
     
        </Wrapper>
    )
}