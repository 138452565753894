import React from "react";

import { Wrapper, StyledP } from "./style";

export default props => {
  return (
    <Wrapper>
      <StyledP
        color={props.color}
        textAlign={props.textAlign}
        maxWidth={props.maxWidth}
        paddingBottom={props.paddingBottom}
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></StyledP>
    </Wrapper>
  );
};
