import React, { Suspense, useEffect } from "react";
import { toggleMobileMenu } from "../../../redux/actions";
import { connect } from "react-redux";
import { Wrap } from "./style";

/* const MobileNav = lazy(() => import("../mobileNav")); */
import MobileNav from "../mobileNav"

const MenuComponent = ({ menuOpen }) => {
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menuOpen]);

  return (
    <Suspense fallback={null}>
      <Wrap opened={menuOpen}>
        <MobileNav />
      </Wrap>
    </Suspense>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    menuToggle: () => dispatch(toggleMobileMenu())
  };
};

const mapStateToProps = state => {
  return {
    menuOpen: state.toggleMobileMenu.menuOpened
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
