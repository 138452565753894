import React, { Suspense, useEffect, useState } from "react";

// STYLES
import { Wrapper, WrapperCards, WrapBtn } from "./style";
import { useMediaQuery } from "react-responsive";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import axios from "axios";
import {setHeaders} from "../../utils/setHeaders"
import { createBrowserHistory } from "history";

// COMPONENTS
/* const BlogCards = lazy(() => import("../../components/commons/blogCards"));
const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const Button = lazy(() => import("../../components/commons/buttonGeneric")); */

import BlogCards from "../../components/commons/blogCards"
import HeroViews from "../../components/commons/heroViews"
import Button from "../../components/commons/buttonGeneric"

// TEMP DATA

export default () => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  const [itemsToShow, setItemsToShow] = useState(6);
  const [posts, setPosts] = useState(null);
  const [page, setPage] = useState(null);
  const history = createBrowserHistory();

  const handleToShow = () => {
    if (itemsToShow < posts.length) {
      setItemsToShow(itemsToShow + 6);
    }
  };

  useEffect(() => {
    if (isMobile) {
      setItemsToShow(4);
    } else {
      setItemsToShow(6);
    }

    document.body.style.overflowAnchor = "none";
  }, []);

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/newsupdatesposts");
    fetchPage(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/76");
  }, []);

  async function fetchData(url) {
    // console.log('views/newsAndUpdates/fetchData('+url+')');
    try {
      const response = await axios.get(url);
      setPosts(response.data.filter(d => !d.acf.blog_post_draft));
      setHeaders(response.data, history.location.pathname);
      // console.log('views/newsAndUpdates/fetchData('+url+'): SUCCESS', response, posts);
    } catch (error) {
      console.log('views/newsAndUpdates/fetchData('+url+'): ERROR', error);
      console.error(error);
    }
  }

  async function fetchPage(url) {
    try {
      const response = await axios.get(url);
      setPage(response.data.acf);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={null}>
      {posts && page ? (
        <AnimatedWrap>
          <Wrapper>
            <HeroViews
              bg={page.news_and_updates_hero_image}
              title="News & Updates "
              customHeight={"500px"}
              loginButton
              logintop={"220px"}
            />
            <WrapperCards>
              {posts.slice(0, itemsToShow).map((item, index) => (
                <BlogCards
                  newsRoute={"/vacation-club/news-n-updates/"}
                  key={index}
                  post={item}
                  sectionName={"news"}
                ></BlogCards>
              ))}
            </WrapperCards>

            {itemsToShow < posts.length && (
              <WrapBtn onClick={() => handleToShow()}>
                <Button>view more</Button>
              </WrapBtn>
            )}
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
