import React, { Suspense } from "react";

// STYLES
import {
  Wrapper,
  Right,
  Left,
  ParaWrap,
  middleSection,
  MainSubtitle,
  SubtitleWrap,
  Title,
  MoreInfoText
} from "./style";


import Paragraph from "../../../components/commons/Paragraph"

export default props => (
  <Suspense fallback={null}>
    <Wrapper
      columnGap={props.columnGap}
      gridOrder={props.gridOrder}
      as={props.middleSection ? middleSection : ""}
    >
      <Right customTop={props.customTop}>
        {props.title && <Title>{props.title}</Title>}
        {props.subtitle && (
          <SubtitleWrap>
            <MainSubtitle
              mobileLineHeight={"24px"}
              mobileSize={"16px"}
              title={props.subtitle}
            ></MainSubtitle>
          </SubtitleWrap>
        )}
        <React.Fragment>
          <ParaWrap>
            <Paragraph text={props.text}></Paragraph>
          </ParaWrap>
        </React.Fragment>
        <React.Fragment>
          {props.moreinfo && (
            /*       <MoreInfoText>
              {props.moreinfo}
              {props.link && (
                <a href={props.link} rel="noopener" target="_blank">
                  {" www.visitcaymanislands.com"}
                </a>
              )}
            </MoreInfoText> */
            <MoreInfoText>
              <Paragraph text={props.moreinfo}></Paragraph>
            </MoreInfoText>
            
          )}
        </React.Fragment>
      </Right>
      <Left imageToEdge={props.imageToEdge} bg={props.image}></Left>
    </Wrapper>
  </Suspense>
);
