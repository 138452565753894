import styled from "styled-components";
import {XS, LG} from "../../../utils/constants"

export const Wrapper = styled.div``;

export const RoundedImage = styled.div`
  padding: 20px;
  grid-area: image;
  width: 100%;
  height: ${props => (props.customHeight ? props.customHeight : "600px")};
  border-radius: 20px;
  display: block;
  background-position: center center;
  background-size: cover;
  background-image: url(${props => props.bg});

  @media (max-width: 1320px) {
    height: 400px;
    justify-self: flex-end;
  }

  @media(max-width:${LG}){
    background-image: url(${props => props.bgMobile ? props.bgMobile : props.bg});
  }

  @media(max-width:${XS}){
    background-image: url(${props => props.bgMobile ? props.bgMobile : props.bg});
  }
`;
