import React, { Suspense, lazy, useContext, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  Wrapper,
  GridItem,
  TitleContainer,
  UpperTitle,
  Title,
  Image,
  GridTwoColumns,
  MobileImgWrap,
  MobileCaret
} from "./style";

/* const Paragraph = lazy(() => import("../Paragraph"));
const Button = lazy(() => import("../buttonGeneric")); */

import Paragraph from "../Paragraph"
import Button from "../buttonGeneric"

export default props => {
  const theme = useContext(ThemeContext);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });
  return (
    <Suspense fallback={null}>
      <Wrapper>
        {props.data.map((card, s) => (
          <Fragment key={s}>
            {!isMobile ? (
              <GridItem hideCard={props.hideCard && s === 1}>
                <TitleContainer>
                  <UpperTitle color={theme.blackColor()}>
                    {card.upperTitle}
                  </UpperTitle>
                  <Title color={theme.blackColor()}>{card.title}</Title>
                </TitleContainer>
                <Image bg={card.image} />
                <GridTwoColumns>
                  <Paragraph color={theme.blackColor()} text={card.text} />
                  <Link to={card.route}>
                    <Button transition={theme.defaultTransition()} />
                  </Link>
                </GridTwoColumns>
              </GridItem>
            ) : (
              <GridItem>
                <TitleContainer>
                  <Title color={theme.blackColor()}>{card.title}</Title>
                </TitleContainer>
                <GridTwoColumns>
                  <Paragraph color={theme.blackColor()} text={card.text} />
                </GridTwoColumns>
                <MobileImgWrap>
                  <MobileCaret noCaret={props.noCaret}>
                    <span>{card.upperTitle}</span>
                  </MobileCaret>
                  <Image bg={card.image} />
                  <Link to={card.route}>
                    <Button transition={theme.defaultTransition()} />
                  </Link>
                </MobileImgWrap>
              </GridItem>
            )}
          </Fragment>
        ))}
      </Wrapper>
    </Suspense>
  );
};
