import styled from "styled-components";
import {
  HIGHER_RESOLUTIONS,
  LOWEST_RESOLUTIONS,
  XL,
  LG
} from "../../../../utils/constants";

export const Wrapper = styled.div`
  margin-bottom: 65px;
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.5fr;
  max-width: 1111px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  margin: 0 160px 60px;
  position: relative;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    margin: 0 120px;
  }

  @media (max-width: ${LOWEST_RESOLUTIONS}) {
    margin: 0 30px 60px;
    max-width: unset;
  }

  @media (max-width: ${XL}) {
    grid-template-columns: 0.6fr 1fr 0.8fr;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    margin: 0 20px 60px;
  }
`;

export const Image = styled.div`
  background: url(${props => props.bg}) no-repeat center center / cover;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`;

export const TextContent = styled.div`
  padding: 30px 60px 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: ${props => props.theme.darkBlue};
    @media(max-width:${LG}){
      padding:20px !important;
    }
  }

  h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 300;
    color: ${props => props.theme.darkBlue};
    @media (max-width: ${LG}) {
      padding: 30px 40px 0 40px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  h4 {
    font-family: Poppins;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    color: ${props => props.theme.darkBlue};
    margin-bottom: 15px;
    @media (max-width: ${LG}) {
      padding: 0 40px;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      margin-top: -5px;
    }
  }

  p {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    color: ${props => props.theme.darkBlue};
    @media (max-width: ${LG}) {
      padding: 20px 40px;
    }
  }

  @media (max-width: ${LG}) {
    padding: 0;
  }
`;

export const HoursWrap = styled.div`
  background-color: rgba(208, 220, 232, 0.18);

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 30px 40px 30px;

  p {
    color: ${props => props.theme.darkBlue};
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -10px;
    margin-bottom: 15px;
  }

  @media (max-width: ${LG}) {
    padding: 40px 30px 40px 40px;
  }
`;

export const HoursContent = styled.div`
  margin-bottom: 20px;
  h4 {
    color: ${props => props.theme.darkBlue};
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 300;

    span {
      color: ${props => props.theme.skyBlue};
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      display: block;
    }
  }
`;

export const Disclamer = styled.div`
  position: absolute;
  right: -13vw;
  bottom: -40px;
  max-width: 449px;
  width: 29vw;
  padding: 20px;
  border-radius: 16px 0 0 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);

  em {
    color: ${props => props.theme.skyBlue};
    font-style: normal;
    margin-left: -2px;
    display: inline-block;
    vertical-align: top;
  }

  a{
    color:${props => props.theme.skyBlue};
  }

  p {
    max-width: 230px;
    color: ${props => props.theme.darkBlue};
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 300;
    padding-left: 5px;
    display: inline-block;
    span {
      font-weight: 600;
    }
  }

  @media (max-width: ${LOWEST_RESOLUTIONS}) {
    right: -10vw;
  }

  @media (max-width: ${XL}) {
    width: 50vw;
  }

  @media (max-width: ${LG}) {
    width: auto;
    bottom: -50px;
  }
`;

export const MobilePic = styled.div`
  height: 168px;
  background: url(${props => props.bg}) no-repeat center center / cover;
`;
