import styled from "styled-components";
import { LG } from "../../../utils/constants";

export const Wrapper = styled.div``;

export const Title = styled.h1`
  color: #ffffff;
  font-family: Poppins;
  font-size: ${props => (props.isPost ? "64px" : "120px")};
  max-width: ${props => (props.isPost ? "808px" : "none")};
  line-height: ${props => (props.isPost ? "80px" : "120px")};
  font-weight: bold;
/*   position: relative;
  z-index: ${props => (props.menuOpen ? "800" : "910")}; */
  position:relative;
  z-index:10;

  @media (max-width: ${LG}) {
    font-size: ${props => (props.mobileWidth ? props.mobileWidth : "54px")};
    line-height: 1;

    margin-top:${props => props.loginButton ? '80px': 'initial'};
  }
`;
