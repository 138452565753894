import styled from "styled-components";

// IMAGES
import img from "../../../assets/icons/eye-icon.svg";
import ErrorIcon from "../../../assets/errorfilled.svg";
import { GrowAnimation } from "../../../utils/KeyFrames";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ShowError = styled.div`
  background: url(${ErrorIcon}) no-repeat center center / contain;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 5px;
  animation: ${GrowAnimation} 0.5s ease-out 0s forwards;

  opacity: 1;
  visibility: visible;
`;

export const Input = styled.input`
  border: 1px solid transparent;
  border-bottom: ${props =>
    props.errorMail === false && props.inputItem === "email"
      ? "1px solid #E65758 !important"
      : "1px solid #ffffff !important"};
  padding: 4px;
  margin-bottom: 20px;
  outline: none !important;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  &::placeholder {
    font-family: Roboto;
    color: #d0dce8;
    font-weight: 400;
  }
`;

export const White = styled.input`
  border-bottom: 1px solid white;
  color: white;
  &::placeholder {
    color: white;
  }
`;

export const ShowPassword = styled.button`
  border: 0;
  background: transparent url(${img}) no-repeat center;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  outline: none !important;
  cursor: pointer;
  filter: invert(89%) sepia(16%) saturate(177%) hue-rotate(169deg)
    brightness(97%) contrast(91%);
`;

export const hidePassword = styled.button`
  &::after {
    content: "";
    width: 26px;
    height: 24px;
    border-bottom: 2px solid #333333;
    position: absolute;
    top: -33px;
    left: -13px;
    transform: translateY(20px) translateX(5px) rotate(-26deg);
  }
`;
