import styled from "styled-components";

import { HIGHER_RESOLUTIONS, XL, LG, XS } from "../../../utils/constants";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  max-width: 1111px;
  margin: 0 auto;
  padding: 30px 0 0;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    width: 80%;
  }

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr;
    padding: 30px 15px;
  }

  @media (max-width: ${XS}) {
    grid-gap: 40px 30px;
  }
`;

export const GridItem = styled.div`
  display: ${props => (props.hideCard ? "none" : "block")};
`;

export const GridTwoColumns = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  grid-gap: 30px;
  justify-content: start;
  justify-items: right;
  margin-top: 40px;

  @media (min-width: ${LG}) {
    p {
      padding-left: 5px;
    }
  }
  @media (max-width: ${LG}) {
    grid-gap: 20px;
    grid-template-columns: 1fr;

    p {
      padding-bottom: 20px;
    }
  }
`;

export const TitleContainer = styled.div`
  color: ${props => props.theme.darkBlue};

  @media (min-width: ${LG}) {
    padding-left: 5px;
  }
`;

export const UpperTitle = styled.h4`
  color: ${props => props.theme.darkBlue};
  font-family: Poppins;
  font-size: 24px;
  line-height: 40px;
  font-weight: 300;

  @media (max-width: ${LG}) {
    display: none;
  }
`;

export const Title = styled.h3`
  font-family: Poppins;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;

  margin: 0 0 30px;

  @media (max-width: ${LG}) {
    margin: 0 0 -25px;
    font-size: 40px;
    line-height: 48px;
  }
`;

export const Image = styled.div`
  height: 385px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  border-radius: 20px;
  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    height: 23.819444444444443vw;
  }

  @media (max-width: ${LG}) {
    height: 323px;
    margin-bottom: 30px;
  }
`;

export const MobileImgWrap = styled.div`
  text-align: center;
  position: relative;
`;

export const MobileCaret = styled.div`
  height: 31px;
  width: auto;
  border-radius: 8px 0 0 8px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 30px;
  right: 0;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 20px;

  display: ${props => (props.noCaret ? "none" : "block")};

  span {
    color: ${props => props.theme.skyBlue};
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 34px;
    text-transform: uppercase;
  }
`;
