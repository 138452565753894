import styled from "styled-components";
import { LOWEST_RESOLUTIONS, LG } from "../../utils/constants";

export const Wrapper = styled.div` 
  font-family: Roboto;
  letter-spacing: 0;
  width: 100%;
  color: white;
`;

export const WrapperParagraph = styled.div`
  width: 100%;
  padding: 0 165px 100px;
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // grid-column-gap: 30px;
  // grid-row-gap: 30px;
  margin-top: -80px;

  @media (max-width: ${LOWEST_RESOLUTIONS}) {
    // grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${LG}) {
    // grid-template-columns: 1fr;
    padding: 0 20px 100px;
  }
`;

export const ParaWrapper = styled.div`
min-height: 480px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
  text-decoration: none;
  transition: ${props => props.theme.defaultTransition("all", 0.4)};

  padding: 40px;
  color: #1f3040;
  line-height: 24px;

  position: relative;

  // &:hover {
  //   box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.18);
  // }
`;

export const WrapBtn = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 80px;

  @media(max-width:${LG}){
    width:90%;
   
    button{
      width:100% !important;
      height:70px !important;
    }
  }
  
`;
