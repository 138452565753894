import React, { useEffect } from "react";
import { Wrapper } from "./style";

export default ({ children }) => {
  useEffect(() => {
    return () => {
      window.scrollY !== 0 && window.scroll({ top: 0, left: 0 });
    };
  }, []);
  return <Wrapper>{children}</Wrapper>;
};
