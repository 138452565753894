import React, { useState, useEffect, Fragment } from "react";

import {
  Wrapper,
  Item,
  CounterWrap,
  Numbers,
  CounterTitle,
  VideoBg
} from "./style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

import { useMediaQuery } from "react-responsive";
import LoaderSpin from "../../../components/LoaderSpin/LoaderSpin";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";

/* const HeroHeadline = lazy(() => import("../heroheadline/")); */

import HeroHeadline from "../heroheadline"

export default props => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    afterChange: current =>
      setTimeout(() => {
        setSliderState({ currentSlide: current });
      }, 10)
  };

  const [sliderState, setSliderState] = useState({
    currentSlide: 0
  });

  const [slides, setSlides] = useState();
  const [sliderNum, setSliderNum] = useState();
  const [stop, setStop] = useState(false);
  const [slidesTitles, setSliderTitles] = useState();

  useEffect(() => {
    getAllSlides(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/homepage_slide");
  }, []);

  async function getAllSlides(url) {
    try {
      const response = await axios.get(url);
      setSlides(response.data);
      setSliderNum(response.data);
      setSliderTitles(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  if (sliderNum && slides && !stop) {
    if (slides.length <= sliderNum.length) {
      slides.splice(sliderNum.length, 0, props.data.homepage_video);
      slides.reverse().join();
      setStop(true);
    }
  }

  return (
    <Fragment>
      {slides && props ? (
        <Wrapper>
          <AnimatedWrap>
            <HeroHeadline data={props.data} info={props.info} />

            {!isMobile ? (
              <Slider {...settings}>
                {slides.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      {i === 0 ? (
                        <VideoBg autoPlay muted loop id="myVideo">
                          <source src={item.url} type="video/mp4" />
                        </VideoBg>
                      ) : (
                        <div>
                          <Item
                            bg={item.acf && item.acf.homepage_slide_image}
                          ></Item>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </Slider>
            ) : (
              <Slider {...settings}>
                {slides.slice(1, -1).map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <Item
                        bg={item.acf && item.acf.homepage_slide_image}
                      ></Item>
                    </Fragment>
                  );
                })}
              </Slider>
            )}

            <CounterWrap>
              <Numbers>
                {sliderState.currentSlide + 1} / {slides.length}
              </Numbers>
              <CounterTitle>
                {sliderState.currentSlide === 0
                  ? props.data.homepage_video_title
                  : slidesTitles &&
                    slidesTitles[sliderState.currentSlide].title.rendered}
              </CounterTitle>
            </CounterWrap>
          </AnimatedWrap>
        </Wrapper>
      ) : (
        <LoaderSpin>sliderHome</LoaderSpin>
      )}
    </Fragment>
  );
};
