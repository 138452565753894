import styled from "styled-components";

import { XL, XS, LG } from "../../../utils/constants";
import {
  OpacityAnimation,
  ScrollDownAnimation
} from "../../../utils/KeyFrames";

export const Wrapper = styled.div`
  position: absolute;
  top: 51%;
  left: 11.5vw;
  z-index: 800;
  transform: translate(0, -50%);
  max-width: 850px;

  h1 {
    margin-bottom: 75px;
  }

  button {
    margin-top: -15px;
    font-weight: 600;

    &:hover {
      color: ${props => props.theme.darkBlue};
    }
  }

  @media (max-width: ${LG}) {
    margin-right: 40px;
  }

  @media (max-width: ${XS}) {
    left: 20px;
    display: block;
    top: 200px;
    transform: unset;
    h1 {
      font-size: 56px;
      line-height: 64px;
      margin-right: 15px;
      margin-top: 45px;
    }
  }
`;

export const BottomWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 46%;
  z-index: 333;
  display: grid;
  grid-template-columns: 1fr 1fr;
  left: 11.5vw;

  @media (max-width: ${XL}) {
    width: 50%;
  }

  @media (max-width: ${XS}) {
    width: auto;
    top: 100px;
    bottom: unset;
    right: 0vw;
    left: unset;
    transform: initial;
    display: block;
    z-index: 400;
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     height: 117px;
  }
  @media screen and (max-width: 1199px) and (-ms-high-contrast: active), screen and (max-width: 1199px) and (-ms-high-contrast: none) {  
     height: 104px;
     width: 420px;
  }
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0 0;
  position: relative;

  z-index: 2;
  text-align: left;
  padding: 43px 85px 10px;

  h3,
  a {
    position: relative;
  }

  h3 {
    a {
      color: #ffffff;
      font-family: Poppins;
      font-size: 40px;
      letter-spacing: 0;
      line-height: 16px;
      text-transform: uppercase;
      font-weight:600;
      text-decoration:none !important;
    }
  }

  a {
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    display: block;
    line-height: 3;
    font-weight: 300;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${XL}) {
    padding: 30px 40px 10px;
    h3 {
      a{
        font-size: 26px;
      }

    }
  }
`;

export const MaskContainer = styled.div`
  opacity: 0.6;
  border-radius: 16px 16px 0 0;
  background-color: #1f3040;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  @media (max-width: ${XS}) {
    border-radius: 0;
    opacity: 0.8;
  }
`;

export const ScrollIcon = styled.div`
  align-self: flex-end;
  padding-bottom: 30px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;

  @media (max-width: ${XS}) {
    display: none !important;
  }
  
    /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     display: none;
  }
`;

export const AnimatedArrow = styled.div`
  display: block;
  position: relative;
  width: 2px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 15px;
`;

export const BarAnimated = styled.div`
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation: ${ScrollDownAnimation} 1.3s ease-in-out 0s infinite;
  border-radius: 2px;
`;
