import React from "react";

import { Wrapper, Title } from "./style";

export default props => {
  return (
    <Wrapper>
      <Title menuOpen={props.menuOpen} loginButton={props.loginButton} isPost={props.isPost}>{props.title}</Title>
    </Wrapper>
  );
};
