import styled from "styled-components";

import { HIGHER_RESOLUTIONS, XL, LG, XXS } from "../../../utils/constants";
import { PopAnimation } from "../../../utils/KeyFrames";

export const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  max-width: 1111px;
  border-radius: 16px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: ${props => props.normalVideo ? '0' : '-50px'};
  border-radius: 30px;
  margin-bottom: 50px;
  cursor: pointer;

  position: relative;

  video {
    width: 100%;
    height: 100%;
    width: 1260px;
    height: 710px;
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    height: 800px;
    max-width: 1561px;
    width: 80%;

    video {
      width: 1920px;
      height: 1080px;
    }
  }

  @media (max-width: ${XL}) {
    width: 95%;
    margin-top: 30px;
  }

  @media (max-width: ${LG}) {
    height: 300px;
    video {
      width: 800px;
      height: 450px;
    }
  }

  @media (max-width: ${XXS}) {
    height: 200px;
    margin-top: 40px;
    video {
      width: 615px;
      height: 350px;
    }
  }
`;

export const PlayMask = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${props => (props.videoPlay ? "block" : "none")};

  &:hover {
    > div {
      background-color: ${props => props.theme.darkBlue};
      border: 2px solid ${props => props.theme.darkBlue};
    }
  }

  > div {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d0dce8;
    border-radius: 50%;
    transform: scale(1);
    animation: ${PopAnimation} 0.3s ease-out 0s forwards;
    transition: ${props => props.theme.defaultTransition()};

    img {
      padding: 20px;
    }
  }
`;
