import styled from "styled-components";
import { XL, XS, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  width: 100%;
  padding: 60px 165px;

  @media (max-width: ${XL}) {
    padding: 65px 60px;
  }

  @media (max-width: ${XS}) {
    padding: 0 20px 20px;
    margin-top: -10px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  a {
    text-decoration: none;
  }
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     display: flex;
     width: 100%;
     justify-content: space-between;
     
     > a {
      width: 32%;
     }
  }
  @media screen and (max-width: 699px) and (-ms-high-contrast: active), screen and (max-width: 699px) and (-ms-high-contrast: none) {  
     display: block;
  }
`;

export const Card = styled.div`
  text-align: left;
  
  // /* IE10+ specific styles */
  // @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  //    width: 33%;
  // }
  // @media screen and (max-width: 699px) and (-ms-high-contrast: active), screen and (max-width: 699px) and (-ms-high-contrast: none) {  
  //    width: 100%;
  //    margin-right: 0;
  // }
`;

export const Title = styled.div`
  font-size: 32px;
  line-height: 24px;
  font-family: poppins;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${props => props.color};
  text-align: left;
  margin: 30px 0;

  @media(max-width:${LG}){
    font-size:24px;
  }
`;

export const RoundedImage = styled.div`
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  height: 27.77777777777778vw;
  border-radius: 20px;
`;

export const MobileCardsWrap = styled.div`

padding-top:15px;

`;

export const MobileCard = styled.div`
  box-shadow: 0 20px 80px 1px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  margin-bottom:30px;

  > a {
    text-decoration: none;
  }
`;

export const MobileImage = styled.div`
  width: 100%;
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: 160px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const TextWrap = styled.div`
  background-color: white;
  padding: 20px 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h3 {
    color: ${props => props.theme.skyBlue};

    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    padding-left:30px;
  }
`;
