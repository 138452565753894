import React, { Suspense } from "react";

import {
  Wrapper,
  MainTitle,
  IconList,
  TitleAndIconsWrap,
  Disclaimer,
  Grid,
  TitleMobile,
  IconLi
} from "./style";

import BackgroundImageList from "../../../assets/stay-images/background-mobile.jpg";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import { useMediaQuery } from "react-responsive";

export default props => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  return (
    <Suspense>
      {props.features && (
        <AnimatedWrap>
          <Wrapper>
            <TitleAndIconsWrap>
              {!isMobile ? (
                <MainTitle title={props.data.acf.stay_title} />
              ) : (
                <TitleMobile>
                  <p>{props.data.acf.stay_title}</p>
                </TitleMobile>
              )}

              <Grid bg={BackgroundImageList}>
                <IconList>
                  {props.features.map((item, i) => (
                    <IconLi
                      key={i}
                      disclamer={item.acf.stay_feature_disclaimer !== null}
                    >
                      <img
                        src={item.acf.icon_feature}
                        alt={item.title.rendered.replace("&#038;", "&")}
                      />
                      <div>
                        <p>{item.title.rendered.replace("&#038;", "&")}</p>
                        <span index={i}>
                          {item.acf.disclaimer}
                        </span>
                      </div>
                    </IconLi>
                  ))}
                </IconList>
                <Disclaimer>
                  <span>
                    <em>*</em> {props.data.acf.stay_disclaimer}
                  </span>
                </Disclaimer>
              </Grid>
            </TitleAndIconsWrap>
          </Wrapper>
        </AnimatedWrap>
      )}
    </Suspense>
  );
};
