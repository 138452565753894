import styled from "styled-components";

export const Wrapper = styled.div``;

export const StyledP = styled.p`
  font-size:16px;
  letter-spacing:-0.2px;
  font-weight:400;
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : '40px'};
  line-height:30px;

  max-width:${props => props.maxWidth};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};

  span{
    font-weight:bold;
  }
  
  ul {
    margin: 5px 0;
    margin-left: 20px;
  }
  
  li {
    margin: 5px 0;
  }
  
  h2 {
  margin: 30px 0 10px;
  }
  
  h3 {
    margin: 20px 0 10px;
  }

`;
