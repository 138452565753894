import { TOGGLE_MOBILE_MENU } from '../actionTypes'

const initialState = { menuOpened: false }
  
export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_MOBILE_MENU:
            return {
                ...state,
                menuOpened: !state.menuOpened
            }
        default:
            return state
    }
}