import React, { Suspense, Fragment } from "react";
/* import { ThemeContext } from "styled-components"; */
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import {
  Wrapper,
  Grid,
  TextContainer,
  HotelSubtitle,
  SpecsContainer,
  SpecsTitle,
  Icon,
  SpecsUl,
  ListItem,
  AsideImg,
  MobileWrapper,
  LocationMark,
  LocationWrap,
  MobileTextContainer,
  TitleMobile,
  TextMobile,
  SpecSubtitle,
  SpecsDefaultWrap
} from "./style";

import MapMarket from "../../../assets/map.svg";
import { AnimatedWrap } from "../../commons/AnimatedComponents";

/* const Paragraph = lazy(() => import("../Paragraph")); */

import Paragraph from "../Paragraph"

export default props => {
  const location = useLocation();
  const locationParse = location.pathname.replace("/", "");

  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });
  return (
    <Suspense fallback={null}>
      {props.data && props.specs && props.specsHeadline ? (
        <AnimatedWrap>
          {!isMobile && props.data && props.specs && props.specsHeadline ? (
            <Wrapper>
              <Grid>
                <TextContainer>
                  {/*    <HotelTitle color={props.color}>{props.data.title}</HotelTitle> */}
                  <HotelSubtitle color={props.color}>
                    {props.data.hotel_title}
                  </HotelSubtitle>
                  <Paragraph text={props.data.hotel_text} color={props.color} />
                </TextContainer>

                {props.specsHeadline.tortuga ? (
                  <Fragment>
                    <SpecsContainer>
                      <div>
                        <SpecsTitle>Poolside Villas</SpecsTitle>
                        <SpecSubtitle>
                          These villas provide tropical pool and garden views,
                          and feature:
                        </SpecSubtitle>
                        <SpecsUl>
                          {props.poolSideVillage.map((item, i) => (
                            <AnimatedWrap key={i}>
                              <ListItem>
                                <Icon
                                  src={item.acf.icon_feature}
                                  alt={item.title.rendered.replace(
                                    "&#038;",
                                    "&"
                                  )}
                                />
                                {item.title.rendered.replace("&#038;", "&")}
                              </ListItem>
                            </AnimatedWrap>
                          ))}
                        </SpecsUl>
                      </div>
                      <div>
                        <SpecsTitle>Ocean Front Suites</SpecsTitle>
                        <SpecSubtitle>
                          These suites offer stunning views of turquoise waters
                          and sun-bleached sands, featuring:
                        </SpecSubtitle>
                        <SpecsUl>
                          {props.oceanFrontSuites.map((item, i) => (
                            <AnimatedWrap key={i}>
                              <ListItem>
                                <Icon
                                  src={item.acf.icon_feature}
                                  alt={item.title.rendered.replace(
                                    "&#038;",
                                    "&"
                                  )}
                                />
                                {item.title.rendered.replace("&#038;", "&")}
                              </ListItem>
                            </AnimatedWrap>
                          ))}
                        </SpecsUl>
                      </div>
                    </SpecsContainer>
                  </Fragment>
                ) : (
                  <SpecsContainer>
                    <div>
                      <SpecsTitle>{props.specsHeadline.title}</SpecsTitle>
                      <SpecSubtitle>
                        {props.specsHeadline.subtitle}
                      </SpecSubtitle>
                      <SpecsUl>
                        {props.specs.map((item, i) => (
                          <AnimatedWrap key={i}>
                            <ListItem>
                              <Icon
                                src={item.acf.icon_feature}
                                alt={item.title.rendered.replace("&#038;", "&")}
                              />
                              {item.title.rendered.replace("&#038;", "&")}
                            </ListItem>
                          </AnimatedWrap>
                        ))}
                      </SpecsUl>
                    </div>
                  </SpecsContainer>
                )}
              </Grid>
              <AsideImg bg={props.mainImage} />
            </Wrapper>
          ) : (
            <MobileWrapper tortuga={true}>
              {locationParse === "stay/tortuga-club" ||
              locationParse === "/stay/tortuga-club" ? (
                <Fragment>
                  <MobileTextContainer>
                    <TitleMobile>{props.data.hotel_title}</TitleMobile>
                  </MobileTextContainer>
                  <LocationWrap>
                    <LocationMark>
                      <img src={MapMarket} alt="MapMarker" />
                      <span>Cayman Islands</span>
                    </LocationMark>
                    <AsideImg bg={props.mainImage} />
                  </LocationWrap>
                  <MobileTextContainer>
                    <TextMobile
                      dangerouslySetInnerHTML={{
                        __html: props.data.hotel_text
                      }}
                    />
                  </MobileTextContainer>
                  <Fragment>
                    <SpecsContainer tortuga={true}>
                      <div>
                        <SpecsTitle>Poolside Villas</SpecsTitle>
                        <SpecSubtitle>
                          These villas provide tropical pool and garden views,
                          and feature:
                        </SpecSubtitle>
                        <SpecsUl>
                          {props.poolSideVillage.map((item, i) => (
                            <ListItem key={i}>
                              <Icon
                                src={item.acf.icon_feature}
                                alt={item.title.rendered.replace("&#038;", "&")}
                              />
                              {item.title.rendered.replace("&#038;", "&")}
                            </ListItem>
                          ))}
                        </SpecsUl>
                      </div>
                      <div>
                        <SpecsTitle>Ocean Front Suites</SpecsTitle>
                        <SpecSubtitle>
                          These suites offer stunning views of turquoise waters
                          and sun-bleached sands, featuring:
                        </SpecSubtitle>
                        <SpecsUl>
                          {props.oceanFrontSuites.map((item, i) => (
                            <ListItem key={i}>
                              <Icon
                                src={item.acf.icon_feature}
                                alt={item.title.rendered.replace("&#038;", "&")}
                              />
                              {item.title.rendered.replace("&#038;", "&")}
                            </ListItem>
                          ))}
                        </SpecsUl>
                      </div>
                    </SpecsContainer>
                  </Fragment>
                </Fragment>
              ) : (
                <Fragment>
                  <SpecsContainer tortuga={true}>
                    <SpecsDefaultWrap>
                      <div>
                        <SpecsTitle>{props.specsHeadline.title}</SpecsTitle>
                        <SpecSubtitle>
                          {props.specsHeadline.subtitle}
                        </SpecSubtitle>
                        <SpecsUl>
                          {props.specs.map((item, i) => (
                            <ListItem key={i}>
                              <Icon
                                src={item.acf.icon_feature}
                                alt={item.title.rendered.replace("&#038;", "&")}
                              />
                              {item.title.rendered.replace("&#038;", "&")}
                            </ListItem>
                          ))}
                        </SpecsUl>
                      </div>
                    </SpecsDefaultWrap>
                  </SpecsContainer>

                  <LocationWrap>
                    <LocationMark>
                      <img src={MapMarket} alt="MapMarker" />
                      <span>Cayman Islands</span>
                    </LocationMark>
                    <AsideImg bg={props.mainImage} />
                  </LocationWrap>
                  <MobileTextContainer>
                    <TitleMobile>{props.data.hotel_title}</TitleMobile>
                    <TextMobile
                      dangerouslySetInnerHTML={{
                        __html: props.data.hotel_text
                      }}
                    />
                  </MobileTextContainer>
                </Fragment>
              )}
            </MobileWrapper>
          )}
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
