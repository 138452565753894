import styled from "styled-components";

import { RoundedImage } from "../../../components/commons/borderImage/style";
import { Subtitle } from "../../../components/commons/subtitle/style";
import { XL, LG, XS, HIGHER_RESOLUTIONS } from "../../../utils/constants";

export const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 140px;

  @media (max-width: ${XS}) {
    margin-top: -10px;
  }
`;

export const FeaturesWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 610px;
  align-items: center;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`;

export const FeaturesList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 40px;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    span {
      max-width: 187px;
      display: inline-block;
      margin-left: 15px;
    }
  }

  @media (max-width: ${XS}) {
    grid-template-columns: 1fr;
    grid-gap: 15px 40px;

    li {
      img {
        width: 25px;
      }
      span {
        max-width: 260px;
      }
    }
  }
`;

export const Icon = styled.img`
  max-width: 24px;
`;

export const IconTitle = styled.span`
  color: ${props => props.theme.skyBlue};
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase;
`;

export const Disclaimer = styled.div`
  background-color: white;
  height: 49px;
  display: flex;
  align-items: center;
  width: 400px;
  margin: 0 0 0 auto;

  border-radius: 16px 0 0 16px;
  background-color: #ffffff;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.03);

  span {
    padding-left: 20px;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    color: ${props => props.theme.darkBlue};
  }

  em {
    color: ${props => props.theme.skyBlue};
    font-style: normal;
  }

  @media (max-width: ${XL}) {
    margin-top: 20px;
  }

  @media (max-width: ${LG}) {
    position: relative;
    top: 75px;
    z-index: 1;
  }

  @media (max-width: ${XS}) {
    top: 0;
    width: 195px;
    margin-bottom: 30px;
    padding: 15px 0;
    height: 52px;
    margin-top: 40px;

    span {
      max-width: 150px;
    }
  }
`;

export const BorderImage = styled(RoundedImage)`
  grid-area: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 480px;

  @media (max-width: ${XL}) {
    height: 480px;
  }

  @media (max-width: ${XS}) {
    border-radius: 0;
    height: 553px;
  }
`;

export const FeaturesContainer = styled.div`
  > div {
    &:first-child {
      max-width: 533px;
      margin: 0 auto;
    }
  }

  @media (max-width: ${XL}) {
    > div {
      &:first-child {
        padding: 0 50px;
      }
    }
  }

  @media (max-width: ${LG}) {
    > div {
      &:first-child {
        max-width: unset;
        padding: 0 50px;
      }
    }
  }

  @media (max-width: ${LG}) {
    > div {
      &:first-child {
        padding: 0 50px 40px 20px;
      }
    }
  }
`;

export const SubtitleMain = styled(Subtitle)`
  margin-bottom: 25px;

  @media (max-width: ${XS}) {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

export const Reservations = styled.div`
  position: relative;

  @media (max-width: ${XS}) {
    margin-top: 40px;
  }
`;

export const Box = styled.div`
  max-width: 381px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 40px 40px 45px;

  position: absolute;
  bottom: -60px;
  left: 60px;

  h4 {
    color: ${props => props.theme.darkBlue};
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
  }

  h5 {
    a {
      color: ${props => props.theme.skyBlue};
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      margin-top: 10px;
      margin-bottom: 15px;
      text-decoration: none;
      display: block;
    }
  }

  ul {
    list-style: none;
    li {
      margin-bottom: 10px;
      max-width: 266px;
      span {
        color: ${props => props.theme.darkBlue};
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
  }

  p {
    color: ${props => props.theme.darkBlue};
    font-weight: 600;
  }

  @media (max-width: ${XS}) {
    left: initial;
    bottom: initial;
    top: -50px;
    max-width: unset;
    margin: 20px;
    padding: 40px 30px;
  }
`;



