import styled from "styled-components";
import Cta from "../../components/commons/cta";
import { XS, XL } from "../../utils/constants";

export const Wrapper = styled.div`
  background-color: grey;
  height: 100vh;
  padding: 60px 165px 100px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  position: relative;

  display: flex;
  align-items: center;

  @media (max-width: ${XL}) {
    padding: 60px 110px 100px;
  }

  @media (max-width: ${XS}) {
    padding: 60px 20px 0;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
  @media (max-width: ${XS}) {
    opacity: 0.6;
  }
`;

export const TitleWrap = styled.div`
  color: #ffffff;
  position: relative;
  z-index: 10;
  max-width: 700px;

  @media (max-width: ${XS}) {
    margin-bottom: 140px;
  }
`;

export const Title = styled.div`
  font-size: 64px;
  padding-bottom: 40px;
  line-height: 80px;
  font-family: Poppins;
  font-weight: bold;
  padding-bottom: 20px;
  line-height: 70px;

  @media (max-width: ${XS}) {
    font-size: 45px;
    line-height: 48px;
  }
`;

export const Text = styled.p`
  font-size: 26px;
`;

export const BtnLink = styled(Cta)`
  margin-top: 40px;
`;
