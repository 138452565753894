import styled from "styled-components";
import { LG } from "../../../utils/constants";
import { GrowAnimation } from "../../../utils/KeyFrames";

export const Wrapper = styled.div`
  padding: 10px 0 30px;
`;

export const MorritForm = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: ${LG}) {
    width: 93%;
  }
`;

export const Button = styled.div`
  align-self: flex-end;
`;

export const SendButton = styled.button`
  outline: none;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: white;
  border-radius: 99em;
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 125px;
  height: 51px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: ${props => props.theme.defaultTransition()};

  &:hover {
    background-color: ${props => props.theme.darkBlue};
    border-color: white;
    color: white !important;
  }
`;

export const EmailAlert = styled.div`
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -7px;
`;

export const ErrorMessage = styled.div`
  color: #ffffff;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
  top: -7px;
  margin-bottom:10px;

  animation: ${GrowAnimation} 0.5s ease-out 0s forwards;
`;

export const TextAreaWrap = styled.div`
  width: 100%;

  textarea {
    width: 100%;
    border: 0;
    background-color: transparent;
    color: white;
    padding-left: 4px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    background: transparent;
    border-bottom: 1px solid white;
    resize: none;
    padding-bottom: 5px;
    display: block;
    font-weight: 400;

    margin-bottom: 20px;

    &:focus {
      outline: 0;
    }

    ::-webkit-input-placeholder {
      color: #ffffff;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #ffffff;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #ffffff;
    }

    :-ms-input-placeholder {
      color: #ffffff;
    }

    ::placeholder {
      color: #ffffff;
    }
  }
`;

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // background: "none",
    // border: "none",
    width: "100%",
    fontSize: "16px",
    boxShadow: "none",
    cursor: "pointer",
    margin: 0,
    transition: "all cubic-bezier(0.15, 0.82, 1, 1) .5s",
    padding: ".5em 0",
    zIndex: 2,
    // borderRadius: "none",
    fontFamily: "Roboto",
    lineHeight: "24px",
    justifyContent: "flex-start",
    // align-items: center;

    paddingLeft: "15px",
    maxWidth: "280px",
    border: "1px solid white",
    borderRadius: "8px",
    marginTop: "50px",
    background: state.isFocused ? "#006dbc45" : "transparent"
  }),
  indicatorSeparator: () => ({
    display: "none",
    color: "white"
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "#006dbc45" : "transparent",
    color: state.isFocused ? "#1F3040" : "#1F3040",
    padding: "1em",
    transition: "all cubic-bezier(0.15, 0.82, 1, 1) .2s;",
    fontSize: "1.1em",
    cursor: "pointer",
    zIndex: 2,

    maxWidth: "280px",
    "&:hover": {
      backgroundColor: "#006dbc45",
      color: "#1F3040"
    }
  }),
  placeholder: provided => ({
    ...provided,
    color: "white",

    position: "relative",
    top: "12px"
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: "#ffffff",
    padding: 0,
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderTop: "6px solid #ffffff",
    position: "absolute",
    right: "20px"
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: 0,
    color: "white",
    flex: "none"
  }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
    color: "white"
  }),
  menu: provided => ({
    ...provided,
    padding: 0,
    border: "none",
    boxShadow: "none",
    ouline: "none",
    color: "white",
    maxWidth: "280px",
    borderRadius: "8px"
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    color: "white"
  }),
  singleValue: provided => ({
    color: "white",
    paddingLeft: 4
  })
};

export const SelectWrap = styled.div`
  margin-bottom: 20px;
`;
