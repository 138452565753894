import styled from "styled-components";

import { LG, XS, XL } from "../../../utils/constants";

export const Wrapper = styled.div`
  color: grey;
  padding: 40px 165px 70px;

  @media (max-width: ${XL}) {
    padding: 40px 50px 70px;
    margin-top: 20px;
  }

  @media (max-width: ${LG}) {
    padding: 40px 30px 70px;
    margin-top: 20px;
  }

  @media (max-width: ${XS}) {
    &:last-child {
      padding: 40px 30px 20px;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 35px;

  @media (max-width: ${XS}) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  line-height: 24px;
  font-family: poppins;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${props => props.color};
  text-align: left;
  margin: 30px 0;

  @media (max-width: ${LG}) {
    font-size: 24px;
  }

  @media (max-width: ${XS}) {
    color: #353b48;
    font-family: Poppins;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 60px;
    margin-bottom: 10px;
  }
`;

export const Card = styled.div`
  a {
    text-decoration: none;
  }
  @media (max-width: ${XS}) {
    button {
      margin: 0 auto;
      width: 200px;
      display: block;
    }
  }
`;

export const MainImage = styled.div`
  border-radius: 20px;
  height: 27.77777777777778vw;
  background: url(${props => props.bg}) no-repeat center center;
  background-size: cover;

  @media (max-width: ${XS}) {
    height: 323px;
  }
`;

export const Icon = styled.img`
  width: 28px;
  height: 28px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 20px;
`;

export const SpecsUl = styled.ul`
  padding-left: 0;
  list-style: none;
  padding-left: 5px;
  padding-top: 15px;
  padding-bottom: 30px;
  min-height: 220px;

  @media (max-width: ${XL}) {
    min-height: 240px;
  }

  @media (max-width: ${LG}) {
    min-height: 280px;
  }

  @media (max-width: ${XS}) {
    min-height: auto;
  }
`;

export const SpecsLi = styled.li`
  color: ${props => props.theme.skyBlue};
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 25px 0 0;
  display: flex;
  align-items: center;

  @media (max-width: ${LG}) {
    align-items: end;
  }
`;

export const DescItem = styled.p`
  vertical-align: middle;
  display: inline-block;
  max-width: 286px;
`;
