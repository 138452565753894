import React, { useContext, Suspense, Fragment } from "react";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import {
  Wrapper,
  Card,
  Title,
  RoundedImage,
  CardContainer,
  MobileCard,
  MobileImage,
  TextWrap,
  MobileCardsWrap
} from "./style";

/* const SecondTitle = lazy(() =>
  import("../../../components/commons/secondTitle")
);
const Button = lazy(() => import("../../../components/commons/buttonGeneric")); */

import SecondTitle from "../../../components/commons/secondTitle"
import Button from "../../../components/commons/buttonGeneric"

export default props => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  const theme = useContext(ThemeContext);

  return (
    <Suspense fallback={<div></div>}>
      {props.hotelsInfo ? (
        <Fragment>
          <Wrapper>
            <SecondTitle
              title={"Our Cayman Islands properties"}
              maxWidth={false}
              textAlign={"left"}
              color={theme.blackColor()}
            />

            {!isMobile ? (
              <CardContainer>
                {props.hotelsInfo.map((hotel, i) => (
                  <Link to={"/stay/" + hotel.slug} key={i}>
                    <Card>
                      <RoundedImage bg={hotel.acf.hotel_card_image} />
                      <Title color={theme.blackColor()}>
                        {hotel.title.rendered}
                      </Title>
                      <Button
                        to={"/stay/" + hotel.slug}
                        transition={theme.defaultTransition()}
                      >
                        View More
                      </Button>
                    </Card>
                  </Link>
                ))}
              </CardContainer>
            ) : (
              <MobileCardsWrap>
                {props.hotelsInfo.map((hotel, x) => (
                  <MobileCard key={x}>
                    <Link to={"/stay/" + hotel.slug}>
                      <MobileImage bg={hotel.acf.hotel_card_image} />
                      <TextWrap>
                        <h3>{hotel.title.rendered}</h3>
                      </TextWrap>
                    </Link>
                  </MobileCard>
                ))}
              </MobileCardsWrap>
            )}
          </Wrapper>
        </Fragment>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
