import React, { useState, useEffect, Suspense, lazy } from "react";
import { OuterWrapper, Wrapper, LogoWrapper } from "./style";

import { Logo } from "../../components";

import Nav from "./nav"
import Menu from "./menu";
import Hamburger from "./hamburger";

/* const Nav = lazy(() => import("./nav"));
const Menu = lazy(() => import("./menu"));
const Hamburger = lazy(() => import("./hamburger")); */

export default ({ scrollYPos, triggerPoint = 75, location }) => {
  const [prev, setPrev] = useState(null);
  const [isHidden, setIsHidden] = useState(false);
  const [showbg, setShowBg] = useState(false);

  const hideBar = () => {
    scrollYPos > prev
      ? !isHidden && setIsHidden(true)
      : isHidden && setIsHidden(false);
    scrollYPos > triggerPoint ? setShowBg(true) : setShowBg(false);
    scrollYPos > triggerPoint ? setPrev(scrollYPos) : setPrev(triggerPoint);
  };

  const showBackground = () => {
    if (scrollYPos > 40) {
      setShowBg(true);
    } else {
      setShowBg(false);
    }
  };

  useEffect(() => {
    hideBar();
    showBackground();
  }, [scrollYPos, showbg]);

  return (
    <Suspense fallback={<div></div>}>
      <OuterWrapper>
        <Wrapper showbg={showbg} isHidden={isHidden}>
          <LogoWrapper
            aria-label="Home"
            name="Home"
            exact
            to="/"
            activeClassName="active"
          >
            <Logo width="100px" showbg={showbg} />
          </LogoWrapper>
          <Hamburger showbg={showbg}  />
          <Menu />
          <Nav isDesk={true} location={location} isHidden={isHidden} showbg={showbg} />
        </Wrapper>
      </OuterWrapper>
    </Suspense>
  );
};
