import styled from "styled-components";

import { LG } from "../../../utils/constants";

export const Wrapper = styled.div``;

export const FinePrint = styled.div`
  width: 100%;
  margin-top: 100px;
  padding: 0 150px;

  > div > div {
    border-radius: 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  @media (max-width: ${LG}) {
    padding: 0 20px;
    margin-top: -85px;
    margin-bottom: 200px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 35px;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

export const FinePrintBox = styled.div`
  max-width: auto;
  border-radius: 16px !important;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  padding: 40px 40px 40px 45px;

  @media(max-width:${LG}){
    padding:40px 20px;
  }

  h4 {
    color: ${props => props.theme.darkBlue};
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
  }

  h5 {
    a {
      color: ${props => props.theme.skyBlue};
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      margin-top: 10px;
      margin-bottom: 15px;
      text-decoration: none;
      display: block;
    }
  }

  ul {
    list-style: none;
    li {
      margin-bottom: 10px;
      max-width: 100%;
      color: ${props => props.theme.darkBlue};
      span {
        color: ${props => props.theme.darkBlue};
        font-family: Roboto;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
  }
`;

export const PolicyWrap = styled.div`
  h4 {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
  }
  h5 {
    color: ${props => props.theme.darkBlue};
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 17px
  }
  ul {
    list-style: disc;
    padding-left: 25px;
    margin-bottom:30px;
    li {
      margin-bottom: 10px;
      max-width: 100%;
      font-size: 14px;
    }
  }
  p {
    color: ${props => props.theme.darkBlue};
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 300;
    margin-bottom: 5px;

    @media(max-width:${LG}){
      font-size:13px;
      padding-bottom:20px;
    }
    
  }
`;
