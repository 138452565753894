import styled from "styled-components";
import { Link } from "react-router-dom";
import { XL, LG, XS } from "../../../utils/constants";

const activeLink = (currentIndex, Index) => {
  if (currentIndex === Index) {
    return {
      color: props => props.color
    };
  } else {
    return {
      color: "#d0dce8"
    };
  }
};

export const Wrapper = styled.div`
  padding: ${props =>
    props.customPadding ? props.customPadding : "80px 155px 70px"};

  @media (max-width: ${XL}) {
    padding: 80px 20px 70px;
  }
  @media (max-width: ${LG}) {
    width: 100%;
    overflow-x: scroll;
    padding: ${props => props.customMobilePadding ? props.customMobilePadding : '80px 20px 70px'};
  }
  @media (max-width: ${XS}) {
    padding: 10px 20px 30px;
  }
`;

export const List = styled.ul`
  display: flex;
  list-style: none;
  
  @media (max-width: ${XS}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 25px;
  }
`;

export const Item = styled.li`
  color: ${props => props.darkBlue};
  font-family: Poppins;
  font-size: ${props => (props.dineNavigationSize ? "20px" : "24px")};
  font-weight: bold;
  line-height: 24px;
  margin-right: ${props => (props.dineNavigationSize ? "10px" : "25px")};

  a {
    color: ${props => activeLink(props.activeRoute, props.currentRoute).color};
    transition: ${props => props.transition};
    padding: 10px;
    border-radius: 8px;

    &:hover {
      color: white;
      background-color: #d0dce8;
    }
  }

  @media (max-width: ${LG}) {
    font-size: ${props => (props.dineNavigationSize ? "26px" : "26px")};
  }
  @media (max-width: ${XS}) {
    margin-right: 0;
    a {
      padding: 10px 0;
    }
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #d0dce8;

  @media (max-width: ${LG}) {
    display: block;
  }
`;
