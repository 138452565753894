import styled from "styled-components";
import { PopAnimation, GrowAnimation } from "../../utils/KeyFrames";
import { LG } from "../../utils/constants";


export const Wrapper = styled.div`
  position: relative;
  margin-top: 10px;
  z-index:700;
`;

export const ShareButton = styled.div`
  height: 42px;
  width: 42px;
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: 0 10px 20px 0 rgba(31, 48, 64, 0.1);
  transition: ${props => props.theme.defaultTransition()};

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(31, 48, 64, 0.2);
  }
`;

export const Menu = styled.div`
  width: 170px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);

  background-color: white;
  margin-left: 0.5px;
  position: absolute;
  overflow: hidden;
  transition: ${props => props.theme.defaultTransition("all", 0.2)};
  top: -190px;
  z-index: 4;

  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? "1" : "0")};

  button {
    padding-top: 4px !important;
    padding-bottom: 10px !important;

    margin-right: 12px;
    outline: 0 !important;
    opacity: 1;
    transition: ${props => props.theme.defaultTransition("all", 0.2)};

    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 30px;
    background-color: white;
    display: flex;
    align-items: center;
    padding-left: 30px !important;

    &:hover {
      background-color: #006dbc0d !important;
    }

    svg {
      margin: 5px 0;
      width: 30px;
    }

    img {
      width: 30px;
    }
  }

  @media (max-width: ${LG}) {
    top: -190px;
  }
`;

export const ButtonAnimated = styled.div`
  animation: ${PopAnimation} 0.4s ease-out ${props => props.delay}s forwards;
  span {
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    color: ${props => props.theme.darkBlue};
    font-weight: 400;
    margin-left: 10px;
  }

  &:first-child {
    button {
      padding-top: 20px !important;
    }
  }
  &:last-child {
    button {
      padding-bottom: 20px !important;
    }
  }
`;

export const Caret = styled.div`
  background-color: white;
  display: block;
  position: absolute;
  bottom: 62px;
  transform: rotate(180deg);
  left: 12px;
  border-radius: 4px;
  clip-path: polygon(48% 60%, 0% 100%, 100% 100%);
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? "1" : "0")};
  z-index: 4;

  height: 20px;
  width: 20px;
  background-color: inherit;
  border: inherit;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  border-radius: 0 0 0 0.25em;

  transform: rotate(-45deg);
  border-radius: 0 0 0 0.25em;
  background-color: white;

  transition: ${props => props.theme.defaultTransition()};

  @media (max-width: ${LG}) {
    bottom: 85px;
    position: relative;
  }
`;

export const CopyBtn = styled.button`
  cursor: pointer;
  border: 0;
`;

export const CopyMessage = styled.div`
  color: ${props => props.theme.skyBlue};
  visibility: ${props => (props.copied ? "visible" : "hidden")};
  opacity: ${props => (props.copied ? "1" : "0")};
  font-family: Poppins;
  position: absolute;
  animation: ${GrowAnimation} 0.4s ease-out 0s forwards;

  @media (max-width: ${LG}) {
    left: 60px;
    bottom: 43px;
  }
`;
