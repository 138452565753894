import React, { Suspense, useEffect, useState } from "react";
import { Wrapper, Overlay, Title, Text, TitleWrap, BtnLink } from "./style";
import axios from "axios";

export default props => {
  const [data, setData] = useState();

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/23");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data.acf);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={null}>
      {data ? (
        <Wrapper bg={data.blog_hero_image}>
          <Overlay></Overlay>
          <TitleWrap>
            <Title>404 Error - Oops, something's gone wrong!</Title>
            <Text>We can't seem to find the page you're looking for.</Text>
            <BtnLink typebtn="main" to={"/"}>
              Go to homepage
            </BtnLink>
          </TitleWrap>
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
