import styled from "styled-components";

import { HIGHER_RESOLUTIONS, XS } from "../../../utils/constants";
import { StyledH3 } from "../secondTitle/style";
import { RoundedImage } from "../borderImage/style";
import { StyledP } from "../Paragraph/style";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 123px;
  margin-right: 80px;
  color: black !important;
  
  p, a, h1, h2, h3, h4, h5 {
    color: #1f3040 !important;
  }

  @media (max-width: ${XS}) {
    flex-direction: column-reverse;
    margin: 0;
    padding: 0;
  }
  
  /* IE10+ specific styles */
  @media screen and (max-width: 767px) and (-ms-high-contrast: active), screen and (max-width: 767px) and (-ms-high-contrast: none) {  
     margin: 40px 0 0;
  }
`;

export const Text = styled(StyledP)`
  max-width: 620px;
  color: ${props => props.theme.darkBlue};
  margin-bottom: 20px;
  
  h3 {
    width: 100%;
    color: rgb(31, 48, 64);
    font-family: Poppins;
    font-weight: bold;
    text-align: left;
    
    font-size: 37px;
    line-height: 42px;
    font-weight: 700;
  }
  
  ol {
    margin-left: 20px;
  }

  li {
    padding-left: 10px;  
  }
  li::marker {
    color: #016EBC;
    font-weight: 700;
  }
`;
