import styled from "styled-components";

import { StyledH3 } from "../../../components/commons/secondTitle/style";
import { StyledP } from "../../../components/commons/Paragraph/style";
import Subtitle from "../../../components/commons/subtitle/";
import { RoundedImage } from "../../../components/commons/borderImage/style";
import { LG, HIGHER_RESOLUTIONS, XL } from "../../../utils/constants";

export const Wrapper = styled.div`
  max-width: 1111px;
  margin: 0 auto;

  h3 {
    margin-bottom: -30px;
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    margin: 0 170px;
  }
`;

export const FirstGrid = styled.div`
  > div {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    > div {
      &:first-child {
        padding: 0 30px 0 0;
        height: 800px;
        width: 90%;
      }
      article {
        height: 654px !important;
      }
    }

    p {
  
      color: ${props => props.theme.darkBlue};
    }

    @media (max-width: ${LG}) {
      width: 100%;
      display: block;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      padding: 0;

      > div {
        &:first-child {
          padding: 10px 0 0 0;
          height: auto;
          width: 100%;
        }
        article {
          height: 323px !important;
        }
      }
    }
  }

  @media (max-width: ${XL}) {
    padding: 0 30px;
  }
`;

export const HeroWrap = styled.div`
  h3 {
    margin-bottom: -20px;
  }
  h4 {
    margin-bottom: 40px;
  }

  @media (max-width: ${LG}) {
    h3 {
      margin-top: 30px;
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 0;
    }
  }
`;

export const Title = styled(StyledH3)`
  font-family: Poppins;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 96px;
  color: ${props => props.theme.darkBlue};
`;

export const Text = styled(StyledP)``;

export const MainSubtitle = styled(Subtitle)``;

export const SecondGrid = styled.div`
  > div {
    width: 45%;
    display: inline-block;
    vertical-align: top;

    &:first-child {
      width: 55%;
      @media (min-width: ${HIGHER_RESOLUTIONS}) {
        padding-right: 50px;
      }
    }

    > div {
      &:first-child {
        padding: 0 0 0 30px;
        height: 800px;
        width: 100%;
      }
      article {
        height: 654px !important;
      }
    }

    @media (max-width: ${LG}) {
      width: 100%;
      display: block;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      padding: 0;

      > div {
        &:first-child {
          padding: 10px 0 0 0;
          height: auto;
          width: 100%;
        }
        article {
          height: 323px !important;
        }
      }
    }
  }

  p {
    margin-top: 10px;
    max-width: 570px;

    padding-bottom: 20px;
    color: ${props => props.theme.darkBlue};

    @media (min-width: ${HIGHER_RESOLUTIONS}) {
      max-width: unset;
    }
  }

  h4 {
    margin-right: 30px;
  }

  @media (max-width: ${XL}) {
    padding: 0 30px;
  }
`;

export const SecondBottomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: -80px;
  padding-bottom: 100px;

  @media (max-width: ${XL}) {
    padding: 0 30px 60px;
  }
`;

export const BookingCTA = styled.div`
  height: 269px;
  width: 470px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 60px;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    width: 80%;
  }

  span {
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    color: ${props => props.theme.darkBlue};
    font-weight: 300;
    a {
      text-decoration: none;
      color: ${props => props.theme.darkBlue};
    }

    b {
      font-family: Poppins;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      display: block;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  p {
    color: ${props => props.theme.skyBlue};
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;

    max-width: 275px;
  }

  @media (max-width: ${LG}) {
    max-width: calc(100% - 30px);
    margin: 20px auto 40px;
    height: 170px;
    padding: 0 40px;
    span {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 32px;
      line-height: 1.1;

      b {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
        margin-top: 0;
      }
    }
    p {
      font-size: 16px;
      font-weight: bold;

      letter-spacing: 0;
      line-height: 20px;
      padding: initial !important;
      max-width: 200px;
    }
  }
`;

export const BottomText = styled.div`
  p {
    color: ${props => props.theme.darkBlue};
    &:first-of-type {
      margin-top: 30px;
    }
  }
`;

export const MobileWrap = styled.div`
  p,
  h3,
  h4 {
    padding: 0 20px;
    padding-bottom: 20px;

    p {
      padding-left: 0;
    }
  }

  p {
    padding-bottom: 20px;
  }
`;

export const SliderMobileWrap = styled.div`
  margin-bottom: 30px;
`;

export const PictureXs = styled(RoundedImage)``;

export const PictureWrap = styled.div`
  width: 90%;
  margin: 0 auto 30px;
`;

export const VideoWrap = styled.div`
  > div {
    &:first-child {
      max-width: unset;
      width: 100%;
    }
  }
`;
