import React, { Suspense, lazy, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";

import {
  Wrapper,
  Grid,
  Title,
  Card,
  MainImage,
  SpecsUl,
  SpecsLi,
  Icon,
  DescItem
} from "./style";

import LondonerDesktop from "../../../assets/new-londoner.jpg";
import GrandResortDesktop from "../../../assets/stay-images/grand-resort-desktop.jpg";
import TortugaClubDesktop from "../../../assets/stay-images/tortuga-club-desktop.jpg";

/* const Button = lazy(() => import("../../../components/commons/buttonGeneric")); */

import Button from "../../../components/commons/buttonGeneric"

/*const hotels = [
  {
    title: "The Londoner.",
    mainImage: LondonerDesktop,
    route: "stay/the-londoner",
    specs: [
      {
        icon: "https://placehold.it/300x300",
        text: "2 Bedroom Oceanfront Deluxe Suites"
      },
      {
        icon: "https://placehold.it/300x300",
        text: "3 Bedroom Penthouse Suites"
      },
      {
        icon: "https://placehold.it/300x300",
        text: "Children’s pool"
      }
    ]
  },
  {
    title: "Grand Resort.",
    mainImage: GrandResortDesktop,
    route: "stay/grand-resort",
    specs: [
      {
        icon: "https://placehold.it/300x300",
        text:
          "1 & 2 bedroom oceanfront deluxe suites with floor plans for as many as 8 people"
      },
      {
        icon: "https://placehold.it/300x300",
        text: "Paranoramic ocean views & balconies overlooking the beach"
      },
      {
        icon: "https://placehold.it/300x300",
        text: "Fully appointed kitchens"
      }
    ]
  },
  {
    title: "Tortuga Club.",
    mainImage: TortugaClubDesktop,
    route: "stay/tortuga-club",
    specs: [
      {
        icon: "https://placehold.it/300x300",
        text: "Poolside villas"
      },
      {
        icon: "https://placehold.it/300x300",

        text:
          "Fully appointed kitchens, with stainless steel appliances and granite countertops"
      },
      {
        icon: "https://placehold.it/300x300",
        text: "Ocean front suites"
      }
    ]
  },
  {
    title: "Virtual Tours",
    mainImage: TortugaClubDesktop,
    route: "stay/virtual-tours",
    specs: [
      {
        icon: "https://placehold.it/300x300",
        text: "Poolside villas"
      },
      {
        icon: "https://placehold.it/300x300",

        text:
          "Fully appointed kitchens, with stainless steel appliances and granite countertops"
      },
      {
        icon: "https://placehold.it/300x300",
        text: "Ocean front suites"
      }
    ]
  }
];*/

export default props => {
  const theme = useContext(ThemeContext);

  return (
    <Suspense fallback={null}>
      {props.hotelsInfo && (
        <Wrapper>
          <Grid>
            {props.hotelsInfo.map((hotel, i) => (
              <Card key={i}>
                <Title color={theme.blackColor()}>{hotel.title.rendered}</Title>
                <MainImage bg={hotel.acf.hotel_card_image} />

                {(() => {
                  if (hotel.acf.hotel_card_feature_1_icon) {
                    return (
                      <SpecsUl>
                        <SpecsLi>
                          <Icon src={hotel.acf.hotel_card_feature_1_icon} />
                          <DescItem>{hotel.acf.hotel_card_feature_1_title}</DescItem>
                        </SpecsLi>
                        <SpecsLi>
                          <Icon src={hotel.acf.hotel_card_feature_2_icon} />
                          <DescItem>{hotel.acf.hotel_card_feature_2_title}</DescItem>
                        </SpecsLi>
                        <SpecsLi>
                          <Icon src={hotel.acf.hotel_card_feature_3_icon} />
                          <DescItem>{hotel.acf.hotel_card_feature_3_title}</DescItem>
                        </SpecsLi>
                      </SpecsUl>
                    )
                  } else {
                    return (
                      <p><br/></p>
                    )
                  }
                })()}

                <Link to={"/stay/" + hotel.slug}>
                  <Button
                    title={"View detail"}
                    transition={theme.defaultTransition()}
                  ></Button>
                </Link>
              </Card>
            ))}
          </Grid>
        </Wrapper>
      )}
    </Suspense>
  );
};
