import React from "react";

import { Subtitle } from "./style";

export default props => {
  return <Subtitle color={props.color} dangerouslySetInnerHTML={{ __html: props.title }} mobileSize={props.mobileSize} 
  mobileLineHeight={props.mobileLineHeight}
  
  />;
};
