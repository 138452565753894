import styled from 'styled-components'

export const Wrapper = styled.div`
	width: 100%;
	min-height:100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	background: transparent;
  	color: ${props => props.theme.dark()};
	overflow-x: hidden;

	font-family:roboto;

`


