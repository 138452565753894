import React, { Fragment } from "react";
import { createBrowserHistory } from "history";

import { Wrapper, List, Item, StyledLink } from "./style";

export default props => {
  const history = createBrowserHistory();

  

  return (
    <Fragment>
    {/*   {!isMobile && ( */}
        <Wrapper customPadding={props.customPadding} customMobilePadding={props.customMobilePadding} >
          <List>
            {props.list.map((item, i) => (
              <Item
                key={i}
                index={i}
                activeRoute={item.routeName}
                currentRoute={props.finalRoute}
                color={props.color}
                transition={props.transition}
                dineNavigationSize={props.dineNavigationSize}
              >
                <StyledLink to={item.route}>
                  {item.name.slice(0, -1)}
                </StyledLink>
              </Item>
            ))}
          </List>
        </Wrapper>
  {/*     )} */}
    </Fragment>
  );
};
