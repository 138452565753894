import React, {useContext, Suspense, lazy, Fragment, useEffect, useState} from "react";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import {
  Wrapper,
  MobileCardsWrap,
  CardContainer,
} from "./style";

/* const SecondTitle = lazy(() =>
  import("../../../components/commons/secondTitle")
);
const Button = lazy(() => import("../../../components/commons/buttonGeneric")); */

import SecondTitle from "../../../components/commons/secondTitle"
import Button from "../../../components/commons/buttonGeneric"

export default props => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  const theme = useContext(ThemeContext);

  const [popup, setPopup] = useState({});
  function openTour(key) {
    if (!popup.show && key >= 0 && props.data && props.data.length) {
      setPopup({
        title: key,
        iframe: props.data[key].tour_url,
        // iframe: 'https://ermert.es',
        show: true
      })
    } else {
      /*setTimeout(() => {
        setPopup({})
      }, 1000);*/

      setPopup({})
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {props.data ? (
        <Fragment>
          <Wrapper>

            {popup && popup.show ? (
              <>
                <div className="vt-popup-overlay">
                </div>
                <div className="vt-popup">
                  <div className="vt-close" onClick={() => openTour(-1)}>&times;</div>
                  <iframe src={popup.iframe} width="100%" height="100%"></iframe>
                </div>
              </>
            ) : (
              <></>
            )}


              <CardContainer>
                {props.data.map((tour, i) => (

                  <div key={i} className={tour.wide ? 'cardWide' : 'cardNormal'}>

                    <div className='vt-tour'>

                      <img src={tour.thumbnail} />

                      <div className='vt-card-overlay'>
                        <div>
                          {tour.name}
                        </div>
                        <button
                          onClick={() => openTour(i)}
                        >
                          Explore
                        </button>
                      </div>


                    </div>

                  </div>
                ))}
              </CardContainer>

          </Wrapper>
        </Fragment>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
