import styled from "styled-components";

import { XL, LG, XS } from "../../../utils/constants";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1.45fr 1fr;
  padding-bottom: 130px;
  padding-left: 165px;

  @media (max-width: ${XL}) {
    padding-left: 30px;
  }
`;

export const Grid = styled.div``;

export const TextContainer = styled.div`
  padding-right: 40px;
`;

export const HotelTitle = styled.h2`
  color: ${props => props.color};
  font-family: Poppins;
  font-size: 80px;
  font-weight: bold;
  line-height: 96px;
  margin-top: 10px;
  margin-bottom: 40px;
  
`;

export const HotelSubtitle = styled.h3`
  color: ${props => props.theme.darkBlue};
  font-family: Poppins;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: -30px;
  padding-right:30px;
`;

export const SpecsContainer = styled.div`
  width: fit-content;
  border-radius: 16px;
  box-shadow:0 20px 80px 0 rgba(0,0,0,0.1);
  box-shadow: ${props =>
    props.tortuga ? "none" : "0 20px 80px 0 rgba(0, 0, 0, 0.1)"};
  padding: 30px 40px 40px;
  padding: ${props =>
    props.tortuga ? "30px 10px 40px" : "30px 60px 40px 20px"};
  display: flex;
  position: absolute;
  z-index: 200;
  background: white;

  > div {

    &:first-child{
      padding-right: ${props => (props.tortuga ? "80px" : "initial")};
    }
   
  }

  @media (max-width: ${XL}) {
    position: relative;
    display: grid;
    margin-right: -60px;
    margin-bottom: -30px;
    grid-gap: 40px;
  }

  @media (max-width: ${LG}) {
    margin-bottom: 40px;
    width: ${props => (props.tortuga ? "100%" : "95%")};
    margin: 0 auto;
    position: relative;
    top: ${props => (props.tortuga ? "0" : "-30px")};
    margin-bottom: ${props => (props.tortuga ? "0" : "30px")};
    padding-left: ${props => (props.tortuga ? "10px" : "20px")};
    background: ${props => (props.tortuga ? "transparent" : "white")};
    grid-gap: 0;

    > div {
      box-shadow: ${props =>
        props.tortuga ? "0 20px 80px 0 rgba(0, 0, 0, 0.1)" : "none"};
      padding: ${props => (props.tortuga ? "20px" : "initial")};
      margin-bottom: ${props => (props.tortuga ? "30px" : "initial")};
      border-radius: 16px;
    }
  }
`;

export const SpecsTitle = styled.h4`
  color: ${props => props.theme.skyBlue};
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;

  margin-bottom: 10px;

  @media (max-width: ${XS}) {
    font-family: Poppins;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 25px;
  }
`;

export const SpecSubtitle = styled.div`
  color: ${props => props.theme.skyBlue};
  font-family: Poppins;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 25px;

  @media (min-width: ${LG}) {
    max-width: 500px;
  }
`;

export const Icon = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 20px;
`;

export const SpecsUl = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 240px 220px;
  grid-gap: 20px;
  max-width: 500px;

  @media (max-width: ${XS}) {
    grid-template-columns: 1fr;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  color: ${props => props.theme.skyBlue};
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
`;

export const AsideImg = styled.div`
  border-radius: 20px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: 41.66666666666667vw;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  @media (max-width: ${LG}) {
    width: 100%;
    height: 323px;
    border-radius: 0;
  }
`;

export const MobileWrapper = styled.div`
  background: linear-gradient(
    0deg,
    rgba(233, 233, 233, 1) 38%,
    rgba(255, 255, 255, 1) 100%
  );

  background: ${props =>
    props.tortuga
      ? "white"
      : "linear-gradient(0deg, rgba(233, 233, 233, 1) 38%, rgba(255, 255, 255, 1) 100% )"};
`;

export const LocationWrap = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0;
`;

export const LocationMark = styled.div`
  position: absolute;
  right: 30px;
  top: -15px;
  display: flex;

  width: 150px;
  background-color: white;
  padding: 2px 10px;
  border-radius: 8px;

  span {
    color: ${props => props.theme.skyBlue};
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const MobileTextContainer = styled.div`
  background-color: white;
  padding: 30px 15px;

  p{
    padding-bottom:15px;
  }
`;

export const TitleMobile = styled.h3`
  color: ${props => props.theme.darkBlue};
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
  margin-bottom: 15px;
`;

export const TextMobile = styled.div`
  color: ${props => props.theme.darkBlue};
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  background-color: white;
`;

export const SpecsDefaultWrap = styled.div`
    margin-top: -51px;
    background-color: white;

`

