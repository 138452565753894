import React, { Suspense, useEffect, useState, Fragment } from "react";
import moment from "moment";
import {
  Wrapper,
  Title,
  MainTable,
  TableHeader,
  TableList,
  Item,
  UsdCTA,
  ActivityField,
  Disclaimer,
  MobileTitle
} from "./style";

import { useMediaQuery } from "react-responsive";
import TimeIcon from "../../../assets/daily-icons/time.svg";
import WalkIcon from "../../../assets/daily-icons/walk.svg";
import MapIcon from "../../../assets/daily-icons/map.svg";
import { createBrowserHistory } from "history";
import axios from "axios";
import LoaderSpin from "../../../components/LoaderSpin/LoaderSpin";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import {setHeaders} from "../../../utils/setHeaders"

/* const Tabs = lazy(() => import("./tabs")); */
import Tabs from "./tabs"


const DailyActivities = props => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const history = createBrowserHistory();
  const [selected, setSelected] = useState(2);
  const [apiData, setApiData] = useState(null);
  const [apiActivities, setApiActivities] = useState();
  const [apiCategories, setApiCategories] = useState();
  const [sorted, setSorted] = useState([]);
  const [nuevoMap, setNuevoMap] = useState([]);

  const handleTabs = day => {
    switch (day) {
      case "Sunday":
        setSelected(2);
        break;
      case "Monday":
        setSelected(3);
        break;
      case "Tuesday":
        setSelected(4);
        break;
      case "Wednesday":
        setSelected(5);
        break;
      case "Thursday":
        setSelected(6);
        break;
      case "Friday":
        setSelected(7);
        break;
      case "Saturday":
        setSelected(8);
        break;
      default:
    }
  };

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/659");
    fetchActivities(
      process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/activities_list/"
    );
    fetchCategories(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/categories");
  }, [selected]);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setApiData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchActivities(url) {
    try {
      const response = await axios.get(url);
      setApiActivities(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchCategories(url) {
    try {
      const response = await axios.get(url);
      setApiCategories(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    let sortedItems = [];

    if (apiActivities) {
      apiActivities.forEach((item, i) => {
        if (item.categories[0] === selected) {
          sortedItems.push(item);
        }
      });
    }

    setSorted(sortedItems);

    let nuevoMapTemp = sortedItems.sort(
      (a, b) =>
        parseInt(moment(a.acf.hour_of_activity, ["h:mm A"]).format("HHmm")) -
        parseInt(moment(b.acf.hour_of_activity, ["h:mm A"]).format("HHmm"))
    );

    setNuevoMap(nuevoMapTemp);
  }, [selected, apiActivities]);

  return (
    <Suspense fallback={<LoaderSpin>dailyActivities</LoaderSpin>}>
      {apiData && apiActivities && apiCategories && sorted ? (
        <AnimatedWrap>
          <Wrapper>
            {!isMobile ? (
              <Title
                mobileLineHeight={"24px"}
                mobileSize={"16px"}
                title={apiData.title}
              ></Title>
            ) : (
              <MobileTitle
                dangerouslySetInnerHTML={{ __html: apiData.title }}
              ></MobileTitle>
            )}

            <Tabs days={days} selected={selected} handleTabs={handleTabs} />
            <MainTable>
              <TableHeader>
                <div>
                  <img src={TimeIcon} alt="Time" />
                  <span>Time</span>
                </div>
                <div>
                  <img src={WalkIcon} alt="Activity" />
                  <span>Activity</span>
                </div>
                <div>
                  <img src={MapIcon} alt="Place" />
                  <span>Meeting Point</span>
                </div>
              </TableHeader>

              <TableList>
                {nuevoMap.map((item, i) => {
                  if (item.categories[0] === selected) {
                    return (
                      <AnimatedWrap key={i}>
                        <Item>
                          {item.acf.all_day === "Yes" ? (
                            <span>All day</span>
                          ) : (
                            <span>
                              {item.acf.hour_of_activity}

                              {item.acf.to_time && (
                                <Fragment> - {item.acf.to_time}</Fragment>
                              )}
                            </span>
                          )}

                          <ActivityField>
                            <h3>
                              {item.title.rendered.replace("&#038;", "&")}
                            </h3>
                            {item.acf.activity_price !== null && (
                              <UsdCTA>USD${item.acf.activity_price}</UsdCTA>
                            )}
                          </ActivityField>
                          <h4>{item.acf.meeting_point}</h4>
                        </Item>
                      </AnimatedWrap>
                    );
                  }
                })}
              </TableList>
            </MainTable>

            <Disclaimer>
              * This activity schedule is subject to change without notice.
            </Disclaimer>
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};

export default DailyActivities;
