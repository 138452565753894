export const setHeaders = (data, url) => {
  // console.log('setHeaders', url, data);
  if (data.yoast_title) {
    document.title = data.yoast_title
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");
  }

  if (data.yoast_meta) {
    data.yoast_meta.forEach(value => {
      let meta;
      let found = true;
      if (value.property) {
        meta = document.querySelector(`meta[property="${value.property}"]`);
        if (!meta) {
          meta = document.createElement("meta");
          found = false;
        }
        meta.setAttribute("property", value.property);
        if (value.property === "og:url") {
          meta.setAttribute("content", process.env.REACT_APP_FRONT_URL + `${url}`);
        } else {
          meta.setAttribute(
            "content",
            value.content
              .replace(/&amp;/g, "&")
              .replace(/&lt;/g, "<")
              .replace(/&gt;/g, ">")
              .replace(/&quot;/g, '"')
              .replace(/&#039;/g, "'")
              .replace(/&#039;/g, "'")
          );
        }
      } else {
        meta = document.querySelector(`meta[name="${value.name}"]`);
        if (!meta) {
          meta = document.createElement("meta");
          found = false;
        }
        meta.setAttribute("name", value.name);
        meta.setAttribute(
          "content",
          value.content
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, '"')
            .replace(/&#039;/g, "'")
        );
      }
      if (!found) document.head.appendChild(meta);
    });
  }
};
