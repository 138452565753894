import React from "react";

import { Wrapper, StyledH3 } from "./style";

export default (props, { className }) => {
  return (
    <Wrapper>
      <StyledH3
        className={className}
        maxWidth={props.maxWidth}
        textAlign={props.textAlign}
        color={props.color}
        lineHeight={props.lineHeight}
      >
        {props.title}
      </StyledH3>
    </Wrapper>
  );
};
