import styled from "styled-components";
import { HIGHER_RESOLUTIONS, XL, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  padding-top: 130px;
  background: url(${props => props.bg}) no-repeat;
  background-size: cover;
  height: auto;
  overflow: hidden;

  //@media  (min-height: 900px) {
  //  height: 1200px;
  //}

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    height:auto;
  }
`;

export const HubspotContactFormWrapper = styled.div`
    background: white;
    padding: 1rem 1rem 0;
    border-radius: 1rem;
    margin-top: 50px;
    margin-right: 20px;
`;

export const Maps = styled.div`
  width: 100%;


  @media (max-width: ${LG}) {
    height: 410px;
    grid-row: 2/3;
  }
`;
export const RightSection = styled.div`
  padding: 30px 0 60px 100px;
  max-width: 540px;

  @media (max-width: ${XL}) {
    padding: 30px 0 60px 40px;
  }

  @media (max-width: ${LG}) {
    padding: 30px 0 60px 20px;
    margin-top: -30px;
  }
`;

export const Title = styled.h1`
  font-family: Poppins;
  font-size: 120px;
  font-weight: bold;
  line-height: 100px;
  color: white;

  @media (max-width: ${LG}) {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
  }
`;

export const Text = styled.p`
  color: white;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
`;

export const ContactEmail = styled.a`
  color: white;
  font-family: Poppins;
  font-size: 24px;
  line-height: 16px;
  text-decoration: none;
  display: block;
  margin-bottom: 30px;
  margin-top:5px;
`;

export const AdressWrap = styled.div`
  max-width: 300px;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
  }
`;


export const Reservations = styled.div`
  h5 {
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 16px;
    color: white;
    font-weight: 300;
    margin-bottom:20px;
    margin-top:30px;
  }

  h3 {
    color: white;
    font-family: Poppins;
    font-size: 25px;
    line-height: 35px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: ${LG}) {
    max-width: 310px;
    margin-bottom:20px;


    h3 {
      font-size: 22px;
      line-height: 36px;
      margin-top: 10px;
    }
  }
`;

export const Tel = styled.a`

text-decoration:none;

 /*  h3{
    max-width:200px;
  } */

`