import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { XL, XS, LG } from "../../utils/constants";

export const OuterWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 900;
`;
export const Wrapper = styled.header`
  will-change: transform;
  background: ${props =>
    props.showbg === true ? "white" : "transparent"};
  padding: ${props => (props.showbg === false ? "0 165px 0" : "0 165px 0")};
  margin-top: ${props => (props.showbg === false && props.isHidden ? "88px" : "initial")};
  height: auto;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 0.3fr;
  grid-template-areas: "logo . button";
  align-items: center;
  justify-items: center;
  transition: all cubic-bezier(0.15, 0.82, 1, 1) 0.5s;
 /*  transition: ${props => props.theme.defaultTransition('all', 0.8)}; */
  transform: ${props =>
    props.isHidden ? "translateY(-108px)" : "translateY(0)"};

  z-index: 900;
  box-shadow:${props =>
    props.showbg === true ? '0 20px 80px 0 rgba(0, 0, 0, 0.1)' : "transparent"};

  height:96px;

  @media (min-width: 1300px) {
    grid-template-columns: 0.2fr 1fr 1fr;
    grid-template-areas: "logo . nav";
  }

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr 1fr;
    padding: ${props => (props.showbg === false ? "0 40px 0" : "0 40px 0")};
  }

  
`;
export const LogoWrapper = styled(NavLink)`
  grid-area: logo;
  font-size: 2em;
  color: white;
  display: grid;
  align-items: center;
  justify-items: center;
  transition: ${props => props.theme.defaultTransition()};


  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${XL}) {
    position: relative;
    left: -8vw;
  }

  @media (max-width: ${LG}) {
    left: -35px;
    img{
      width:120px !important;
    }
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     float: left;
  }
  
`;
