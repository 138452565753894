import ShoppingHero from "../../../assets/hero-img-min/shopping-hero-desktop.jpg";
import ShoppingHeroXs from "../../../assets/hero-img-min/shopping-hero-desktop.jpg";
import Picture1 from "../../../assets/shopping/1-desktop.jpg";
import Picture1xs from "../../../assets/shopping/1-xs.jpg";
import Picture2 from "../../../assets/shopping/2-desktop.jpg";
import Picture2xs from "../../../assets/shopping/2-xs.jpg";
import Picture3 from "../../../assets/shopping/3-desktop.jpg";
import Picture3xs from "../../../assets/shopping/3-xs.jpg";
import Picture4 from "../../../assets/shopping/4-desktop.jpg";
import Picture4xs from "../../../assets/shopping/4-xs.jpg";

import SpaHero from "../../../assets/hero-img-min/spa-hero-desktop.jpg";
import SpaHeroXs from "../../../assets/hero-img-min/spa-hero-desktop.jpg";
import Spa1 from "../../../assets/spa/1.jpg";
import Spa1Xs from "../../../assets/spa/1-xs.jpg";

import LandHero from "../../../assets/hero-img-min/land-hero-desktop.jpg"
import LandHeroXs from "../../../assets/hero-img-min/land-hero-desktop.jpg"

import SeaHero from "../../../assets/hero-img-min/sea-hero-desktop.jpg"
import SeaHeroXs from "../../../assets/hero-img-min/sea-hero-desktop.jpg"

import DailyHero from "../../../assets/hero-img-min/daily-hero-desktop.jpg"
import DailyHeroXs from "../../../assets/hero-img-min/daily-hero-desktop.jpg"

const images = [
  ShoppingHero,
  ShoppingHeroXs,
  Picture1,
  Picture1xs,
  Picture2,
  Picture2xs,
  Picture3,
  Picture3xs,
  Picture4,
  Picture4xs,
  SpaHero,
  SpaHeroXs,
  Spa1,
  Spa1Xs,

  LandHero,
  LandHeroXs,
  SeaHero,
  SeaHeroXs,

  DailyHero,
  DailyHeroXs
];

export default images;
