import styled from "styled-components";
import { LG } from "../../../utils/constants";

export const Subtitle = styled.h4`
  color: ${props => props.theme.darkBlue};
  font-family: Poppins;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 300;

  @media (max-width: ${LG}) {
    font-size: ${props => (props.mobileSize ? props.mobileSize : "20px;")};
    line-height: ${props =>
      props.mobileLineHeight ? props.mobileLineHeight : "inherit"};
  }

  span {
    color: ${props => props.color};
  }
`;
