import styled from "styled-components";
import arrowLeft from "../../../assets/arrow-left.png";
import { HIGHER_RESOLUTIONS, XL, XS, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;

  .slick-slider {
    width: 100%;
    height: 100%;
  }

  .slick-list {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .slick-arrow {
    width: 45px;
    height: 45px;
    background-color: ${props => props.theme.darkBlue} !important;
    z-index: 800;
    border-radius: 8px;
    transition: ${props => props.theme.defaultTransition()};
    &:hover {
      background-color: #2a5074 !important;
      opacity: 1 !important;
    }
    @media (max-width: ${LG}) {
      display: none !important;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 0;
    width: 45px;
    height: 26px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url(${arrowLeft});
  }

  .slick-next {
    bottom: 9px;
    top: inherit;
    right: 11.2vw;
  }

  .slick-prev {
    top: inherit;
    bottom: 9px;
    right: 11.2vw;
    left: inherit;
    margin-right: 50px;
  }

  .slick-next:before {
    transform: rotate(180deg);
  }

  @media (max-width: ${XS}) {
    height: auto;

    .slick-slider {
      height: auto;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${props => props.bg}) no-repeat center center / cover;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${props => props.theme.imagaMask};
  }
`;

export const CounterWrap = styled.div`
  position: relative;
  text-align: right;
  padding-right: 15.5vw;
  padding-top: 23px;
  bottom: 100px;
  right: 5px;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    padding-right: 17.5vw;
  }

  @media (max-width: 1600px) {
    padding-right: 19vw;
  }

  @media (max-width: ${XL}) {
    padding-right: 22vw;
  }

  @media (max-width: ${LG}) {
    display: none;
  }
`;

export const Numbers = styled.div`
  letter-spacing: -0.5px;
  color: white;
  padding-bottom: 2px;
  @media (max-width: ${LG}) {
    display: none;
  }
`;

export const CounterTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 400;
  color: white;
  @media (max-width: ${XS}) {
    display: none;
  }
`;

export const VideoBg = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;
