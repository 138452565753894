import React, { Suspense, useContext } from "react";
import { ThemeContext } from "styled-components";

import { Wrapper, Card, TextContainer, Text, Headline, Email } from "./style";

// FUNCTION
function getHtml(info) {
  return { __html: info };
}

export default props => {
  const theme = useContext(ThemeContext);

  return (
    <Suspense fallback={null}>
      {props.hoursTemplate ? (
        <Wrapper dineCard={props.dineCard} lastCard={props.lastCard}>
          {props.cardData ? (
            <Card dineCard={props.dineCard}>
              <TextContainer>
                <Text color={theme.blackColor()}>
                  {props.cardData.hours_box_title_1}
                </Text>
                <Headline color={theme.blackColor()}>
                  {props.cardData.hours_box_text_1}
                </Headline>
              </TextContainer>
              <TextContainer>
                <Text color={theme.blackColor()}>
                  {props.cardData.hours_box_title_2}
                </Text>
                <Headline color={theme.blackColor()}>
                  {props.cardData.hours_box_text_2}
                </Headline>
              </TextContainer>
            </Card>
          ) : (
            ""
          )}
        </Wrapper>
      ) : (
        <Card
          top={props.top}
          padding={props.padding}
          left={props.left}
          dineCard={props.dineCard}
        >
          <TextContainer>
            <Headline font={"Roboto"} color={theme.blackColor()}>
              {props.cardData.title}
            </Headline>
          </TextContainer>
          <TextContainer>
            <Text
              font={"Roboto"}
              color={theme.blackColor()}
              dangerouslySetInnerHTML={getHtml(props.cardData.text)}
            ></Text>
          </TextContainer>
          <TextContainer>
            <Text font={"Roboto"} color={theme.blackColor()}>
              {props.cardData.phoneText}
            </Text>
            <Headline color={theme.blackColor()}>
              <a
                href={`tel:${
                  props.cardData.phoneTextNumber.substring(0, 1) === "+"
                    ? "+"
                    : ""
                }${props.cardData.phone}`}
              >
                {props.cardData.phoneTextNumber}
              </a>
            </Headline>
          </TextContainer>
          <TextContainer>
            <Text font={"Roboto"} color={theme.blackColor()}>
              {props.cardData.emailText}
            </Text>
            <Email
              href={"mailto:" + props.cardData.email}
              color={theme.blackColor()}
            >
              {props.cardData.email}
            </Email>
          </TextContainer>
        </Card>
      )}
    </Suspense>
  );
};
