import React, { useContext, useEffect, useState, Fragment } from "react";
import { ThemeContext } from "styled-components";
import Logo from "../commons/logo";
import { useMediaQuery } from "react-responsive";
import FacebookIcon from "../../assets/facebook.svg";
import InstagramIcon from "../../assets/instagram.svg";
import TwitterIcon from "../../assets/twitter.svg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { convertPhone } from "../../utils/constants";

import {
  Wrapper,
  LogoFooter,
  HeadTel,
  LogoContainer,
  Grid,
  List,
  ListItem,
  CustomLink,
  NoLink,
  LinkRouter,
  SocialImage,
  Copyright,
  SocialMobile,
  PlaceLogo
} from "./style";

export default () => {
  const theme = useContext(ThemeContext);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [info, setInfo] = useState(null);
  const [homeNumber, setHomeNumber] = useState("");
  const [frontDeskTel, setFrontDeskTel] = useState("");
  const [frontDeskTel2, setFrontDeskTel2] = useState("");
  const [reservationTel, setReservationTel] = useState("");

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    resizeWindow();

    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  const location = useLocation();

  useEffect(() => {
    getInfo(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/general_info/");
  }, []);

  useEffect(() => {
    if (info && homeNumber === "") {
      setHomeNumber(convertPhone(info.main_tel));
    }
    if (info && frontDeskTel === "") {
      setFrontDeskTel(convertPhone(info.front_desk_tel));
    }
    if (info && frontDeskTel2 === "") {
      setFrontDeskTel2(convertPhone(info.front_desk_tel_2));
    }
    if (info && reservationTel === "") {
      setReservationTel(convertPhone(info.reservations_tel));
    }
  }, [homeNumber, info, frontDeskTel, frontDeskTel2, reservationTel]);

  async function getInfo(url) {
    try {
      const response = await axios.get(url);

      setInfo(response.data[0].acf);
    } catch (error) {
      console.error(error);
    }
  }

  const copyright = new Date().getFullYear()

  return (
    <Wrapper
      color={theme.blackColor()}
      contactMargin={
        location.pathname.replace("/", "") === "contact" ||
        location.pathname.replace("/", "") === "contact/" || location.pathname.replace("/", "") === "404/" || location.pathname.replace("/", "") === "404"
      }
    >
      {info && (
        <Fragment>
          <LogoFooter>
            <LogoContainer>
              <PlaceLogo>
                <Logo />
              </PlaceLogo>

              {!isMobile && (
                <HeadTel
                  href={`tel:${
                    info.main_tel.substring(0, 1) === "+" ? "+" : ""
                  }${homeNumber}`}
                >
                  {info.main_tel}
                </HeadTel>
              )}
              {isMobile && (
                <List>
                  <SocialMobile>
                    {info.facebook_url !== null && (
                      <ListItem>
                        <CustomLink target="_blank" href={info.facebook_url}>
                          <SocialImage src={FacebookIcon} alt="Facebook" />
                        </CustomLink>
                      </ListItem>
                    )}
                    {info.instagram_url !== null && (
                      <ListItem>
                        <CustomLink target="_blank" href={info.instagram_url}>
                          <SocialImage src={InstagramIcon} alt="Instagram" />
                        </CustomLink>
                      </ListItem>
                    )}
                    {info.twitter_url !== null && (
                      <ListItem>
                        <CustomLink target="_blank" href={info.twitter_url}>
                          <SocialImage src={TwitterIcon} alt="Twitter" />
                        </CustomLink>
                      </ListItem>
                    )}
                  </SocialMobile>
                </List>
              )}
            </LogoContainer>
          </LogoFooter>
          <Grid>
            <List>
              <ListItem dangerouslySetInnerHTML={{ __html: info.address }} />
            </List>
            <List>
              <ListItem>
                General:{" "}
                <CustomLink href={`mailto:${info.mail}`} rel="noopener">
                  {info.mail}
                </CustomLink>
                <span>
                  Front Desk:{" "}
                  {windowWidth < 992 ? (
                    <Fragment>
                      <NoLink>
                        <a
                          href={`tel:${
                            info.front_desk_tel.substring(0, 1) === "+"
                              ? "+"
                              : ""
                          }${frontDeskTel}`}
                        >
                          {info.front_desk_tel}
                        </a>
                      </NoLink>
                      OR
                      <NoLink>
                        <a
                          href={`tel:${
                            info.front_desk_tel_2.substring(0, 1) === "+"
                              ? "+"
                              : ""
                          }${frontDeskTel2}`}
                        >
                          {info.front_desk_tel_2}
                        </a>
                      </NoLink>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <NoLink>
                        <a
                          href={`tel:${
                            info.front_desk_tel.substring(0, 1) === "+"
                              ? "+"
                              : ""
                          }${frontDeskTel}`}
                        >
                          {info.front_desk_tel}
                        </a>
                      </NoLink>
                      {" or "}
                      <NoLink>
                        <a
                          href={`tel:${
                            info.front_desk_tel_2.substring(0, 1) === "+"
                              ? "+"
                              : ""
                          }${frontDeskTel2}`}
                        >
                          {info.front_desk_tel_2}
                        </a>
                      </NoLink>
                    </Fragment>
                  )}
                </span>
                Reservations:
                {windowWidth < 992 ? (
                  <NoLink>
                    <a
                      href={`tel:${
                        info.reservations_tel.substring(0, 1) === "+"
                          ? "+"
                          : null
                      }${reservationTel}`}
                    >
                      {info.reservations_tel}
                    </a>
                  </NoLink>
                ) : (
                  <NoLink>
                    <a
                      href={`tel:${
                        info.reservations_tel.substring(0, 1) === "+"
                          ? "+"
                          : null
                      }${reservationTel}`}
                    >
                      {info.reservations_tel}
                    </a>
                  </NoLink>
                )}
              </ListItem>
            </List>
            <List>
              <ListItem>
                <a
                  href={process.env.REACT_APP_CUSTOMER_PORTAL}
                  target={"_blank"}
                  rel="noopener"
                >
                  My Account
                </a>
              </ListItem>
              <ListItem>
                <LinkRouter to="/cayman-islands">The Cayman Islands</LinkRouter>
              </ListItem>
              <ListItem>
                <LinkRouter to="/contact">Contact Us</LinkRouter>
              </ListItem>
              <ListItem>
                <LinkRouter to="/privacy-policy">Privacy Policy</LinkRouter>
              </ListItem>
            </List>
            {!isMobile && (
              <List>
                {info.facebook_url !== null && (
                  <ListItem>
                    <CustomLink target="_blank" href={info.facebook_url}>
                      <SocialImage src={FacebookIcon} alt="Facebook" />
                    </CustomLink>
                  </ListItem>
                )}
                {info.instagram_url !== null && (
                  <ListItem>
                    <CustomLink target="_blank" href={info.instagram_url}>
                      <SocialImage src={InstagramIcon} alt="Instagram" />
                    </CustomLink>
                  </ListItem>
                )}
                {info.twitter_url !== null && (
                  <ListItem>
                    <CustomLink target="_blank" href={info.twitter_url}>
                      <SocialImage src={TwitterIcon} alt="Twitter" />
                    </CustomLink>
                  </ListItem>
                )}
              </List>
            )}
          </Grid>
          <Copyright>© Morritt’s {copyright}. All rights reserved.</Copyright>
        </Fragment>
      )}
    </Wrapper>
  );
};
