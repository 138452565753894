import styled from "styled-components";
import { LG } from "../../../../utils/constants";

export const Wrapper = styled.div`
  margin: 40px 0 40px;

  @media (max-width: ${LG}) {
    margin: 0 -40px;

    padding: 30px 0;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

export const List = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;

  li {
    &:first-child {
      > div {
        margin-left: 0;
      }
    }
    &:last-child {
      > div {
        margin-right: 0;
      }
    }
  }

  @media (max-width: ${LG}) {
    li {
      &:first-child {
        > div {
          margin-left: 170px;
        }
      }
      &:last-child {
        > div {
          margin-right: 40px;
        }
      }
    }
  }
`;

export const Tab = styled.div`
  height: 67px;
  width: 67px;
  border-radius: 33.5px;
  background-color: ${props =>
    props.selected ? props.theme.skyBlue : "#f6f9fb"};
  position: relative;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px; /* and that, will result in a 10px gap */

  transition: ${props => props.theme.defaultTransition()};

  &:hover {
    background-color: ${props => props.theme.skyBlue};

    span {
      color: white;
      font-weight: 600;
    }
  }

  span {
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
    color: ${props =>
      props.selected ? "white" : props => props.theme.darkBlue};
    font-weight: ${props => (props.selected ? "600" : "400")};
  }

  &:after {
    content: "";
    position: absolute;
    display: ${props => (props.selected ? "block" : "none")};
    width: 70px;
    height: 120px;
    background-color: white;
    transform: rotate(45deg);
    top: 80px;
    left: 97%;
    margin-left: -84px;
    border-radius: 5px;
  }

  @media (max-width: ${LG}) {
    height: 48px;
    width: 48px;
    margin: 10px;

    span {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }

    &:after {
      display:none;
      width: 40px;
      height: 100px;
      top: 67px;
      left: 142%;
      margin-left: -84px;
    }
  }
`;
