import React, { Suspense } from "react";

import {
  Wrapper,
  BorderImage,
  SubtitleMain,
  ResidentGrid,
  TextContainerResident,
  Title,
  Paragraph
} from "./style";

import { useMediaQuery } from "react-responsive";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";

export default props => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  return (
    <Suspense fallback={null}>
      {props.data ? (
        <Wrapper>
          <AnimatedWrap>
            <ResidentGrid>
              {!isMobile && (
                <BorderImage bg={props.data.acf.staycation_top_image} />
              )}

              <TextContainerResident>
                <Title>{props.data.acf.staycation_title}</Title>
                <SubtitleMain>
                  {props.data.acf.staycation_subtitle}
                </SubtitleMain>
                {isMobile && (
                  <BorderImage bg={props.data.acf.staycation_top_image} />
                )}
                <Paragraph
                  dangerouslySetInnerHTML={{
                    __html: props.data.acf.staycation_text
                  }}
                />
              </TextContainerResident>
            </ResidentGrid>
          </AnimatedWrap>
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
