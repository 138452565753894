import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";

import { Wrapper } from "./style";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import {setHeaders} from "../../utils/setHeaders"
import { createBrowserHistory } from "history";

/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const Residents = lazy(() => import("./residents"));
const Features = lazy(() => import("./features"));
const MainSlider = lazy(() => import("../../components/commons/mainSlider/"));
const FinePrint = lazy(() => import("./finePrint")); */

import HeroViews from "../../components/commons/heroViews"
import Residents from "./residents"
import Features from "./features"
import MainSlider from "../../components/commons/mainSlider"
import FinePrint from "./finePrint"

export default () => {
  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/167");
  }, []);

  const [data, setData] = useState(null);
  const history = createBrowserHistory();

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {data ? (
        <Wrapper>
          <AnimatedWrap>
            <HeroViews
              bg={data.acf.staycation_hero_image}
              title={data.title.rendered}
            />
            <Residents data={data} />
            <Features data={data} />
            <FinePrint />
            <MainSlider
              images={data.acf.staycation_gallery}
              title={"Staycation."}
              color={"#1F3040"}
            />{" "}
          </AnimatedWrap>
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
