import React from "react";
import logoMorrits from "../../../assets/morrits-logo.png";
import logoMorritsReverse from "../../../assets/logo-header-reverse.png";
import { Wrapper, LogoBgWhite } from "./style";

export default ({ showbg }) => (
  <Wrapper>
    {showbg ? (
      <LogoBgWhite src={logoMorritsReverse} alt="Morritss" />
    ) : (
      <img src={logoMorrits} alt="Morritts" />
    )}
  </Wrapper>
);
