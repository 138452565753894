import styled from "styled-components";
import {XS, LG, XL} from "../../utils/constants";

export const Wrapper = styled.div``

export const InternalWrapper
  = styled.div`
  padding: 0 165px 0;
  margin-bottom: 40px;

  h2 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: bold;
    line-height: 56px;
  }

  ul {
    padding-left: 20px;
  }

  @media (max-width: ${XL}) {
    padding: 0 60px;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${XS}) {
    padding: 0 20px;
    
    h2 {
      font-size: 37px;
      font-weight: bold;
      line-height: 42px;
    }
  }
`;