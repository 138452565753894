import styled from "styled-components";

import { XL, LG, XS } from "../../../utils/constants";
import Cta from "../../../components/commons/cta";

export const Wrapper = styled.div`
  width: 100%;
  height: ${props => (props.customHeight ? props.customHeight : "430px")};

  padding: 80px 160px 0;
  background: url(${props => props.bg}) no-repeat center center / cover;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  position: relative;

  @media (max-width: ${XL}) {
    padding: 80px 30px 0;
  }
  @media (max-width: ${LG}) {
    padding: 80px 68px 0;
    height: ${props => (props.customHeight ? props.customHeight : "276px")};
  }
  @media (max-width: ${XS}) {
    padding: 80px 20px 20px;
    height: ${props => (props.customHeight ? props.customHeight : "auto")};
  }

  @media (max-width: ${XS}) {
    background: url(${props => (props.bgMobile ? props.bgMobile : props.bg)})
      no-repeat center center / cover;
  }
`;

export const BtnLink = styled(Cta)`
  position: absolute;
  right: 160px;
  top: 120px;
  z-index: 10;

  @media (max-width: ${LG}) {
    right: 30px;
    top: ${props => (props.logintop ? props.logintop : "183px")};
  }
`;

export const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000000;
  opacity: 0.55;
`;
