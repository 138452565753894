import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import routes from "./routes";

import Error404 from "../views/error404/error404"

export default () => {
  const location = useLocation();
  const remainingStyles = {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%"
  };
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0.1, ...remainingStyles },
    enter: { opacity: 1, ...remainingStyles },
    leave: { opacity: 0, ...remainingStyles },
    config: { duration: 350 }
  });
  return transitions.map(({ item, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={item}>
        {routes
          /*      .filter(r => !r.isSection) */
          .map((route, i) => (
            <Route key={i} exact {...route} />
          ))}
        <Route path="/404" component={Error404} />
        <Redirect to="/404" />
      </Switch>
    </animated.div>
  ));
};
