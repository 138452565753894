import WaterIcon from "../../assets/hotels_icons/sea.svg";
import SwimIcon from "../../assets/hotels_icons/Swim.svg";
import BathIcon from "../../assets/hotels_icons/bath.svg";
import CaribeIcon from "../../assets/hotels_icons/caribe.svg";
import ChildrensPoolIcon from "../../assets/hotels_icons/childrens-pool.svg";
import OceanViewIcon from "../../assets/hotels_icons/oceanview.svg";
import OvenIcon from "../../assets/hotels_icons/oven.svg";
import ParkingIcon from "../../assets/hotels_icons/parking.svg";
import PhIcon from "../../assets/hotels_icons/ph.svg";
import TownIcon from "../../assets/hotels_icons/townhouse.svg";



const images = [
  {
    WaterIcon,
    SwimIcon,
    BathIcon,
    CaribeIcon,
    ChildrensPoolIcon,
    OceanViewIcon,
    OvenIcon,
    ParkingIcon,
    PhIcon,
    TownIcon,
  }
];

export default images;
