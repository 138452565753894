export default {
  dark: (opacity = 1) => `rgba(45,45,45,${opacity})`,
  grey: (opacity = 1) => `rgba(85,85,85,${opacity})`,
  defaultTransition: (type = "all", s = 0.3) =>
    `${type} cubic-bezier(0.15, 0.82, 1, 1) ${s}s`,
  blackColor: () => "#1F3040",
  lightGrayColor: () => "#D0DCE833",
  darkBlue: "#1F3040",
  skyBlue: "#006DBC",
  imagaMask: "rgba(31,48,64,0.4)"
};
