import React from "react";
import { CircleSpinner } from "react-spinners-kit";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  
  font-size: 12px;
  letter-spacing: 0;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  color: black;
  gap: 10px;
  font-style: italic;
`;

export const LoaderSpin = props => {
  const debug = false;
  return (
    <Wrapper>
      <CircleSpinner size={60} color="#1F3040" loading={true} />
      {debug &&
        <span>{props.children}</span>
      }
    </Wrapper>
  );
};

export default LoaderSpin;
