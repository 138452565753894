import React, { useContext, Suspense, } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { ThemeContext } from "styled-components";
import {Wrapper, Title, Image, TextContainer, Text, TextInfo} from "./style";

import Button from "../buttonGeneric"
import ContactInfo from "../contactInfo/contactInfo";

export default props => {
  const theme = useContext(ThemeContext);
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  // console.log('stickyImageTextRow', props.data);

  return (
    <Suspense fallback={<div></div>}>
      {props.data ? (
        <Wrapper>

          {isMobile && props.data.link ? (
            <Link to={props.data.link}>
              <Button
                title={props.data.link_name}
                transition={theme.defaultTransition()}
              >
                {props.data.link_name}
              </Button>
            </Link>
          ) : null}

          <Image bg={props.data.side_image} />

          <TextContainer>
            <Title>{props.data.title}</Title>

            {(props.data.show_info ? (
              <>
                <TextInfo
                  dangerouslySetInnerHTML={{
                    __html: props.data.text
                  }}
                />
                <ContactInfo data={{
                  front_desk: true,
                  reservations: false,
                  address: true,
                  email: true
                }}/>
              </>
            ) : (
              <TextInfo
                dangerouslySetInnerHTML={{
                  __html: props.data.text
                }}
              />
            ))}


            {!isMobile && props.data.link ? (
              <Link to={props.data.link}>
                <Button
                  title={props.data.link_name}
                  transition={theme.defaultTransition()}
                >
                  {props.data.link_name}
                </Button>
              </Link>
            ) : null}

          </TextContainer>
        </Wrapper>
      ) : <div></div>}
    </Suspense>
  );
};
