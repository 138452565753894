import React, { useEffect, useState, Fragment, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

import ShareIcon from "../../assets/share.svg";
import CopyUrlIcon from "../../assets/blogpost/ico/url.svg";
import FbIcon from "../../assets/blogpost/ico/fb.svg";
import onClickOutside from "react-onclickoutside";

import HelmetMetaData from "../../utils/HelmetMetaData";

// STYLES
import {
  Wrapper,
  ShareButton,
  Menu,
  ButtonAnimated,
  Caret,
  CopyBtn,
  CopyMessage
} from "./style";

const ShareModule = props => {
  const [visible, setVisible] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [visible]);

  ShareModule.handleClickOutside = () => setVisible(false);

  return (
    <Wrapper>
      <HelmetMetaData
        title={props.shareData.title}
        quote={props.shareData.title}
        image={props.shareData.image}
      ></HelmetMetaData>

      <ShareButton onClick={() => setVisible(!visible)}>
        <img src={ShareIcon} alt="share" />
      </ShareButton>

      <Menu visible={visible} onClick={() => setVisible(false)}>
        {visible && (
          <Fragment>
            <ButtonAnimated delay={0.1}>
              <FacebookShareButton
                url={props.shareData.currentLocation}
                quote={"Morritt's"}
                hashtag="#Morritts"
              >
                <img src={FbIcon} alt="Facebook" />

                <span>Facebook</span>
              </FacebookShareButton>
            </ButtonAnimated>

            <ButtonAnimated delay={0.2}>
              <TwitterShareButton
                url={props.shareData.currentLocation}
                quote={"Morritt's"}
                hashtag="#Morritts"
              >
                <TwitterIcon size={34} round={true} />
                <span>Twitter</span>
              </TwitterShareButton>
            </ButtonAnimated>

            <ButtonAnimated delay={0.3}>
              <CopyToClipboard
                text={props.shareData.currentLocation}
                onCopy={() => setCopied(true)}
              >
                <CopyBtn>
                  <img src={CopyUrlIcon} alt="Copy url" />
                  <span>Copy URL</span>
                </CopyBtn>
              </CopyToClipboard>
            </ButtonAnimated>
          </Fragment>
        )}
      </Menu>
      <Caret visible={visible} />
      {copied && <CopyMessage copied={copied}>Copied!</CopyMessage>}
    </Wrapper>
  );
};

ShareModule.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: () => ShareModule.handleClickOutside
};

export default onClickOutside(ShareModule, clickOutsideConfig);
