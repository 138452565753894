import React, { Suspense, useEffect, useState } from "react";
import { Wrapper } from "./style";

import axios from "axios";

import LoaderSpin from "../../components/LoaderSpin/LoaderSpin";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import SliderHome from "./sliderHome";
import {setHeaders} from "../../utils/setHeaders"
import { createBrowserHistory } from "history";

/* const Welcome = lazy(() => import("./Welcome")); */
/* const SliderHome = lazy(() => import("./sliderHome")); */
/* const FeaturedItems = lazy(() => import("./FeaturedItems"));
const Residents = lazy(() => import("./residents/")); */

import Welcome from "./Welcome"
import FeaturedItems from "./FeaturedItems"
import Residents from "./residents"

export default () => {
  const [data, setData] = useState(null);
  const [hotelsInfo, setHotelsInfo] = useState(null);
  const finalHotels = [];
  const [info, setInfo] = useState(null);
  const history = createBrowserHistory();

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/9");
    fetchHotels(
      process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages?per_page=100"
    );
    getInfo(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/general_info/");
  }, []);

  async function getInfo(url) {
    try {
      const response = await axios.get(url);

      setInfo(response.data[0].acf);
    } catch (error) {
      console.error(error);
    }
  }
  

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchHotels(url) {
    try {
      const response = await axios.get(url);
      setHotelsInfo(response.data.reverse());
    } catch (error) {
      console.error(error);
    }
  }

  if (hotelsInfo) {
    hotelsInfo.map(singleHotel => {
      if (singleHotel.id === 241) {
        return finalHotels.push(singleHotel);
      }
      if (singleHotel.id === 280) {
        return finalHotels.push(singleHotel);
      }
      if (singleHotel.id === 310) {
        return finalHotels.push(singleHotel);
      }
      return null;
    });
  }

  return (
    // <Suspense fallback={<LoaderSpin>home.Suspense</LoaderSpin>}>
    <>
      {data && info ? (
       /*  <AnimatedWrap> */
          <Wrapper>
            <SliderHome data={data} info={info} />

            <Welcome
              imageToRight={true}
              title={data.homepage_welcome_title}
              text={data.homepage_welcome_text}
              bg={data.homepage_welcome_image}
            />
            {hotelsInfo && <FeaturedItems hotelsInfo={finalHotels} />}

            <Residents data={data} />
          </Wrapper>
    /*     </AnimatedWrap> */
      ) : (
        <LoaderSpin>home.Wrapper</LoaderSpin>
      )}
    </>
    // </Suspense>
  );
};
