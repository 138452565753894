import styled from "styled-components";

import { LOWEST_RESOLUTIONS, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  border-radius: 16px;
  display: grid;
  justify-content: end;
  padding-right: 165px;
  position: relative;
  margin-top: ${props => (props.lastCard ? "-180px" : "-240px")};
  top: ${props => (props.lastCard ? "-40px" : "initial")};

  @media (max-width: ${LOWEST_RESOLUTIONS}) {
    position: ${props => (props.dineCard ? "absolute" : "relative")};
    right: ${props => (props.dineCard ? "-180px" : "inherit")};
    margin-top: ${props => (props.lastCard ? "-100px" : "-240px")};
    top: ${props => (props.lastCard ? "initial" : "initial")};
  }

  @media (max-width: ${LG}) {
    display: block;
    position: relative;
    width: 100%;
    right: 0;
    padding: 15px;
  }
`;

export const Card = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => (props.padding ? props.padding : "40px 80px 20px 40px")};
  position: relative;
  top: ${props => (props.top ? props.top : props.dineCard ? "170px" : "170px")};
  left: ${props => (props.left ? props.left : "inherit")};

  @media (max-width: ${LG}) {
    padding: 40px 20px 20px 20px;
    top: ${props => (props.top ? props.top : props.dineCard ? "30px" : "170px")};
  }
`;

export const TextContainer = styled.div`
  margin-bottom: 20px;
`;

export const Text = styled.span`
  color: ${props => props.color};
  font-family: ${props => (props.font ? props.font : "Poppins")};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  display: block;
`;

export const Headline = styled.h4`
  color: ${props => props.color};
  font-family: ${props => (props.font ? props.font : "Poppins")};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  a{
    text-decoration:none;
    color:inherit;
  }
`;

export const Email = styled.a`
  color: ${props => props.color};
  font-family: ${props => (props.font ? props.font : "Poppins")};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none;
`;
