import React, { Suspense } from "react";

// STYLES
import { Wrapper } from "./style";

// COMPONENTS
/* const ContactHero = lazy(() => import("./contactHero")); */

import ContactHero from "./contactHero";

export default () => {
  return (
    <Suspense fallback={null}>
      <Wrapper>
        <ContactHero
          bg={"https://placehold.it/1920x430"}
          title="Contact"
        ></ContactHero>
      </Wrapper>
    </Suspense>
  );
};
