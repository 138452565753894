import styled from "styled-components";
import arrow from "../../../assets/icons/breadcrumbarrow.svg";
import {Link} from "react-router-dom"

import { LG, XS } from "../../../utils/constants";

export const Wrapper = styled.div`
  display: flex;
 /*  z-index: ${props => (props.menuOpen ? 800 : 910)}; */
  color: white;
  font-family: Roboto;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  padding-bottom: 30px;
  position: relative;
  z-index:10;

  @media (max-width: ${LG}) {
    font-size: 16px;
    padding-bottom: 30px;
    margin-top: 40px;
  }

  @media (max-width: ${XS}) {
    padding-bottom: 20px;
  }
`;

export const Breadcrumb = styled(Link)`
  text-decoration: none;
  color: white;
  transition: ${props => props.theme.defaultTransition()};


  &::after {
    content: "";
    padding: 0 5px;
    width: 13px;
    height: 10px;
    display: inline-block;
    background: url(${arrow}) no-repeat center;
    background-size: contain;
  }

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`;

export const Current = styled.span`
  font-weight: 900;
`;
