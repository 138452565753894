import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  Fragment
} from "react";
import { ThemeContext } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { AnimatedWrap } from "../../components/commons/AnimatedComponents";

// STYLES
import { RecommendedPost, RecommendedTitle } from "./style";



import BlogCards from "../../components/commons/blogCards"

export default props => {
  const theme = useContext(ThemeContext);

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 0,
    dragabble: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: false,
          centerMode: false,
          centerPadding: 0
        }
      }
    ]
  };

  const [fetchPosts, setFetchPosts] = useState(null);
  const [posts, setRecommendedPosts] = useState(null);
  const [finalPosts, setFinalPosts] = useState([]);

  useEffect(() => {
    getRecommendedPosts(
      process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/23"
    );
    getAllPost(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/blog_posts");
  }, []);

  async function getRecommendedPosts(url) {
    try {
      const response = await axios.get(url);
      setFetchPosts(response.data.acf.recommended_posts);
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllPost(url) {
    try {
      const response = await axios.get(url);
      setRecommendedPosts(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (props.data) {
      try {
        props.data.forEach(numberId => {
          getPostsById(
              process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/blog_posts/" + numberId.ID
          );
        });
      } catch (err) {
        console.log('getPostsById', err);
      }
    }
    if (props.dataNews) {
      props.dataNews.forEach(numberId => {
        getPostsById(
          process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/newsupdatesposts/" + numberId.ID
        );
      });
    }
  }, [props.data, props.dataNews]);

  async function getPostsById(url) {
    try {
      const response = await axios.get(url);
      setFinalPosts(finalPosts => [...finalPosts, response.data]);
    } catch (error) {
      console.error("Can't get post " + url, error);
    }
  }

  return (
    <Suspense fallback={null}>
      {finalPosts ? (
        <AnimatedWrap>
          {props.data || props.dataNews ? (
            <RecommendedPost colorfont={theme.lightGrayColor}>
              <RecommendedTitle colorfont={theme.blackColor}>
                Recommended Reading.
              </RecommendedTitle>

              <Slider {...settings}>
                {finalPosts.map(item => (
                  <Fragment key={item.id}>
                    <div>
                      <BlogCards
                        post={item}
                        sectionName={props.sectionName}
                      ></BlogCards>
                    </div>
                  </Fragment>
                ))}
              </Slider>
            </RecommendedPost>
          ) : null}
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
