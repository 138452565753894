import styled from "styled-components";

import { StyledH3 } from "../../../components/commons/secondTitle/style";
import { StyledP } from "../../../components/commons/Paragraph/style";
import { RoundedImage } from "../../../components/commons/borderImage/style";

import { XL, HIGHER_RESOLUTIONS, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  max-width: 1111px;
  margin: 0 auto;

  p{
    color:${props => props.theme.darkBlue};
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    margin: 0 170px;
  }

  @media (max-width: ${XL}) {
    max-width: unset;
    margin: 0 20px;
    h3 {
      max-width: unset;
      padding-left: 0;
    }
  }
  @media (max-width: ${LG}) {
    h3 {
      padding-right: 0;
      margin-top: 15px;
    }
  }
`;

export const Title = styled(StyledH3)`
  color: ${props => props.theme.darkBlue};
  font-size: 80px;
  @media (max-width: ${XL}) {
    padding: 20px 80px 20px 20px;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 48px;
  }
`;

export const FirstGrid = styled.div`
  /*   display: grid;
  grid-template-columns: 1fr 1fr; */

  > div {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    > div {
      &:first-child {
        padding: 10px 30px 0 0;
        width: 90%;
      }
      article {
        height: 654px !important;
      }
    }

    @media (max-width: ${LG}) {
      width: 100%;
      display: block;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      padding: 0;

      &:last-child {
        margin-top: 100px;

        span,
        h6 {
          padding-left: 30px;
        }
      }

      > div {
        &:first-child {
          padding: 10px 0 0 0;
          height: auto;
          width: 100%;
        }
        article {
          height: 323px !important;
        }
      }
    }
  }
`;

export const Text = styled(StyledP)`

`;

export const OnSiteSports = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  h5 {
    font-family: Poppins;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    color: ${props => props.theme.darkBlue};
    margin-top: -20px;
    margin-bottom: 20px;

    @media (max-width: ${LG}) {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 34px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 0;

    li {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      span {
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-transform: uppercase;
        color: ${props => props.theme.skyBlue};
      }
    }

    @media (max-width: ${LG}) {
      grid-template-columns: 1fr;
      margin-top: 5px;

      li {
        &:nth-child(3) {
          grid-row: 3/4;
        }
        &:nth-child(4) {
          grid-row: 2/3;
        }
      }
    }
  }

  @media (max-width: ${LG}) {
    margin-bottom: 25px;
  }
`;

export const TimeCard = styled.div`
  height: 160px;
  width: 350px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  padding-left: 60px;

  span {
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${props => props.theme.darkBlue};
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h6 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    color: ${props => props.theme.darkBlue};
  }

  @media (max-width: ${LG}) {
    height: 120px;
    width: 100%;
    padding-left: 30px;

    span {
      margin-top: 0;
    }
  }
`;

export const Picture = styled(RoundedImage)`
  max-width: 899px;

  @media (max-width: ${LG}) {
    height: 323px;
    margin-top: 50px;
    margin-bottom: -35px;
  }
`;

export const SecondGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.6fr;

  p {
    max-width: 560px;
    margin-bottom: -35px;
  }

  ${Title} {
    max-width: 100px;
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    p {
      max-width: unset;
      margin-right: 30px;
    }
    ${Title} {
      max-width: unset;
    }
  }

  @media (max-width: ${XL}) {
    ${Title} {
      max-width: unset;
      padding-left: 0;
      margin-top: 20px;
    }
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`;
