import styled from "styled-components";
import Subtitle from "../../../components/commons/subtitle";

import { XL, LG, XS } from "../../../utils/constants";

export const Wrapper = styled.div``;

export const TitleAndIconsWrap = styled.div`
  h4 {
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    color: ${props => props.theme.darkBlue};

    padding: 60px 140px 0 162px;
    margin: 0 auto;

    @media (max-width: ${XL}) {
      padding: 60px 50px 0 50px;
    }

    @media (max-width: ${LG}) {
      padding: 60px 30px 0 30px;
    }
  }
`;

export const MainTitle = styled(Subtitle)``;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1024px 1.2fr;
  align-items: center;
  justify-content: right;
  align-content: space-evenly;
  justify-items: flex-end;

  grid-template-rows: 200px;
  padding-bottom: 60px;

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr 300px;
  }
  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 50px;
  }
  @media (max-width: ${XS}) {
    margin-top: 100px;
    background: url(${props => props.bg}) no-repeat center center / cover;
    height: auto;
  }
`;

export const IconList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 100px;
  max-width: 920px;
  padding-left: 60px;
  margin-top: 60px;

  @media (max-width: ${XL}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: unset;
    width: 100%;
    grid-template-rows: 100px;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: unset;
    width: 100%;
    grid-template-rows: 100px 50px;
    margin-top: 20px;
    padding-left: 30px;
  }

  @media (max-width: ${XS}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;

    margin: 0 auto;
    width: 90%;

    padding: 20px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 20px 80px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    top: -40px;
  }
`;

export const IconLi = styled.li`
  display: flex;
  align-items: center;
  align-items: ${props => (props.disclamer ? "end" : "center")};
  margin-bottom: 10px;
  padding-top: ${props => (props.disclamer ? "50px" : "inherit")};

  @media (max-width: ${XS}) {
    padding-top: ${props => (props.disclamer ? "10px" : "inherit")};
    img {
      top: ${props => (props.disclamer ? "0" : "inherit")};
    }
  }

  img {
    width: 32px;
    margin-right: 15px;
    position: relative;
    top: ${props => (props.disclamer ? "-8px" : "inherit")};

    @media (max-width: ${XS}) {
      width: 25px;
    }
  }

  p {
    color: ${props => props.theme.skyBlue};
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: uppercase;
  }
  span {
    color: rgba(0, 0, 0, 0.4);
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    display: block;
    max-width: 166px;

    @media (max-width: ${XS}) {
      max-width: 250px;
    }
  }
`;

export const Disclaimer = styled.div`
  border-radius: 16px 0 0 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  width: 24.65277777777778vw;
  display: flex;
  align-items: center;
  padding: 20px;
  margin-top: 100px;
  span {
    color: ${props => props.theme.darkBlue};
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    max-width: 200px;

    em {
      color: ${props => props.theme.skyBlue};
      font-style: normal;
    }
  }

  @media (max-width: ${LG}) {
    margin-top: 50px;
    width: 50%;
  }

  @media (max-width: ${XS}) {
    margin-top: -50px;
  }
`;

export const TitleMobile = styled.div`
  padding: 35px 20px 0;
  margin-bottom: -40px;

  h3 {
    color: ${props => props.theme.darkBlue};
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
  }

  p {
    margin-top: 20px;
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
`;
