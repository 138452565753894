import styled from "styled-components";
import Subtitle from "../../../components/commons/subtitle/";
import { HIGHER_RESOLUTIONS, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  max-width: 1111px;
  margin: 0 auto;
  margin: -20px auto;
  padding-left: 10px;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    margin: -20px 170px 0;
  }

  @media (max-width: ${LG}) {
    margin: 20px 15px auto;
  }
`;

export const Title = styled(Subtitle)``;

export const MainTable = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.2);

  > div > div > div {
    display: flex;
    align-items: center;

    > div > div > img {
      margin-right: 10px;
    }

    > div > div > span {
      color: ${props => props.theme.skyBlue};
      font-family: Poppins;
      font-size: 16px;
      letter-spacing: 3px;
      line-height: 25px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  @media (max-width: ${LG}) {
    > div > div > div {
      align-items: self-end;
      flex-direction: column;
      justify-content: center;
    }

    span {
      font-size: 12px !important;
      letter-spacing: 0;
      line-height: 16px !important;
      text-align: left !important;
    }
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.5fr;
  border-bottom: 0.6px solid rgba(41, 58, 73, 0.24);
  padding: 30px 30px 25px 60px;
  z-index: 2;
  position: relative;
  background-color: white;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  div {
    display: flex;
    align-items: center;
    justify-content: unset;

    img{

      margin-right:10px;
    }
  }

  @media (max-width: ${LG}) {
    padding: 10px 20px;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    align-items: center;
  }

  span {
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 3px;
    line-height: 25px;
    text-align: center;
    color: ${props => props.theme.skyBlue};
    text-transform: uppercase;
  }
`;

export const TableList = styled.div``;

export const Item = styled.div`
  display: grid !important;
  grid-template-columns: 0.4fr 1fr 0.5fr;

  &:first-child {
    span {
      padding-top: 40px;
      display: block;
      text-align: center;
    }
    > div > div > div {
      padding-top: 15px;
    }

    > h4 {
      padding-top: 15px;
    }
  }
  &:last-child {
    span {
      padding-bottom: 40px;
      display: block;
    }
    > div > div > div {
      padding-bottom: 25px;
    }

    > h4 {
      padding-bottom: 25px;
    }
  }

  span {
    background-color: rgba(208, 220, 232, 0.18);
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 19px;
    color: ${props => props.theme.darkBlue} !important;
    height: 100%;

    padding: 20px 0;
  }
  h4 {
    font-family: Roboto;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${props => props.theme.darkBlue};
    font-weight: 300;
  }

  @media (max-width: ${LG}) {
    align-items: flex-start !important;

    span {
      font-size: 12px !important;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 14px;
      text-align: center !important;
      padding: 0;

      display: flex;
      align-self: center;
    }

    h4 {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
    }

    &:first-child {
      > div > div > div {
        padding-top: 31px;
      }
      span {
        padding-top: 30px;
      }
      h4 {
        /*      padding-top: 30px; */
        display: flex;
        flex-direction: column;
        align-self: center;
      }
    }
    > div {
      padding-bottom: 0;
      display: flex;
      align-self: center !important;
    }
  }
`;

export const ActivityField = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px;

  h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    color: ${props => props.theme.skyBlue};
  }

  @media (max-width: ${LG}) {
    padding: 0 15px;
    flex-direction: column;
    h3 {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      padding-bottom: 5px;
      margin-top: -5px;
    }
  }
`;

export const UsdCTA = styled.div`
  font-family: Poppins;
  color: ${props => props.theme.skyBlue};
  font-size: 16px;
  background-color: #206cb50f;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;

  @media (max-width: ${LG}) {
    max-width: max-content;
  }
`;

export const Disclaimer = styled.div`
  font-size: 12px;
  line-height: 1;
  font-family: Roboto;
  color: ${props => props.theme.darkBlue};
  max-width: 200px;
  margin-top:20px;
  margin-left:10px;
`;

export const MobileTitle = styled.div`
  color: ${props => props.theme.darkBlue};
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
  margin-top: 30px;
`;
