import React from "react";
import { Marker } from "./style";

export default props => {
  const { color, name, id } = props;
  return (
    <Marker>
      <div
        className="pin bounce"
        style={{ backgroundColor: color, cursor: "pointer" }}
        title={name}
      />
      <div className="pulse" />
    </Marker>
  );
};


