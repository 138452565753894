import styled from "styled-components";

import menuMobilePic from "../../../assets/menu-mobile.jpg";

export const Wrap = styled.div`
  z-index: 998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(${menuMobilePic}) no-repeat center center / cover;
  transition: ${props => props.theme.hoverbiezer};
  display: flex;
  flex-direction: column;
  transition: ${props => props.theme.defaultTransition('all', 0.2)};
  justify-content: start;

  opacity: ${props => (props.opened ? "1" : "0")};
  visibility: ${props => (props.opened ? "visible" : "hidden")};
/*   display: ${props => (props.opened ? "block" : "none")}; */

  @media (min-width: 1300px) {
    display: none;
  }
`;
