import React, { useState, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Wrapper } from "./appStyle";
import { styleTheme } from "./utils";

import Views from "./views"
import Header from "./components/header/";
import ViewsWrapper from "./components/viewsWrapper/";
import Footer from "./components/footer/";
import LoaderSpin from "./components/LoaderSpin/LoaderSpin";

/* const Views = lazy(() => import("./views"));
const Header = lazy(() => import("./components/header/"));
const ViewsWrapper = lazy(() => import("./components/viewsWrapper/"));
const Footer = lazy(() => import("./components/footer/")); */

export default () => {
  const [scrollYPos, setScrollYPos] = useState(0);

  const location = useLocation();

  const [firstLoad, setfFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      setfFirstLoad(false);
    }
  }, [location]);

  return (
    <Suspense fallback={<LoaderSpin>SUSEPENSE: app.js</LoaderSpin>}>
      <ThemeProvider theme={styleTheme}>
        {/*firstLoad: {firstLoad ? 'true' : 'false'}*/}
        {/*{firstLoad &&*/}
        {/*<LoaderSpin>app.js</LoaderSpin>*/}
        {/*}*/}
        <Wrapper>
          <Header scrollYPos={scrollYPos} location={location.pathname.replace("/", "")} />
          <ViewsWrapper setScrollYPos={setScrollYPos}>
            <Views />
          </ViewsWrapper>
        </Wrapper>
        <Footer />
      </ThemeProvider>
    </Suspense>
  );
};
