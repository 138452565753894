import styled from "styled-components";
import Subtitle from "../../../components/commons/subtitle";
import { StyledH3 } from "../../../components/commons/secondTitle/style";
import { XL, LOWEST_RESOLUTIONS } from "../../../utils/constants";

export const Wrapper = styled.div`
  display: grid;
  /*   max-width: 1110px; */
  margin: 0 auto;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: ${props => (props.columnGap ? props.columnGap : "30px")};
  grid-template-areas: ${props =>
    props.gridOrder ? props.gridOrder : "'text image'"};
  padding: 60px 165px 0;

  @media (max-width: ${XL}) {
    padding: 60px 30px 0;
  }
`;
export const Right = styled.div`
  grid-area: text;
  margin-top: ${props => (props.customTop ? 0: "initial")};

  @media (min-width: ${LOWEST_RESOLUTIONS}) {
    margin-top: 0;
  }
`;

export const Left = styled.div`
  border-radius: 16px;
  grid-area: image;
  background: url(${props => props.bg}) no-repeat center center / cover;
  width:${props => props.imageToEdge ? '800px' : 'initial'};
  margin-left:${props => props.imageToEdge ? "-370px" : 'initial'};
`;

export const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  color: ${props => props.darkBlue};
  margin: 0;
  padding-bottom: 30px;
`;

export const TextBold = styled.p`
  font-weight: 900;
`;

export const Link = styled.a`
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  color: ${props => props.darkBlue};
  margin: 0;
  text-decoration: none;
`;

export const middleSection = styled.div`
  grid-template-columns: 440px 1.4fr;
  img {
    transform: translate(-175px, -70px);
  }
`;

export const SubtitleWrap = styled.div`
  margin-bottom: 40px;
`;

export const MainSubtitle = styled(Subtitle)``;

export const Title = styled(StyledH3)`
  color: ${props => props.theme.darkBlue};
  font-family: Poppins;
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 72px;
  margin-top: -10px;
  margin-bottom: -20px;
`;

export const MoreInfoText = styled.div`
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  color: ${props => props.theme.darkBlue};

  a {
    color: ${props => props.theme.skyBlue};
    text-decoration: none;
  }
`;


export const ParaWrap = styled.div`

p{
  padding-bottom:20px;
}
`

