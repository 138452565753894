import React, { Suspense, Fragment } from "react";
import { toggleMobileMenu } from "../../../redux/actions";
import { connect } from "react-redux";
import { Wrapper, BtnLink, Mask } from "./style";

/* const TitleBig = lazy(() => import("../titleBig"));
const Subtitle = lazy(() => import("./HeroSubtitle"));
const BreadCrumbs = lazy(() =>
  import("../../../components/commons/breadcrumbs")
); */

import TitleBig from "../titleBig"
import Subtitle from "./HeroSubtitle"
import BreadCrumbs from "../../../components/commons/breadcrumbs"

const HeroViews = props => {
  return (
    <Suspense fallback={<div></div>}>
      {props ? (
        <Wrapper
          bg={props.bg}
          bgMobile={props.bgMobile}
          customHeight={props.customHeight}
        >
          {!props.withoutBC && <BreadCrumbs blogBread={props.blogBread} />}

          {props.loginButton && (
            <Fragment>
              <BtnLink
                typebtn="main"
                to={{ pathname: process.env.REACT_APP_CUSTOMER_PORTAL }}
                target="_blank"
                logintop={props.logintop}
              >
                Owner Login
              </BtnLink>
            </Fragment>
          )}
          <TitleBig
            menuOpen={props.menuOpen}
            title={props.title}
            isPost={props.isPost}
            loginButton={props.loginButton}
          />
          <Subtitle subtitle={props.subtitle} isPost={props.isPost} />
          <Mask />
        </Wrapper>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    menuToggle: () => dispatch(toggleMobileMenu())
  };
};

const mapStateToProps = state => {
  return {
    menuOpen: state.toggleMobileMenu.menuOpened
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroViews);
