import React, { Suspense, lazy, useContext, useState, useEffect } from "react";

import { Wrapper } from "./style";
import { ThemeContext } from "styled-components";
import { createBrowserHistory } from "history";
import images from "./images";

import axios from "axios";
import { AnimatedWrap } from "../../../components/commons/AnimatedComponents";
import { setHeaders } from "../../../utils/setHeaders";

/* const HeroViews = lazy(() => import("../../../components/commons/heroViews"));
const Navigation = lazy(() => import("../../../components/commons/upperList"));
const Shopping = lazy(() => import("../shopping"));
const Spa = lazy(() => import("../spa"));
const Land = lazy(() => import("../land"));
const Sea = lazy(() => import("../sea"));
const DailyActivities = lazy(() => import("../dailyActivities")); */

import HeroViews from "../../../components/commons/heroViews"
import Navigation from "../../../components/commons/upperList"
import Shopping from "../shopping"
import Spa from "../spa"
import Land from "../land"
import Sea from "../sea"
import DailyActivities from "../dailyActivities"


export default () => {
  const theme = useContext(ThemeContext);

  const history = createBrowserHistory();

  const [finalRoute, setFinalRoute] = useState("");

  const currentRoute = history.location.pathname.split("/");
  const parsedCurrentRoute = currentRoute.slice(currentRoute.length - 2);

  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    if (finalRoute === "sea" || finalRoute === "sea/") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/533");
    }
    if (finalRoute === "land" || finalRoute === "land/") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/580");
    }
    if (finalRoute === "spa" || finalRoute === "spa/") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/614");
    }
    if (finalRoute === "shopping" || finalRoute === "shopping/") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/625");
    }
    if (
      finalRoute === "daily-activities" ||
      finalRoute === "daily-activities/"
    ) {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/659");
    }
  }, [finalRoute]);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setApiData(response.data.acf);
      setHeaders(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const activities = [
    {
      name: "Sea ",
      subtitle: "",
      featuredTitle: "Pools & Swim-up Bars.",
      bgImage: images[16],
      bgImageXs: images[17],
      featuredSubtitle: [
        "We are pleased to offer two pools exclusively to our owners and guests: the ",
        <b key={"Premier Pool"}>Premier Pool</b>,
        " and the ",
        <b key={"Park Pool."}>Park Pool.</b>
      ],

      route: "/activities/sea",
      routeName: "sea"
    },
    {
      name: "Land ",
      subtitle: "",
      featuredTitle: "",
      bgImage: images[14],
      bgImageXs: images[15],
      featuredSubtitle: [
        "We are pleased to offer two pools exclusively to our owners and guests: the ",
        <b key={"Premier Pool."}>Premier Pool</b>,
        " and the ",
        <b key={"Park Pool."}>Park Pool.</b>
      ],
      route: "/activities/land",
      routeName: "land"
    },
    {
      name: "Spa ",
      subtitle: "",
      featuredTitle: "Pools & Swim-up Bars.",
      bgImage: images[10],
      bgImageXs: images[11],
      featuredSubtitle: [
        "We are pleased to offer two pools exclusively to our owners and guests: the ",
        <b key={"Premier Pool."}>Premier Pool</b>,
        " and the ",
        <b key={"Park Pool."}>Park Pool.</b>
      ],
      route: "/activities/spa",
      routeName: "spa"
    },
    {
      name: "Shopping ",
      subtitle: "",
      featuredTitle: "Pools & Swim-up Bars.",
      bgImage: images[0],
      bgImageXs: images[0],
      featuredSubtitle: [
        "We are pleased to offer two pools exclusively to our owners and guests: the ",
        <b key={"Premier Pool."}>Premier Pool</b>,
        " and the ",
        <b key={"Park Pool."}>Park Pool.</b>
      ],
      route: "/activities/shopping",
      routeName: "shopping"
    },
    {
      name: "Daily Activities ",
      subtitle: "",
      featuredTitle: "Pools & Swim-up Bars.",
      bgImage: images[18],
      bgImageXs: images[19],
      featuredSubtitle: [
        "We are pleased to offer two pools exclusively to our owners and guests: the ",
        <b key={"Premier Pool."}>Premier Pool</b>,
        " and the ",
        <b key={"Park Pool."}>Park Pool.</b>
      ],
      route: "/activities/daily-activities",
      routeName: "daily-activities"
    }
  ];

  const [currentName, setCurrentName] = useState(null);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);
  const [pageIndex, setPageIndex] = useState(null);

  useEffect(() => {
    if (history.location.pathname.split("/").pop() === "") {
      setFinalRoute(parsedCurrentRoute[0]);
    } else {
      setFinalRoute(history.location.pathname.split("/").pop());
    }

    if (finalRoute !== "") {
      activities.forEach((activity, x) => {
        if (activity.route.split("/").pop() === finalRoute) {
          setCurrentName(activity.name);
          setCurrentSubtitle(activity.subtitle);
          setPageIndex(x);
        }
      });
    }
  }, [currentRoute, pageIndex, finalRoute]);

  return (
    <Suspense fallback={<div></div>}>
      {apiData ? (
        <AnimatedWrap>
          <Wrapper>
            <HeroViews
              bg={apiData.hero_image}
              subtitle={currentSubtitle}
              title={currentName}
            />
            <Navigation
              color={theme.blackColor()}
              transition={theme.defaultTransition()}
              list={activities}
              customPadding={"60px 165px 70px"}
              dineNavigationSize={"20px"}
              customMobilePadding={"40px 20px 0"}
              finalRoute={finalRoute}
            />

            {pageIndex === 4 && <DailyActivities data={activities[4]} />}
            {pageIndex === 3 && <Shopping data={activities[3]} />}
            {pageIndex === 2 && <Spa data={activities[2]} />}
            {pageIndex === 1 && <Land data={activities[1]} />}
            {pageIndex === 0 && <Sea data={activities[0]} />}
          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
