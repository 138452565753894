import styled from "styled-components";

import { XS } from "../../../utils/constants";

export const Button = styled.div`
  grid-area: button;
  height: 75px;
  width: 75px;
  padding: 1.5em;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  outline: none;
  border: none;
  transition: ${props => props.theme.defaultTransition()};
  position: relative;
  @media (min-width: 1300px) {
    display: none;
  }

  @media (max-width: ${XS}) {
    position: absolute;
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     float: right;
     z-index: 10000;
  }
  
`;
export const StyledSvg = styled.svg`
  cursor: pointer;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  z-index: 999;
`;
export const Path = styled.path`
  stroke: ${props => props.showbg === true ? props.theme.darkBlue : 'white'};
  transition: 0.2s;
`;
