import React, { useContext, Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { ThemeContext } from "styled-components";
import { Wrapper, Title, Image, TextContainer, Text } from "./style";
/* const Button = lazy(() => import("../../../components/commons/buttonGeneric")); */

import Button from "../../../components/commons/buttonGeneric"

export default props => {
  const theme = useContext(ThemeContext);
  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });
  return (
    <Suspense fallback={<div></div>}>
      {props.data ? (
        <Wrapper>
          {isMobile ? (
            <Link to={"/staycation"}>
              <Button
                title="Plan my escape"
                transition={theme.defaultTransition()}
              >
                Plan my escape
              </Button>
            </Link>
          ) : null}
          <Image bg={props.data.homepage_residents_image} />

          <TextContainer>
            <Title>{props.data.homepage_residents_title}</Title>

            <Text
              dangerouslySetInnerHTML={{
                __html: props.data.homepage_residents_text
              }}
            />
            {!isMobile ? (
              <Link to={"/staycation"}>
                <Button
                  title="Plan my escape"
                  transition={theme.defaultTransition()}
                >
                  Plan my escape
                </Button>
              </Link>
            ) : null}
          </TextContainer>
        </Wrapper>
      ) : <div></div>}
    </Suspense>
  );
};
