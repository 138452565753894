import styled from "styled-components";
import Slider from "react-slick";
import arrowLeft from "../../../assets/arrow-left.png";

import { LG, XS } from "../../../utils/constants";

export const Wrapper = styled.div`
  padding: 30px 165px 40px;

  @media (max-width: ${LG}) {
    padding: 0 20px 40px;
  }
  @media (max-width: ${XS}) {
    padding: 0 10px 60px 10px;
    margin-top: -110px;

    margin-top: ${props =>
      props.customMargin ? props.customMargin : "-110px"};
  }
`;

export const Gallery = styled(Slider)`
  .slick-arrow {
    width: 45px;
    height: 45px;
    background-color: ${props => props.color} !important;
    z-index: 800;
    border-radius: 8px;
    transition: ${props => props.theme.defaultTransition()};

    &:hover {
      background-color: #2a5074 !important;
      opacity: 1 !important;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 0;
    width: 45px;
    height: 26px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url(${arrowLeft});
  }

  .slick-next {
    bottom: 0;
    top: inherit;
    right: 2.2vw;
  }

  .slick-prev {
    top: inherit;
    bottom: 0;
    right: 2.2vw;
    left: inherit;
    margin-right: 50px;
  }

  @media (max-width: ${XS}) {
    .slick-prev {
      bottom: -80px;
      top: inherit;
      right: unset;
      left: 0;
      margin-right: 50px;
    }
    .slick-next {
      bottom: -80px;
      top: inherit;
      right: 0;
    }
  }

  .slick-next:before {
    transform: rotate(180deg);
  }
`;

export const SliderItem = styled.div`
  display: grid !important;
  grid-template-columns: 100%;
  grid-gap: 30px;
  justify-content: center;
  cursor: pointer;

  outline: none;

  .slick-slide {
    display: grid;

    div > div {
      background-color: grey;
      height: 41.66666666666667vw;
      border-radius: 20px;
    }
  }
`;

export const FirstSliderImage = styled.article`
  background: url(${props => props.bg}) no-repeat center center;
  background-size: cover;
  outline: none;
  grid-column: ${props => (props.index === 0 ? "1/3" : "")};
  border-radius: 20px;
  height: ${props =>
    props.index + 1 ? "22.22222222222222vw" : "41.66666666666667vw"};
  height: 41.66666666666667vw !important;

  @media (max-width: ${LG}) {
    height: 60vw !important;
  }

  @media (max-width: ${XS}) {
    height: 323px !important;
    border-radius: 8px;
  }
`;

export const Image = styled.div`
  background: url(${props => props.bg}) no-repeat center center;
  background-size: cover;
  outline: none;
`;

export const CounterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const CounterWrap = styled.div`
  position: relative;
  text-align: right;
  padding-right: 145px;
  padding-top: 23px;
  bottom: 91px;
  right: 5px;

  @media (max-width: ${XS}) {
    display: none;
  }
`;

export const Numbers = styled.div`
  letter-spacing: -0.5px;
  color: white;
  padding-bottom: 2px;
`;

export const CounterTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 300;
  color: white;
`;

export const ButtonsWrap = styled.div`
  button {
    color: white;
    background-color: ${props => props.bgColor};
  }
`;
