import React, {Suspense, useEffect, useState} from "react";
import { useMediaQuery } from "react-responsive";

import { Wrapper, Text } from "./style";

import {convertPhone} from "../../../utils/constants";
import axios from "axios";
import {AdressWrap, ContactEmail, Reservations, Tel} from "../../../views/contact/contactHero/style";

export default props => {

  const isMobile = useMediaQuery({
    query: "(max-device-width: 767px)"
  });

  const [info, setInfo] = useState(null);
  const [frontDeskTel, setFrontDeskTel] = useState("");
  const [frontDeskTel2, setFrontDeskTel2] = useState("");
  const [reservationTel, setReservationTel] = useState("");

  useEffect(() => {
    if (info && frontDeskTel === "") {
      setFrontDeskTel(convertPhone(info.front_desk_tel));
    }
    if (info && frontDeskTel === "") {
      setFrontDeskTel2(convertPhone(info.front_desk_tel_2));
    }
    if (info && reservationTel === "") {
      setReservationTel(convertPhone(info.reservations_tel));
    }
  }, [info, frontDeskTel, reservationTel]);
  useEffect(() => {
    getInfo(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/general_info/");
  }, []);

  async function getInfo(url) {
    try {
      const response = await axios.get(url);

      setInfo(response.data[0].acf);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {info ? (
        <Wrapper>
          <div>
            {props.data.front_desk ? (
              <Reservations>
                <h5>Front Desk: </h5>
                <Tel
                  href={`tel:${
                    info.front_desk_tel.substring(0, 1) === "+" ? "+" : null
                  }${frontDeskTel}`}
                >
                  <h3>{info.front_desk_tel} or</h3>
                </Tel>

                <Tel
                  href={`tel:${
                    info.front_desk_tel_2.substring(0, 1) === "+" ? "+" : null
                  }${frontDeskTel2}`}
                >
                  <h3>{info.front_desk_tel_2}</h3>
                </Tel>
              </Reservations>
            ) : null}
            {props.data.reservations ? (
              <Reservations>
                <h5>Reservations: </h5>
                <Tel
                  href={`tel:${
                    info.reservations_tel.substring(0, 1) === "+" ? "+" : null
                  }${reservationTel}`}
                >
                  <h3>{info.reservations_tel}</h3>
                </Tel>
              </Reservations>
            ) : null}
            {props.data.email ? (
              <ContactEmail href={`mailto:${info.mail}`}>
                {info.mail}
              </ContactEmail>
            ) : null}
            {props.data.address ? (
            <AdressWrap>
              <Text
                dangerouslySetInnerHTML={{ __html: info.address_contact }}
              />
            </AdressWrap>
            ) : null}
          </div>
        </Wrapper>
      ) : <div></div>}
    </Suspense>
  );
};
