import styled from "styled-components";

export const Wrapper = styled.div``;

export const Subtitle = styled.h3`
  color: #ffffff;
  padding-top: 20px;
  font-size: ${props => (props.isPost ? "16px" : "32px")};
  font-family: ${props => (props.isPost ? "Roboto" : "Poppins")};
  line-height: ${props => (props.isPost ? "24px" : "40px")};
  font-weight: ${props => (props.isPost ? "bold" : "300")};
  position: relative;
  z-index: 10;
`;
