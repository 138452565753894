import styled from "styled-components";
import { StyledH3 } from "../../../components/commons/secondTitle/style";
import { StyledP } from "../../../components/commons/Paragraph/style";
import Subtitle from "../../../components/commons/subtitle/";
import {
  HIGHER_RESOLUTIONS,
  LOWEST_RESOLUTIONS,
  LG
} from "../../../utils/constants";

export const Wrapper = styled.div``;

export const OnSite = styled.div`
  max-width: 1111px;
  margin: 0 160px;
  display: grid;
  grid-template-columns: 1.1fr 0.65fr;
  margin-right: 300px;

  p{
    color:${props => props.theme.darkBlue};
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
  }

  @media (max-width: ${LOWEST_RESOLUTIONS}) {
    margin-right: unset;
    margin: 0 30px;
    max-width: unset;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    margin: 0 20px;
  }
`;

export const OnSiteTitle = styled(StyledH3)`
  color: ${props => props.theme.darkBlue};
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 96px;

  @media (max-width: ${LG}) {
    margin-top: 30px;
    font-size: 40px;
  }
`;

export const OnSiteText = styled(StyledP)`
  padding-right: 50px;
  @media (max-width: ${HIGHER_RESOLUTIONS}) {
    max-width: 567px;
    padding-right: 20px;
  }
  @media (max-width: ${LG}) {
    max-width: unset;
  }
`;

export const Located = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 0 0 70px;

  h3 {
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 300;
    margin-bottom: 20px;

    span {
      font-weight: bold;
      display: block;

    }
  }

  h4 {
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 300;
    max-width: 300px;

    span {
      color: ${props => props.theme.skyBlue};
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      display:block;
    }
  }

  @media (max-width: ${LG}) {
    padding: 40px 40px;
  }
`;

export const ShoppingCenter = styled.div`
  max-width: 1111px;
  margin: 0 160px;
  padding-top: 80px;
  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    margin: 0 120px;
  }

  @media (max-width: ${LOWEST_RESOLUTIONS}) {
    margin: 0 30px;
    max-width: unset;
  }

  @media(max-width:${LG}){
    margin: 0 20px;
  }
`;

export const ShoppingTitle = styled(StyledH3)`
  color: ${props => props.theme.darkBlue};
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 96px;
  padding-bottom: 25px;

  @media (max-width: ${LG}) {
    font-size: 40px;
    line-height: 48px;
    margin-top:-40px;
  }
`;

export const ShoppingSubtitle = styled(Subtitle)`
  @media (max-width: ${LG}) {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

export const CardsContainer = styled.div`
  margin-top: 50px;
`;
