import styled from "styled-components";
import { StyledH3 } from "../../../components/commons/secondTitle/style";
import { StyledP } from "../../../components/commons/Paragraph/style";
import Subtitle from "../../../components/commons/subtitle/";
import { RoundedImage } from "../../../components/commons/borderImage/style";

import { HIGHER_RESOLUTIONS, XL, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  max-width: 1111px;
  margin: 0 auto;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    margin: 0 170px;
  }

  p {
    color: ${props => props.theme.darkBlue};
  }

  h4 {
    margin-bottom: 20px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-gap: 30px 60px;

  @media (max-width: ${XL}) {
    grid-gap: 30px;
    margin: 0 30px;
  }

  @media (max-width: ${LG}) {
    grid-gap: 30px;
    margin: 0 20px;
  }

  div {
    &:first-child {
      @media (max-width: ${LG}) {
        grid-row: 2/3;
      }
    }
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled(StyledH3)`
  color: ${props => props.theme.darkBlue};
  font-size: 80px;
  @media (max-width: ${XL}) {
    padding: 20px 80px 20px 20px;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 48px;
  }
`;

export const MainSubtitle = styled(Subtitle)``;

export const Text = styled(StyledP)`
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  color: ${props => props.theme.darkBlue};

  @media (max-width: ${LG}) {
    margin-bottom: -20px;
  }
`;

export const Picture = styled(RoundedImage)`
  @media (max-width: ${LG}) {
    margin-top: -25px;
  }
`;

export const TimeCard = styled.div`
  height: 160px;
  width: 350px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;

  span {
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: ${props => props.theme.darkBlue};
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h6 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    color: ${props => props.theme.darkBlue};
  }

  @media (max-width: ${LG}) {
    height: 120px;
    width: 100%;
    padding-left: 30px;
    margin-bottom: 40px;

    span {
      margin-top: 0;
    }
  }
`;

export const BookNow = styled.a`

display:block;
text-decoration:none;
margin-top: 30px;
margin-bottom: 30px;
 @media(max-width:${LG}){
  margin: 0 auto;
  width: 200px;
  padding-bottom: 40px;
 }

button{
  background-color:${props => props.theme.skyBlue};

  &:hover{
    background-color: #2883DA;
    box-shadow: 0 3px 13px 0 rgba(77,114,149,0.35);
}
  }
}


`;
