import React from "react";
import { Wrapper, main, secondary, btnwhite } from "./style";

export default ({ children, ...props }) => (
  <Wrapper
    as={
      props.typebtn === "main"
        ? main
        : props.typebtn === "white"
        ? btnwhite
        : secondary
    }
    {...props}
  >
    {children}
  </Wrapper>
);
