import styled from 'styled-components'

export const Wrapper = styled.div`
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 1em;
    width: 100%;
    color: white;
  /*   background: ${props => props.theme.grey()}; */
    text-align: center;
`