import { keyframes } from "styled-components";

export const ScrollDownAnimation = keyframes`
  0% {
    opacity:1;
    top:0;
  }
  100% {
    opacity:1;
    top:100%
  }
`;

export const PopAnimation = keyframes`
  0% {
    transform:scale(1);
  }
  50% {
    transform:scale(1.3);
  }
  100% {
    transform:scale(1.2);
  }

`;

export const GrowAnimation = keyframes`
  0% {
    transform:scale(0);
  }
  50% {
    transform:scale(1.3);
  }
  100% {
    transform:scale(1);
  }
`;

export const OpacityAnimation = keyframes`
0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }

`;
