import React, { useEffect, useState, useRef } from "react";

import { useMediaQuery } from "react-responsive";
import { Wrapper, PlayMask } from "./style";
import PlayIcon from "../../../assets/play-icon.svg";

export default props => {
  const [videoPlay, setVideoPlay] = useState(false);
  const playerRef = useRef();

  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  const PlayVideo = () => {
    if (!videoPlay) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    return null;
  };

  useEffect(() => {}, [videoPlay]);

  return (
    <Wrapper normalVideo={props.normalVideo}>
      <video
        autoPlay={!isMobile ? true : false}
        muted
        loop
        width="320"
        ref={playerRef}
        height="240"
        onClick={e => {
          PlayVideo(e);
          setVideoPlay(!videoPlay);
        }}
      >
        <source src={props.ActivitiesVideo} type="video/mp4" />
      </video>
      <PlayMask
        videoPlay={videoPlay}
        onClick={e => {
          PlayVideo(e);
          setVideoPlay(!videoPlay);
        }}
      >
        <div>
          <img src={PlayIcon} alt="playIcon" />
        </div>
      </PlayMask>
    </Wrapper>
  );
};
