import styled from "styled-components";

import { HIGHER_RESOLUTIONS, XL, LG } from "../../utils/constants";
import { RoundedImage } from "../../components/commons/borderImage/style";

export const Wrapper = styled.div`
  padding: 60px 165px 100px;
  display: grid;
  grid-template-columns: 115px 1fr;
  grid-column-gap: 75px;

  ul {
    padding-left: 20px;
  }

  @media (max-width: ${XL}) {
    padding: 60px 30px 100px;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
`;

export const RecommendedPost = styled.div`
  padding: 60px 165px 60px;
  background-color: ${props => props.colorfont};
`;

export const RecommendedTitle = styled.h3`
  color: ${props => props.colorfont};
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;
  padding-bottom: 50px;
`;

export const WrapperCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
`;

export const RightSection = styled.div`
  max-width: 732px;
  color: ${props => props.colorfont};

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Autor = styled.p`
  color: ${props => props.colorfont};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
`;

export const Title = styled.h2`
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  line-height: 48px;

  @media (max-width: ${LG}) {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 15px;
  }
`;

export const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  padding-top: 25px;

  p {
    padding-top: 30px;
  }
  a {
    font-weight: 600;
    color: ${props => props.theme.skyBlue};
    text-decoration: none;
  }
`;

export const Icon = styled.i`
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(31, 48, 64, 0.1);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    background-image: url(${props => props.bg});
    width: 32px;
    height: 32px;
    background-size: cover;
  }
`;

export const MobileWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 60px;
`;

export const MobileImg = styled(RoundedImage)`
  margin-top: 25px;
  margin-bottom: 5px;
  height: 323px !important;
`;

export const HeadlineMobile = styled.div`
  margin-top: 40px;
  margin-bottom: -30px;
`;
