import styled from "styled-components";
import {XL, XS, LG, HIGHER_RESOLUTIONS} from "../../../utils/constants";

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 165px 60px;

  @media (max-width: ${XL}) {
    padding: 0 60px 65px;
  }

  @media (max-width: ${XS}) {
    padding: 0 20px 20px;
    margin-top: -10px;
  }
  
  .vt-popup-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #949494b8;
    z-index: 1000;
  }
  
  .vt-close {
    position: fixed;
    top: 5%;
    right: 5%;
    margin-top: -12px;
    margin-right: -10px;
    font-size: 42px;
    cursor: pointer;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    border-radius: 50px;
    transition: 250ms background;
    z-index: 1002;
    
    &:hover {
      background: #e0e0e0;        
    }
  }
  
  .vt-popup {
    position: fixed;
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;
    background: rgb(33, 33, 33);
    border-radius: 20px;
    z-index: 1001;
    overflow: hidden;
    // border: 1px solid white;
    
    iframe {
      border: 0px solid transparent !important;
      margin: 0;
      padding: 0;
      outline: none;
    }
    
  }
  
`;

export const MobileCardsWrap = styled.div`

padding-top:15px;

`;


export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;

  a, .vt-tour {
    text-decoration: none;
    width: 100%;
    display: flex;
    position: relative;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  > div {
    border-radius: 20px;
    overflow: hidden;
  }
  
  .cardWide {
      grid-column-start: 1;
      grid-column-end: 3;
      background: red !important;
      min-height: 100px;
  }
  .cardNormal {
    background: green !important;
    min-height: 100px;
  }
  
  .vt-card-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: 250ms background;
      background: transparent;
      z-index: 100;
      
      &:hover {
        background: #0039614d;
      }
      
      > div {
        margin-bottom: 20px;
        font-family: Poppins;
        font-weight: bold;
        font-size: 37px;
        text-align: center;
        line-height: 42px;
        font-weight: 700;
        color: white;
        padding: 0 20px;
      }
      
      button {
        height: 50px;
        width: auto;
        padding: 0px 40px;
        border-radius: 25px;
        background-color: rgb(0, 109, 188);
        cursor: pointer;
        color: white;
        font-family: roboto;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        transition: all 0.3s cubic-bezier(0.15, 0.82, 1, 1) 0s;
        border: 0px;
        outline: none !important;
    }
  }
  
  @media (max-width: 999px) {
    grid-gap: 70px;
    
    .vt-card-overlay > div {
      font-size: 27px;
      line-height: 30px;
      padding: 0 15px;
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: ${XS}) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    
    .cardNormal,
    .cardWide {
      margin-bottom: 50px;
    }
    
    .vt-tour {
      min-height: 35vh;
      
      img {
        object-fit: cover;
      }
    }
  }
  
  @media (max-width: 500px) {
    grid-gap: 0;
    
    .cardNormal,
    .cardWide {
      margin-bottom: 20px;
    }
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     display: flex;
     width: 100%;
     justify-content: space-between;
     
     > a,
     > .vt-tour{
      width: 32%;
     }
  }
  @media screen and (max-width: 699px) and (-ms-high-contrast: active), screen and (max-width: 699px) and (-ms-high-contrast: none) {  
     display: block;
  }
  
  
   /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
     display: block;
       
     .cardWide,
     .cardNormal {
        display: block;
        width: 50%;
        margin: 20px auto;
        
        .vt-tour {
          border-radius: 20px;
          overflow: hidden;
        }
        
        .vt-card-overlay {
          > div {
            max-width: 100%;
          }
        }
     }
     .cardWide {
        width: 100%;
     }
  }
  // @media screen and (max-width: 699px) and (-ms-high-contrast: active), screen and (max-width: 699px) and (-ms-high-contrast: none) {  
  //    display: block;
  // }
  
`;

// LEES NEW

export const ThumbNormal = styled.div`
  height: 385px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  border-radius: 20px;
  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    height: 23.819444444444443vw;
  }

  @media (max-width: ${LG}) {
    height: 323px;
    margin-bottom: 30px;
  }
`;