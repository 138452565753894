import styled from "styled-components";

import { StyledH3 } from "../../components/commons/secondTitle/style";
import { StyledP } from "../../components/commons/Paragraph/style";
import { RoundedImage } from "../../components/commons/borderImage/style";
import { LG } from "../../utils/constants";

export const Wrapper = styled.div`
  padding-bottom: 120px;
`;

export const SliderWrap = styled.div`
  margin-top: 50px;

  @media (max-width: ${LG}) {
    > div {
      &:first-child {
        padding: 0 0 60px 0;
        margin: 50px -5px 0;
      }
    }
  }
`;

export const MobileWrap = styled.div`
  margin: 0 20px;

  p{
    padding-bottom:10px;
    padding-top:10px;
  }
`;

export const TextMobile = styled(StyledP)`
  padding-top: 30px;
  padding-bottom: 20px;
`;

export const TitleMobile = styled(StyledH3)`
  font-family: Poppins;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  color: ${props => props.theme.darkBlue};
  margin-top: 20px;
  padding-bottom:0 !important;
`;

export const SubtitleMobile = styled.h3`
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  color: ${props => props.theme.darkBlue};
  font-weight: 300;
  margin-bottom: 20px;

  @media (max-width: ${LG}) {
    margin-top: 20px;
    margin-bottom: 0;
  }

  a{
    color:${props => props.theme.skyBlue};
    text-decoration:none;

  }
`;

export const RoundedMobilePic = styled(RoundedImage)``;

export const MobileFullPicture = styled.div`
  height: 323px;
  background: url(${props => props.bg}) no-repeat center center / cover;
  margin: 0 -20px;
`;
