import styled from "styled-components";
import { Link } from "react-router-dom";
import { LG, XS } from "../../utils/constants";

export const Wrapper = styled.footer`
  font-family: roboto;
  padding: 60px 165px;
  background-color: ${props => props.color};

  @media (max-width: ${LG}) {
    padding: 60px 40px;
  }

  @media (max-width: ${XS}) {
    margin-top: ${props => (props.contactMargin ? "0" : "60px")};
    padding: 0 40px 20px 40px;
  }
`;

export const LogoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
`;

export const LogoFooter = styled.div``;

export const HeadTel = styled.a`
  font-family: poppins;
  justify-self: flex-end;
  font-weight: 600;
  color: white;
  font-size: 32px;
  line-height: 32px;
  text-decoration:none;

  
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  @media (max-width: ${XS}) {
    display: grid;
    grid-gap: 30px;
  }
`;

export const List = styled.ul`
  padding-left: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  line-height: 32px;
  max-width: 240px;

  a {
    text-decoration: none;
    color: white;
    transition: ${props => props.theme.defaultTransition()};

    :hover {
      opacity: 0.7;
    }
  }

  span {
    display: inline-block;
    
  }
`;

export const LinkRouter = styled(Link)`
  text-decoration: none;
  color: white;
  transition: ${props => props.theme.defaultTransition()};

  :hover {
    opacity: 0.7;
  }
`;
export const CustomLink = styled.a`
  text-decoration: none;
  color: white;
  transition: ${props => props.theme.defaultTransition()};

  :hover {
    opacity: 0.7;
  }
`;
export const NoLink = styled.span`
  display: inline-block;
`;

export const SocialImage = styled.img`
  margin-bottom: 5px;
`;

export const Copyright = styled.p`
  margin-top: 30px;
  color: #ffffff;
  opacity: 0.4;
`;

export const SocialMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;

  li {
    margin-left: 15px;

    transition: ${props => props.theme.defaultTransition()};

    :hover {
      opacity: 0.7;
    }
  }
`;

export const PlaceLogo = styled.div`
  position: relative;
  left: 10px;
  @media (max-width: ${XS}) {
    position: relative;
    right: 15px;
    left: unset;
  }
`;
