import styled from 'styled-components'

export const Title = styled.h1`
	font-family: Poppins;
	color: ${({color}) => color || '#FFFFFF'};
	font-size: ${({fontSize}) => fontSize || '80px'};
	line-height: ${({lineHeight}) => lineHeight || '96px'};
	max-width: ${({maxWidth}) => maxWidth || '825px'};
	font-weight: bold;
	text-align: left;
	letter-spacing: 0;
    text-shadow: 0 0 0.5rem #00000042;
`