import styled from "styled-components";

import { HIGHER_RESOLUTIONS, XS } from "../../../utils/constants";
import { StyledH3 } from "../secondTitle/style";
import { RoundedImage } from "../borderImage/style";
import { StyledP } from "../Paragraph/style";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 123px;
  margin-right: 80px;

  @media (max-width: ${XS}) {
    flex-direction: column-reverse;
    margin: 0;
    padding: 0 20px;
  }
  
  @media (max-width: 999px) {
    margin-bottom: 50px;
  }
  
  /* IE10+ specific styles */
  @media screen and (max-width: 767px) and (-ms-high-contrast: active), screen and (max-width: 767px) and (-ms-high-contrast: none) {  
     margin: 40px 0 0;
  }
`;

export const Title = styled(StyledH3)`
  color: ${props => props.theme.darkBlue};
  margin-bottom: 0;
`;

export const Image = styled(RoundedImage)`
  max-width: 515px;
  margin-right: 125px;
  margin-top: 20px;

  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: 800px;
    min-height: 500px;
    height: auto;
  }

  @media (max-width: ${XS}) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-top: 30px;
    margin-bottom:30px;
    height:323px;
  }
  
  /* IE10+ specific styles */
  @media screen and (max-width: 767px) and (-ms-high-contrast: active), screen and (max-width: 767px) and (-ms-high-contrast: none) {  
     margin: 20px 0;
  }
`;

export const TextContainer = styled.div`
  text-align: left;
/*   max-width: 672px; */
  width: 100%;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    margin-right: 30px;
  } 
`;

export const Text = styled(StyledP)`
  max-width: 620px;
  color: ${props => props.theme.darkBlue};
  margin-bottom: 20px;
  
  h3 {
    width: 100%;
    color: rgb(31, 48, 64);
    font-family: Poppins;
    font-weight: bold;
    text-align: left;
    
    font-size: 37px;
    line-height: 42px;
    font-weight: 700;
  }
  
  ol {
    margin-left: 20px;
  }

  li {
    padding-left: 10px;  
  }
  li::marker {
    color: #016EBC;
    font-weight: 700;
  }
  
  // @media (max-width: ${XS}) {
  //   margin-bottom: 50px;  
  // }
`;


export const TextInfo = styled(StyledP)`
  max-width: 620px;
  color: ${props => props.theme.darkBlue};
  margin-bottom: 0;
  padding-bottom: 0;
  
  p {
    font-size: 24px;
    line-height: 31px;
    font-weight: 300;
  }
  
  h3 {
    width: 100%;
    color: rgb(31, 48, 64);
    font-family: Poppins;
    font-weight: bold;
    text-align: left;
    
    font-size: 37px;
    line-height: 42px;
    font-weight: 700;
  }
  
  ol {
    margin-left: 20px;
  }

  li {
    padding-left: 10px;  
  }
  li::marker {
    color: #016EBC;
    font-weight: 700;
  }
`;
