import React, { Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Wrapper,
  Item,
  TextContent,
  HoursWrap,
  Image,
  HoursContent,
  Disclamer,
  MobilePic
} from "./style";

/* const Paragraph = lazy(() =>
  import("../../../../components/commons/Paragraph")
); */

import Paragraph from "../../../../components/commons/Paragraph";

const Card = props => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 992px)"
  });

  return (
    <Suspense>
      <Wrapper>
        <Item>
          <Image bg={props.data.image}></Image>
          <TextContent>
            <h3>{props.data.title} </h3>
            <h4>{props.data.subtitle}</h4>
            {isMobile && <MobilePic bg={props.data.imageXs} />}

            <Paragraph text={props.data.text} />
          </TextContent>
          <HoursWrap>
            <HoursContent>
              <h4>
                {props.data.days1}
                <span>{props.data.hours1}</span>
              </h4>
            </HoursContent>
            <HoursContent>
              <h4>
                {props.data.days2}
                <span>{props.data.hours2}</span>
              </h4>
            </HoursContent>
            <p>{props.data.closed}</p>
          </HoursWrap>
          {props.data.disclaimer !== null ? (
            <Disclamer>
              <em>*</em>
              <p dangerouslySetInnerHTML={{ __html: props.data.disclaimer }} />
            </Disclamer>
          ) : null}
        </Item>
      </Wrapper>
    </Suspense>
  );
};

export default Card;
