import React, { useState, Fragment } from "react";
import {
  Navs,
  Submenu,
  Section,
  StyledNavlink,
  SubmenuLink,
  Overlay
} from "./style";
import routes from "../../../views/routes";

import NavPic1 from "../../../assets/the-londoner-menu.png";
import NavPic2 from "../../../assets/morritts-nav/nav2.png";
import NavPic3 from "../../../assets/morritts-nav/nav1.png";
import NavPic4 from "../../../assets/dropdown-activities/shopping-xs.jpg";

import DinePic1 from "../../../assets/dine/nav/david.png";
import DinePic2 from "../../../assets/dine/nav/mimi.png";
import DinePic3 from "../../../assets/dine/nav/georgie.png";
import DinePic4 from "../../../assets/dine/nav/ivan.png";
import DinePic5 from "../../../assets/dine/nav/pool.jpg";

import Activities1 from "../../../assets/dropdown-activities/sea-xs.jpg";
import Activities2 from "../../../assets/dropdown-activities/land-xs.jpg";
import Activities3 from "../../../assets/dropdown-activities/spa-xs.jpg";
import Activities4 from "../../../assets/dropdown-activities/shopping-xs.jpg";
import Activities5 from "../../../assets/dropdown-activities/daily-xs.jpg";

import VacationClub1 from "../../../assets/vacation-club-header/Ownership&Benefits2x.png"
import VacationClub3 from "../../../assets/vacation-club-header/Owner_Login2x.png"
import VacationClub2 from "../../../assets/vacation-club-header/News&Updates2x.png"


const navImages = [NavPic1, NavPic2, NavPic3, NavPic4];

const dineNavImg = [DinePic1, DinePic2, DinePic3, DinePic4, DinePic5];

const ActivitiesNav = [
  Activities1,
  Activities2,
  Activities3,
  Activities4,
  Activities5
];

const VacationDropdown = [
  VacationClub1,
  '',
  VacationClub2
]
 
export default ({ isDesk, location, isHidden, showbg }) => {
  const [openedMenu, setOpenedMenu] = useState(null);
  const [showOverlay, setShowOverlay] = useState(null);
  const openSubMenu = i => {
    setOpenedMenu(i);
    setShowOverlay(true);
  };
  const closeSubMenu = () => {
    setOpenedMenu(null);
    setShowOverlay(false);
  };

  const locationSplit = location.split("/");

  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

  return (
    <Navs isDesk={isDesk} isHidden={isHidden} showbg={showbg}>
      {routes
        .filter(r => !r.hasOwnProperty("sectionId"))
        .map((route, i) => {
          if (route.isSection) {
            let subRoutes = routes.filter(
              r => r.hasOwnProperty("sectionId") && r.parentId === route.id
            );
            if (subRoutes.length) {
              return (
                <React.Fragment key={i}>

                  <Overlay
                    show={showOverlay}
                    onMouseEnter={() => closeSubMenu()}

                  />

                  <Section onMouseEnter={() => openSubMenu(i)} onMouseLeave={() => {
                    if (isIE11) {
                      closeSubMenu()
                    }
                  }}>

                    <StyledNavlink
                      to={route.path}
                      selected={
                        route.path.replace("/", "") === location ||
                        locationSplit[0] === route.path.replace("/", "")
                      }
                      onClick={() => closeSubMenu()}
                    >
                      {" "}
                      {route.label}
                    </StyledNavlink>


                    <Submenu show={openedMenu === i} onClick={() => closeSubMenu()}>
                      {route.label === "Stay" && (
                        <Fragment>
                          {subRoutes.map((subRoute, k) => (
                            <SubmenuLink
                              key={k}
                              exact={subRoute.exact}
                              to={subRoute.path}

                            >
                              <img src={navImages[k]} alt={subRoute.label}/>
                              <span>{subRoute.label}</span>
                            </SubmenuLink>
                          ))}
                        </Fragment>
                      )}
                      {route.label === "Dine" && (
                        <Fragment>
                          {subRoutes.map((subRoute, k) => (
                            <SubmenuLink
                              key={k}
                              exact={subRoute.exact}
                              to={subRoute.path}
                            >
                              <img src={dineNavImg[k]} alt={subRoute.label}/>
                              <span>{subRoute.label}</span>
                            </SubmenuLink>
                          ))}
                        </Fragment>
                      )}
                      {route.label === "Activities" && (
                        <Fragment>
                          {subRoutes.map((subRoute, z) => (
                            <SubmenuLink
                              key={z}
                              exact={subRoute.exact}
                              to={subRoute.path}
                            >
                              <img
                                src={ActivitiesNav[z]}
                                alt={subRoute.label}
                              />
                              <span>{subRoute.label}</span>
                            </SubmenuLink>
                          ))}
                        </Fragment>
                      )}
                      {route.label === "Vacation Club" && (
                        <Fragment>
                          {subRoutes.map((subRoute, g) => (
                            <Fragment key={g}>
                              {subRoute.label === "Owner Login" ? (
                                <SubmenuLink
                                  to={{
                                    pathname: process.env.REACT_APP_CUSTOMER_PORTAL
                                  }}
                                  target="_blank"
                                >
                                  <img
                                    src={VacationClub3}
                                    alt={subRoute.label}
                                  />
                                  <span>{subRoute.label}</span>
                                </SubmenuLink>
                              ) : (
                                <SubmenuLink
                                  key={g}
                                  exact={subRoute.exact}
                                  to={subRoute.path}
                                  customgrid={'90px 100px'}
                                >

                                  <img
                                    src={VacationDropdown[g]}
                                    alt={subRoute.label}
                                  />
                                  <span>{subRoute.label}</span>
                                </SubmenuLink>
                              )}
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                    </Submenu>
                  </Section>
                </React.Fragment>
              );
            } else return null;
          } else {
            return (
              <Fragment key={i}>
                {route.mobile !== true && !route.noMainNav && (
                  <StyledNavlink
                    exact={route.exact}
                    to={route.path}
                    selected={route.path.replace("/", "") === location}
                  >
                    {route.label}
                  </StyledNavlink>
                )}
              </Fragment>
            );
          }
        })}
    </Navs>
  );
};
