import styled from "styled-components";
import { XS } from "../../../utils/constants";

export const Wrapper = styled.div``;

export const StyledH3 = styled.h3`
  width: 100%;
  font-size: 64px;
  padding-bottom: 40px;
  line-height: ${props => (props.lineHeight ? props.lineHeight : "80px")};
  color: ${props => (props.color ? props.color : "")};
  font-family: Poppins;
  font-weight: bold;
  letter-spacing: 1px;
  max-width: ${props => props.maxWidth};
  text-align: ${props => props.textAlign};

  @media (max-width: 1320px) {
    font-size: 34px;
    line-height: 50px;
    max-width: ${props => (props.maxWidth ? "500px" : "unset")};
  }

  @media (max-width: ${XS}) {
    font-size: 32px;
    line-height: 42px;
    text-align: left;
    padding-bottom: 15px;
  }
`;
