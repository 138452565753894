import React, { useContext, Fragment, useState } from "react";
import { ThemeContext } from "styled-components";

// STYLES
import { Wrapper, Image, Title, Text, Footer, Date, ViewMore } from "./style";
import { AnimatedWrap } from "../AnimatedComponents";

export default props => {
  const theme = useContext(ThemeContext);

  const handleMouseDown = e => {
    setClientX(e.clientX);
  };

  const [clientX, setClientX] = useState(0);

  return (
    <Fragment>
      {props.post && props.post.acf && (
        <AnimatedWrap>
          {props.sectionName === "blog" ? (
            <Wrapper
              to={
                props.newsRoute
                  ? props.newsRoute + props.post.slug
                  : "/blog/" + props.post.slug
              }
              onMouseDown={handleMouseDown}
              onClick={e => {
                const variation = e.clientX - clientX;
                if (variation > -10 && variation < 10) {
                } else {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            >
              <Image
                image={props.post.acf && props.post.acf.blog_post_image}
              ></Image>
              <Title color={theme.blackColor()}>
                {props.post.title.rendered}
              </Title>
              <Text
                color={theme.blackColor()}
                dangerouslySetInnerHTML={{
                  __html: props.post.acf.blog_post_description
                }}
              />

              <Footer>
                <Date color={theme.blackColor()}>
                  {props.post.acf.blog_post_date}
                </Date>
                <ViewMore color={theme.blackColor()}>View more</ViewMore>
              </Footer>
            </Wrapper>
          ) : (
            <Wrapper
              to={
                props.newsRoute
                  ? props.newsRoute + props.post.slug
                  : "/vacation-club/news-n-updates/" + props.post.slug
              }
              onMouseDown={handleMouseDown}
              onClick={e => {
                const variation = e.clientX - clientX;
                if (variation > -10 && variation < 10) {
                } else {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            >
              <Image
                image={props.post.acf && props.post.acf.blog_post_image}
              ></Image>
              <Title color={theme.blackColor()}>
                {props.post.title.rendered}
              </Title>
              <Text
                color={theme.blackColor()}
                dangerouslySetInnerHTML={{
                  __html: props.post.acf.blog_post_description
                }}
              />

              <Footer>
                <Date color={theme.blackColor()}>
                  {props.post.acf.blog_post_date}
                </Date>
                <ViewMore color={theme.blackColor()}>View more</ViewMore>
              </Footer>
            </Wrapper>
          )}
        </AnimatedWrap>
      )}
    </Fragment>
  );
};
