import styled from "styled-components";

export const Wrapper = styled.div`
/*   display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;

  > div {
  /*   border: 3px solid blue; */
  }
`;
