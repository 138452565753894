import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

export const fade = keyframes`
  0% {
      visibility: hidden;
      opacity: 0;
  }
  1% {
      visibility: visible;
      opacity: 0;
  }
  100% {
      visibility: visible;
      opacity: 1;
  }
`;
export const Navs = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 100px;
  overflow: scroll;

  display: ${props => (props.menuOpen ? "flex" : "none")};
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     overflow: auto;
  }
`;
export const Overlay = styled.button``;
export const Section = styled.button`
  background: transparent;
  border: 0;
  outline: 0 !important;
  text-align: left;

  &:focus {
    outline: 0;
  }
`;

export const ImageWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageArrow = styled.img`
  margin-left: 10px;
  visibility: ${props =>
    props.openedMenu === props.index ? "visible" : "hidden"};
  opacity: ${props => (props.openedMenu === props.index ? "1" : "0")};
`;

export const Submenu = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props =>
    props.openedMenu === props.index ? props.elemHeight : "0"};
  visibility: ${props =>
    props.openedMenu === props.index ? "visible" : "hidden"};
  opacity: ${props => (props.openedMenu === props.index ? "1" : "0")};
  transition: ${props => props.theme.defaultTransition()};
  margin-bottom: 0px;
  margin-left: 40px;
  a {
    color: #ffffff;
    font-family: Roboto;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-decoration: none;
  }
`;
export const SubmenuLink = styled(NavLink)`
  margin-bottom: 7px;
`;

export const ButtonToggle = styled.div`
  color: #ffffff;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-decoration: none;
  margin-bottom: 7px;
`;

export const StyledNavlink = styled(NavLink)`
  color: #ffffff;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-decoration: none;
  margin-bottom: 7px;
`;
