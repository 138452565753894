import styled from "styled-components";

import { LG } from "../../utils/constants";

export const Wrapper = styled.div``;

export const ColumnsWrap = styled.div`
  margin-top: 60px;
  margin-bottom: 40px;

  @media (max-width: ${LG}) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
