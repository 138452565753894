import React, { Suspense, useContext, useState, useEffect } from "react";
import { Wrapper, InternalWrapper } from "./style";
import { ThemeContext } from "styled-components";
import { createBrowserHistory } from "history";
import axios from "axios";
import { setHeaders } from "../../utils/setHeaders";
import images from "./images.js";

import StickyImageTextRow from "../../components/commons/stickyImageTextRow/stickyImageTextRow";
import VirtualTours from "./VirtualTours";

/* const HeroViews = lazy(() => import("../../components/commons/heroViews"));
const UpperList = lazy(() => import("../../components/commons/upperList"));
const HotelFeatures = lazy(() =>
  import("../../components/commons/hotelFeatures")
);
const MainSlider = lazy(() => import("../../components/commons/mainSlider/")); */

import HeroViews from "../../components/commons/heroViews"
import UpperList from "../../components/commons/upperList"
import HotelFeatures from "../../components/commons/hotelFeatures"
import MainSlider from "../../components/commons/mainSlider"

export default () => {
  const theme = useContext(ThemeContext);

  const [currentName, setCurrentName] = useState(null);
  const [currentSubtitle, setCurrentSubtitle] = useState(null);
  const [currentHeroMobile, setCurrentHeroMobile] = useState(null);
  const [features, setFeatures] = useState({
    title: "",
    subtitle: "",
    text: ""
  });

  const [specs, setCurrentSpecs] = useState([]);
  const [specsHeadline, setSpecsHeadline] = useState();
  const [data, setData] = useState(null);
  const [apiFeatures, setApiFeatures] = useState([]);
  const [oceanFeatures, setOceanFeatures] = useState([]);

  const history = createBrowserHistory();

  const currentRoute = history.location.pathname.split("/");
  const parsedCurrentRoute = currentRoute.slice(currentRoute.length - 2);

  const [finalRoute, setFinalRoute] = useState("");

  useEffect(() => {
    if (finalRoute === "the-londoner") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/241");
      setSpecsHeadline({
        title: "The Londoner Features",
        subtitle: "",
        tortuga: false
      });
    }
    if (finalRoute === "grand-resort") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/280");
      setSpecsHeadline({
        title: "Grand Resort features",
        subtitle: "",
        tortuga: false
      });
    }
    if (finalRoute === "tortuga-club") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/310");

      setSpecsHeadline(
        {
          title: "Poolside Villages",
          subtitle:
            "These villas provide tropical pool and garden views, and feature: ",
          tortuga: true
        },
        {
          title: "Ocean Front Suites",
          subtitle:
            "These suites offer stunning views of turquoise waters and sun-bleached sands, featuring:",
          tortuga: true
        }
      );
    }
    if (finalRoute === "virtual-tours") {
      fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/1873");
      setSpecsHeadline({
        title: "Virtual Tours features",
        subtitle: "",
        tortuga: false
      });
    }
  }, [finalRoute]);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      setData(response.data.acf);
      setHeaders(response.data, history.location.pathname);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchFeatures(url) {
    try {
      const response = await axios.get(url);
      setApiFeatures(apiFeatures => [...apiFeatures, response.data]);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchOceanFeatures(url) {
    try {
      const response = await axios.get(url);
      setOceanFeatures(oceanFeatures => [...oceanFeatures, response.data]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (data && data.features && apiFeatures < data.features.length) {
      data.features.forEach(item => {
        fetchFeatures(
          process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/features/" + item.ID
        );
      });
    }
    if (finalRoute === "tortuga-club" || finalRoute === "tortuga-club/") {
      if (data && data.ocean_front_suites_features.length && oceanFeatures < data.ocean_front_suites_features.length) {
        data.ocean_front_suites_features.forEach(item => {
          fetchOceanFeatures(
            process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/features/" + item.ID
          );
        });
      }
    }
  }, [data, finalRoute]);

  let hotels = [
    {
      name: "The Londoner ",
      route: "/stay/the-londoner",
      routeName: "the-londoner",
      features: {
        title: "The Londoner ",
        aditionalText: "",
        specs: [
          {
            title: "The Londoner features",
            subtitle: ""
          }
        ]
      }
    },
    {
      name: "Grand Resort ",
      route: "/stay/grand-resort",
      routeName: "grand-resort",
      features: {
        title: "Grand Resort ",
        aditionalText: "",
        specs: [
          {
            title: "Grand Resort features",
            subtitle: ""
          }
        ]
      }
    },
    {
      name: "Tortuga Club ",
      route: "/stay/tortuga-club",
      routeName: "tortuga-club",
      subtitle: "",
      hero: images[0].TortugaHero,
      heroMobile: images[0].TortugaHeroXs,
      mainImage: images[0].TortugaRounded,
      features: {
        title: "Tortuga Club ",
        specs: [
          {
            title: "Poolside Villas",
            subtitle:
              "These villas provide tropical pool and garden views, and feature:"
          },
          {
            title: "Ocean Front Suites",
            subtitle:
              "These suites offer stunning views of turquoise waters and sun-bleached sands, featuring:"
          }
        ]
      }
    },
    {
      name: "Virtual Tours ",
      route: "/stay/virtual-tours",
      routeName: "virtual-tours",
      features: {
        title: "Virtual Tours ",
        aditionalText: "",
      }
    },
  ];

  useEffect(() => {
    if (history.location.pathname.split("/").pop() === "") {
      setFinalRoute(parsedCurrentRoute[0]);
    } else {
      setFinalRoute(history.location.pathname.split("/").pop());
    }

    if (finalRoute !== "") {
      hotels.forEach((hotel, x) => {
        if (hotel.routeName === finalRoute) {
          setCurrentName(hotel.name);
          setCurrentSubtitle(hotel.subtitle);
          setCurrentHeroMobile(hotel.heroMobile);
          setFeatures({
            title: hotel.features.title,
            subtitle: hotel.features.subtitle,
            text: hotel.features.text,
            aditionalText: hotel.features.aditionalText
          });
          setCurrentSpecs([
            {
              specs: hotel.features.specs
            }
          ]);
        }
      });
    }
  }, [finalRoute]);

  // VIRTUAL TOUR DATA
  // --------------------------------------------------------
  var intro_data = {}, outro_data = {}, tours = {}
  if (data && data.intro) {
    intro_data = {
      image_left: true,
      side_image: data.intro.image,
      title: data.intro.title,
      text: data.intro.text,
      show_info: false
    }
    outro_data = {
      image_left: false,
      side_image: data.outro.image,
      title: data.outro.title,
      text: data.outro.text,
      show_info: true
    }
    tours = data.tours

    // console.log('TOURS: ', tours);
  }
  // --------------------------------------------------------


  // console.log('HIER', intro_data);

  return (
    <Suspense fallback={<div></div>}>
      {data && data.hotel_right_image && specs ? (
        <Wrapper>
          <HeroViews
            bg={data.hotel_hero_image}
            title={currentName}
            subtitle={currentSubtitle}
            bgMobile={currentHeroMobile}
          />

          <UpperList
            color={theme.blackColor()}
            transition={theme.defaultTransition()}
            list={hotels}
            finalRoute={finalRoute}
          />

          <HotelFeatures
            data={data}
            specs={apiFeatures}
            color={theme.blackColor()}
            mainImage={data.hotel_right_image}
            specsHeadline={specsHeadline}
            poolSideVillage={apiFeatures}
            oceanFrontSuites={oceanFeatures}
          />

          <MainSlider
            images={data.hotel_gallery}
            title={features.title}
            color={theme.blackColor()}
            customMargin={"auto"}
          />
        </Wrapper>
      ) : (
        <div>
          <Wrapper>
            {data ? (
              <div>

                <HeroViews
                  bg={data.hotel_hero_image}
                  title={currentName}
                  subtitle={currentSubtitle}
                  bgMobile={currentHeroMobile}
                />

                <UpperList
                  color={theme.blackColor()}
                  transition={theme.defaultTransition()}
                  list={hotels}
                  finalRoute={finalRoute}
                />

                {data.subtitle ? (
                  <InternalWrapper>
                    <h2 dangerouslySetInnerHTML={{
                      __html: data.subtitle
                    }}></h2>
                  </InternalWrapper>
                ) : null}

                {data.subtext ? (
                  <InternalWrapper>
                    <div dangerouslySetInnerHTML={{
                      __html: data.subtext
                    }}></div>
                  </InternalWrapper>
                ) : null}

                {intro_data && <StickyImageTextRow data={intro_data} />}

                {tours && <VirtualTours data={tours} />}

                {outro_data && <StickyImageTextRow data={outro_data} />}

              </div>
            ) : (<div></div>)}
          </Wrapper>
        </div>
      )}
    </Suspense>
  );
};
