import dotenv from 'dotenv/config'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./redux/store";
import App from "./app";
const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <BrowserRouter>
      <ReduxProvider store={reduxStore}>
        <App />
      </ReduxProvider>
    </BrowserRouter>,
    rootElement
  );
} else {
  ReactDOM.render(
    <BrowserRouter>
      <ReduxProvider store={reduxStore}>
        <App />
      </ReduxProvider>
    </BrowserRouter>,
    rootElement
  );
}
