import styled from "styled-components";

import { RoundedImage } from "../../../components/commons/borderImage/style";
import { StyledH3 } from "../../../components/commons/secondTitle/style";
import { StyledP } from "../../../components/commons/Paragraph/style";
import { Subtitle } from "../../../components/commons/subtitle/style";
import { NavLink } from "react-router-dom";
import { HIGHER_RESOLUTIONS, XL, XS, LG } from "../../../utils/constants";

export const Wrapper = styled.div`
  margin-top: 80px;
`;

export const BorderImage = styled(RoundedImage)`
  max-width: 515px;
  height: 900px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  grid-area: auto;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
    height:600px;
  }

  

  @media (max-width: ${XS}) {
    height: 300px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: -15px;
  }
`;

export const TextContainerResident = styled.div`
  margin: 0 100px 0 120px;
  max-width: 638px;

  a{
    text-decoration:none;
    font-weight:bold;
    color:${props => props.theme.skyBlue};
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
  }
  @media (max-width: ${XL}) {
    grid-template-columns: 0.8fr 1fr;
    max-width: unset;
    margin: 0 60px 0 60px;
  }
  @media (max-width: ${LG}) {
    margin: 0 20px;
  }
`;

export const Title = styled(StyledH3)`
  color: ${props => props.theme.darkBlue};
  font-size: 80px;
  line-height: 96px;
  margin-top: -15px;

  @media (max-width: ${XS}) {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: -50px;
    margin-bottom: 0;
  }
`;

export const Paragraph = styled(StyledP)`
  color: ${props => props.theme.darkBlue};

  @media (max-width: ${XS}) {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
  }
`;

export const SubtitleMain = styled(Subtitle)`
  margin-bottom: 35px;

  @media (max-width: ${XS}) {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: normal;
  }
`;

export const ResidentGrid = styled.div`
  display: grid;
  grid-template-columns: 515px 1fr;

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    grid-template-columns: 0.6fr 1fr;
  }

  @media (max-width: ${XL}) {
    grid-template-columns: 0.8fr 1fr;
    padding-right: 80px;
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 0.6fr 1fr;
    grid-gap:20px;
    padding-right:initial;
  }
  @media (max-width: ${XS}) {
    grid-template-columns: 1fr;
  }
`;

export const TextLink = styled(NavLink)`
  color: ${props => props.theme.skyBlue};
  font-weight: 600;
  text-decoration: none;
`;
