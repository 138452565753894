import React, { Suspense, lazy, useEffect, useState, Fragment } from "react";
import axios from "axios";

// STYLES
import { Wrapper, WrapperParagraph, ParaWrapper } from "./style";
import { useMediaQuery } from "react-responsive";

import { AnimatedWrap } from "../../components/commons/AnimatedComponents";
import {setHeaders} from "../../utils/setHeaders"

// COMPONENTS
import HeroViews from "../../components/commons/heroViews"
import Paragraph from "../../components/commons/Paragraph/paragraph";

export default () => {

  const [data, setData] = useState(null);


  useEffect(() => {
    document.body.style.overflowAnchor = "none";
  }, []);

  useEffect(() => {
    fetchData(process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_WP_REST_URL + "/wp/v2/pages/977");
  }, []);

  async function fetchData(url) {
    try {
      const response = await axios.get(url);
      response.data.acf.content = response.data.content.rendered
      setData(response.data.acf);
      setHeaders(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      {data ? (
        <AnimatedWrap>
          <Wrapper>
            <HeroViews
              bg={data.hero_image}
              title={data.title}
              customHeight={"500px"}
              loginButton
              logintop={"220px"}
            />

            <WrapperParagraph>

              <Fragment>
                <ParaWrapper>
                  <Paragraph text={data.content}></Paragraph>
                </ParaWrapper>
              </Fragment>

            </WrapperParagraph>

          </Wrapper>
        </AnimatedWrap>
      ) : (
        <div></div>
      )}
    </Suspense>
  );
};
