import styled from "styled-components";
import Subtitle from "../../components/commons/subtitle";
import { HIGHER_RESOLUTIONS, XL, LG } from "../../utils/constants";
import { StyledP } from "../../components/commons/Paragraph/style";

export const Wrapper = styled.div``;
export const Container = styled.div`
  padding: 60px 0 60px 160px;
  display: grid;
  grid-template-columns: 0.82fr 1fr;
  column-gap: 39px;

  @media (max-width: ${XL}) {
    padding: 60px 0 60px 30px;
    grid-template-columns: 1fr;
  }
`;
export const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  color: ${props => props.darkBlue};
  margin: 0;

  p {
    padding-bottom: 30px;
  }
`;
export const Image = styled.img`
  border: 1px solid transparent;
  border-radius: 16px 0 0 16px;
  width: 100%;
  height: 40.27777777777778vw;
  border: 0;
  background: url(${props => props.image}) no-repeat center center / cover;
`;

export const BoxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
export const WhiteBoxContainer = styled.div`
  max-width: 445px;
  position: absolute;
  top: 0;
  transform: translateY(-120px);

  a {
    color: ${props => props.theme.skyBlue};

    @media(max-width:${XL}){
      font-size:20px;
    }
  }

  @media (max-width: ${XL}) {
    bottom: 0;
    top: inherit;
    max-width: unset;
    left: 20px;

    span {
      max-width: 400px;
    }
  }

  @media (max-width: ${LG}) {
    position: relative;
    max-width: 90%;
    left: 51%;
    transform: translate(-50%, 0);
    bottom: 40px;
    margin: 0 auto;

    > div > div {
      &:first-child {
        h4 {
          max-width: unset;
        }
      }
      h4 {
        max-width: 240px;
      }
    }
  }
`;

export const RightWrap = styled.div`
  width: 100%;

  h4 {
    font-family: Poppins;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 48px;
    font-weight: 300;
    max-width: 403px;
    margin-top: 40px;
    margin-left: 80px;
  }

  @media (max-width: ${XL}) {
    h4 {
      max-width: 600px;
      max-width: unset;
      margin-left: 0;
    }
  }

  @media (max-width: ${XL}) {
    margin-top: 100px;
  }
`;

export const Tabs = styled.div`
  margin-bottom: 40px;
  a {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    color: #d0dce8;
    margin-left: 15px;
    transition: ${props => props.theme.defaultTransition()};

    &:first-child {
      color: ${props => props.theme.darkBlue};
      margin-left: 0;
    }

    &:hover {
      color: ${props => props.theme.darkBlue};
    }
  }
`;

export const TextWrap = styled.div`
  margin-top: 40px;
  max-width: 524px;

  p {
    @media (max-width: ${LG}) {
      &:nth-child(2) {
        &:after {
          content: "";
          display: block;
          background: url(${props => props.bg}) no-repeat center center / cover;
          height: 323px;
          margin: 20px -14px -5px;
        }
      }
    }
  }

  @media (min-width: ${HIGHER_RESOLUTIONS}) {
    max-width: unset;
  }
  a {
    color: ${props => props.theme.skyBlue};
    text-decoration: none;
    font-weight: 600;
  }

  @media (max-width: ${XL}) {
    max-width: unset;
    margin-right: 30px;
  }

  @media (max-width: ${LG}) {
    margin: 0;
    padding: 0 15px;

    h4 {
      margin-bottom: 20px;
    }
  }
`;

export const MainSubtitle = styled(Subtitle)``;

export const MobileWrapper = styled.div``;

export const MobileText = styled(StyledP)`
  padding-bottom: 15px;
`;

export const MobilePicture = styled.div`
  background: url(${props => props.bg}) no-repeat center center / cover;
  height: 323px;
  margin-bottom: 20px;
`;
