import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

export const fade = keyframes`
  0% {
      visibility: hidden;
      opacity: 0;
  }
  1% {
      visibility: visible;
      opacity: 0;
  }
  100% {
      visibility: visible;
      opacity: 1;
  }
`;
export const Navs = styled.div`
  grid-area: nav;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 1300px) {
    display: ${props => (props.isDesk ? "none" : "flex")};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  &:first-child {
  }

  > a,
 button > a,
 > div > a {
    color: ${props => (props.showbg === true ? props.theme.darkBlue : "white")};
    text-shadow: ${props => (props.showbg === true ? "none" : "0 0 2px #0000004d;")};
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     width: auto;
  }
`;
export const Overlay = styled.button`
  outline: none;
  border: none;
  background: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 54;
  display: ${({ show }) => (show ? "flex" : "none")};
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
     width: 100px;
     height: 100px;
     background: green;
     display: none;
  }
`;
export const Section = styled.div`
  cursor: pointer;
  border: none;
  text-decoration: none;
  outline: none;
  background: none;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  width: max-content;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  z-index: 55;
  padding: 1em 0;
  margin-bottom: -10px;
  padding-bottom: 25px;

  /*  &:not(:last-child) {
    margin-right: 30px;
  } */
`;
export const Submenu = styled.div`
  position: absolute;
  top: calc(100% - 1.5em);
  right: 2.5;
  transition: ${({ theme }) => theme.defaultTransition()};
  opacity: 0;
  display: flex;
  animation: ${({ show }) => (show ? fade : "none")} 0.2s 0.2s ease forwards;
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: rows;
  width: max-content;
  height: 130px;
  padding: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(31, 48, 64, 0.1);
  z-index: 56;
  padding-right: 0;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    left: 0;
    
    img {
      width: 90px !important;
    }
  }
`;
export const SubmenuLink = styled(NavLink)`
  text-decoration: none;
  outline: none;
  background: none;
  height: 100%;
  color: ${props => props.theme.darkBlue};
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  display: grid;
  grid-template-columns: ${props =>
    props.customgrid ? props.customgrid : "90px 80px"};
  grid-gap: 0 10px;
  align-items: start;
  justify-items: start;
  transition: ${({ theme }) => theme.defaultTransition()};
  
  img {
    width: 100%;
    border-radius: 12px;
    transition: ${props => props.theme.defaultTransition()};
  }
  
  span {
    text-align: left;
    color: ${props => props.darkBlue};
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;

    padding-right: 30px;
  }

  &:hover {
    color: ${props => props.theme.skyBlue};
    
    img {
      box-shadow: 0 10px 40px 0 rgba(31, 48, 64, 0.2);
    }
  }

  &:not(:hover) {
    img {
      -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
      -o-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
      filter: gray;
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    }
  }
  
  /* IE10+ specific styles */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: flex;
    
    img {
      margin-right: 10px;
    }
  }
  
`;
export const StyledNavlink = styled(NavLink)`
  font-weight: regular;
  text-decoration: none;
  outline: none;
  background: none;
  height: 19px;
  color: #ffffff;
  display: flex;
  align-items: flex-start;
  width: max-content;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: ${props => (props.selected ? "600" : "400")};
  &:not(:last-child) {
    margin-right: 30px;
  }
`;
