import React from "react";

import {
  Wrapper,
  Gallery,
  FirstSliderImage,
  SliderItem,
  Numbers,
  CounterTitle,
  CounterWrap
} from "./style";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AsNavFor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,

      oldSlide: 0,
      activeSlide: 0,
      activeSlide2: 0
    });
  }

  render() {

    const settingNav2 = {
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current }),
      arrows: true
    };

    return (
      <Wrapper customMargin={this.props.customMargin}>
        {this.props.images ? (
          <div>
            <Gallery
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
              color={this.props.color}
              secondGallery={true}
              {...settingNav2}
            >
              {this.props.images.map((image, z) => (
                <SliderItem oneImage={true} key={z}>
                  <FirstSliderImage bg={image} key={z}></FirstSliderImage>
                </SliderItem>
              ))}
            </Gallery>
            <CounterWrap>
              <Numbers color={this.props.color}>
                {this.state.activeSlide + 1} / {this.props.images.length}{" "}
              </Numbers>
              <CounterTitle color={this.props.color}>
                {this.props.title.slice(0, -1)}
              </CounterTitle>
            </CounterWrap>
          </div>
        ) : (
          ""
        )}
      </Wrapper>
    );
  }
}
