import React, { Suspense } from "react";
import { Wrapper, List, Tab } from "./style";

export default props => (
  <Suspense fallback={null}>
    <Wrapper>
      <List>
        {props.days.map((day, i) => {
          return (
            <li key={i}>
              <Tab
                selected={props.selected === i + 2}
                onClick={() => props.handleTabs(day)}
              >
                <span>{day.substring(0, 1)}</span>
              </Tab>
            </li>
          );
        })}
      </List>
    </Wrapper>
  </Suspense>
);
